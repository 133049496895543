import React, { Component } from "react";
import { connect } from "react-redux";
import ProjectPage from "../projectPage/ProjectPage";
import NoResult from "../noresult/NoResult";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import { NavLink } from "react-router-dom";
import $ from "jquery";
import ProjectPageSubLinks from "../projectpagesublinks/ProjectPageSubLinks";
import "./renderprojrect.css";
import { Helmet } from "react-helmet";

class RenderProjects extends Component {
  state = {
    projectName: "",
  };
  componentDidMount() {
    // this.props.history.push("/?category=" + this.props.activeCategory);
    $(document).scrollTop(0);
    this.props.showProjects(this.props.activeCategoryy);
  }
  componentDidUpdate() {
    $(document).scrollTop(0);
  }

  seeemore() {
    $(".drop-contt").toggle();
    // $(".drop1").css("display", "block");
    // $(".drop1").toggle();
    // $(".drop2").css("display", "block");
  }
  render() {
    const renderSubCategories = () => {
      if (window.matchMedia("(max-width: 800px)").matches) {
        return (
          <div className="mobile-sublinks" onClick={this.seeemore}>
            <div className="mob-drop-header">
              <p
                className="js-content22"
                id="optt0"
                onClick={() => this.seeemore}
              >
                Sort by +
              </p>
              {this.props.subcategories.map((item, index) => {
                return (
                  <p
                    className="js-content22"
                    id={"optt" + Number(index + 1)}
                    onClick={() => this.seeemore}
                  >
                    <ProjectPageSubLinks
                      btn_name={item.subcategory}
                      clicked={this.seeemore()}
                      onClick={() =>
                        this.props.showSubCategories(item.subcategory)
                      }
                    />
                  </p>
                );
              })}
            </div>
            {/* <div className="seeemore">
              <div className="dropicon">
                <div className="drop1">
                  <img
                    src={this.state.imggSRC[0]}
                    onClick={() => this.seeemore}
                  />
                </div>
                <div className="drop2">
                  <img
                    src={this.state.imggSRC[1]}
                    onClick={() => this.seeemore}
                  />
                </div>
              </div>
            </div> */}
          </div>
        );
      }
      return this.props.subcategories.map((items, index) => {
        return (
          <React.Fragment>
            <ProjectPageSubLinks
              btn_name={items.subcategory}
              onClick={() => this.props.showSubCategories(items.subcategory)}
            />
          </React.Fragment>
        );
      });
    };

    const subcategory = () => {
      return (
        <div>
          <h1 className="navigation-link">
            <NavLink to="/"> PROJECT </NavLink>{" "}
            <div className="arrow"> {">"} </div>
            {this.props.activeCategory.toUpperCase()}
            <div className="arrow">{">"} </div>
            {this.props.activeSubCategory}
          </h1>
          {renderSubCategories()}
          <div className="drop-contt">
            <ul>
              {this.props.subcategories.map((item, index) => {
                return (
                  <li
                    className="drop-cont-links"
                    id={"opttt" + Number(index + 1)}
                    onClick={() =>
                      this.props.showSubCategories(item.subcategory)
                    }
                  >
                    {item.subcategory}
                  </li>
                );
              })}
            </ul>
          </div>
          {/* <div className="project-section ani-showw">{renderProjects()}</div> */}
        </div>
      );
    };
    const projs = () => {
      let temp = 0;
      let data = this.props.projects.map((item, index) => {
        if (
          item.category === this.props.activeCategory &&
          item.subcategory === this.props.activeSubCategory
        ) {
          temp = 1;
          //alert(item.category + " " + this.props.activeCategory);
          return (
            <React.Fragment>
              <div className="pp-container ani-showw">
                <NavLink
                  to={
                    "/project/" +
                    this.props.activeCategory +
                    "/project/" +
                    item.header
                  }
                >
                  <ProjectPage
                    key={index}
                    title={item.title}
                    desc={item.desc}
                    imgSRC={item.imgSRC}
                    alt={item.alt}
                    onClick={() => this.props.showData(item.header)}
                  />
                </NavLink>
              </div>
            </React.Fragment>
          );
        } else if (
          "ALL" === this.props.activeSubCategory &&
          item.category === this.props.activeCategory
        ) {
          temp = 1;
          //alert("ALL" + " " + this.props.activeSubCategory);
          //alert("inside else if loop");
          return (
            <div className="pp-container ani-showw">
              <NavLink
                to={
                  "/project/" +
                  this.props.activeCategory +
                  "/project/" +
                  item.header
                }
              >
                <ProjectPage
                  key={index}
                  title={item.title}
                  desc={item.desc}
                  imgSRC={item.imgSRC}
                  onClick={() => this.props.showData(item.header)}
                  history={this.props.history}
                  location={this.props.location}
                  alt={item.alt}
                />
              </NavLink>
            </div>
          );
        }
      });
      if (temp === 1) {
        return data;
      } else {
        return <NoResult />;
      }
    };
    return (
      <React.Fragment>
        <div className="renderProject">
          {this.props.SEOTags.map((item, index) => {
            if (item.category === this.props.activeCategory) {
              return (
                <Helmet>
                  <title>{item.meta_title}</title>
                  {/* <link
                    rel="canonical"
                    href={
                      "https://www.architectm.com/category/" +
                      this.props.activeCategory
                    }
                  /> */}
                  <meta name="description" content={item.meta_desc} />
                  <meta name="keywords" content={item.meta_tag} />
                  <meta property="og:type" content="website" />
                  <meta
                    property="og:title"
                    content="Maheshwari and Associates"
                  />
                  <meta
                    property="og:url"
                    content="https://www.architectm.com"
                  />
                  <meta
                    property="og:description"
                    content="Maheshwari & Associates is one of the best architecture firm having their offices in mumbai and Kolkata"
                  ></meta>
                </Helmet>
              );
            }
          })}

          <div className="renderProject-header">
            <Header />
          </div>
          <div className="top-container">
            {subcategory()}
            {projs()}
          </div>
        </div>
        <div className="phooter">
          <Footer />
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    projects: state.projects,
    showProjectsdiv: state.showProjects,
    showProjects2: state.showProjects2,
    categories: state.categories,
    showSubCategories: state.showSubCategories,
    subcategories: state.subcategories,
    activeCategory: state.activeCategory,
    activeSubCategory: state.activeSubCategory,
    showCategories: state.showCategories,
    setProjectPageComp: state.setProjectPageComp,
    homeRender: state.homeRender,
    activeProjectName: state.activeProjectName,
    SEOTags: state.SEOTags,
    // name: state.name
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    stateChange: () => dispatch({ type: "change_state" }),
    stateChange2: () => dispatch({ type: "change_state2" }),
    showSubCategories: (name) =>
      dispatch({ type: "display_subCategories", value: name }),
    showProjects: (name) => dispatch({ type: "display_Projects", value: name }),
    showData: (pname) => dispatch({ type: "display_info", value: pname }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RenderProjects);
