import React, { Component } from "react";
import PropTypes from "prop-types";
import "./accordion.css";
class AccordionSection extends Component {
  static propTypes = {
    children: PropTypes.instanceOf(Object).isRequired,
    isOpen: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  onClick = () => {
    this.props.onClick(this.props.label);
  };

  render() {
    const {
      onClick,
      props: { isOpen, label },
    } = this;

    return (
      <div
        style={{
          background: isOpen ? "#000000" : "#f9f9f9",
        }}
        className="accordion-section"
      >
        <div
          onClick={onClick}
          style={{
            color: isOpen ? "white" : "#000000",
          }}
          className="acc-label"
        >
          {label}
          <div style={{ float: "right" }}>
            {!isOpen && <i className="arrow-open"></i>}
            {isOpen && <i className="arrow-close"></i>}
          </div>
        </div>
        {isOpen && (
          <div
            style={{
              background: "white",
              padding: "5px 10px",
            }}
            className="acc-content"
          >
            {this.props.children}
          </div>
        )}
      </div>
    );
  }
}

export default AccordionSection;
