import React, { Component } from "react";
import projectpagecss from "./projectpage.css";

class ProjectPage extends Component {
  render() {
    return (
      <div className="image-container ani-show" onClick={this.props.onClick}>
        <img
          className="image-disp"
          src={this.props.imgSRC}
          alt={this.props.alt}
        />
        <div className="info-holder">
          <div className="title">{this.props.title}</div>
          <div className="desc">{this.props.desc}</div>
        </div>
      </div>
    );
  }
}

export default ProjectPage;
