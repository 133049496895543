import React, { Component } from "react";
import aboutusteam2css from "./aboutusteam2.css";
import { Spring } from "react-spring/renderprops";
let t1 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1566565751/Maheshwari/Kamal_Periwal_fnq4qw.jpg";
class AboutUsTeam2 extends Component {
  state = {
    founder: t1,
  };
  render() {
    return (
      <Spring
        from={{ opacity: 0 }}
        to={{ opacity: 1 }}
        config={{ duration: 1500 }}
      >
        {(props) => (
          <div style={props}>
            <div className="team2-container">
              <div className="info-container">
                <div className="Lsection">
                  <img
                    className="founder"
                    src={this.state.founder}
                    alt="founder"
                  />
                  <p>
                    Principal Architect <br />
                    Ar. Kamal Kumar Periwal
                  </p>
                </div>
                <div className="Rsection">
                  <div className="desc">
                    Architect Kamal Periwal started his journey as an architect
                    during the mid 90s when he set up Maheshwari & Associates.
                    His humble beginnings as an architect started from Mumbai
                    (then called Bombay) and in couple years made his way to
                    Kolkata. In the 25 years of his esteemed career, Ar. Periwal
                    has been able to deliver over 20 million sq. ft. of built up
                    area spread across India. His projects have a strong
                    presence in Eastern India, Kolkata, Siliguri & Guwahati,
                    along with projects in Jaipur, Mumbai and Goa.
                    <br /> <br />
                    Ar. Periwal is known for his architectural designs which
                    bring about a unique combination of efficiency with
                    aesthetics. Some of his projects which do that are Tata
                    Housing Eden Court, 23 Circus Avenue, Magnolia City, Sohum
                    Chain of Malls, The Arch WaterFront and Bodofa Cultural
                    Complex. These projects have been widely appreciated and are
                    quite successful.
                    <br /> <br />
                    Ar. Kamal Periwal has been part of the architectural
                    fraternity from nearly 25 years. He has been recognised by
                    the fraternity for his design excellence and unfathomable
                    passion. He has shared his knowledge in seminars like Steel
                    Summit and also been part of the jury in few occasions.
                    Currently, he is also the Chairman of Kolkata Chapter - IIID
                    (Indian Institute of Interior Designers) which aims to
                    stimulate and captivate budding minds in the interior
                    designing field in Kolkata.
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Spring>
    );
  }
}

export default AboutUsTeam2;
