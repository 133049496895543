import React, { Component } from "react";
import "./deskSlider.css";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

var curImage = 0;

class DeskSlider extends Component {
  constructor(props) {
    super(props);
    this.item1 = React.createRef();
    this.item2 = React.createRef();
    // this.images = [];
  }
  state = {};
  slideShow(images, container, time) {
    container.className += "fadeOut";
    setTimeout(() => {
      console.log(curImage);
      container.src = images[curImage];
      container.className = "";
    }, 3000);
    curImage = curImage + 1;
    console.log(curImage === images.length);

    if (curImage === images.length) {
      curImage = 0;
    }
    setTimeout(() => {
      this.slideShow(images, container, time);
    }, time);
  }
  componentDidMount() {
    // this.slideShow(i1, this.item1.current, 9000);
    // this.slideShow(i2, this.item2.current, 12500);
  }

  render() {
    var settings = {
      dots: false,
      infinite: true,
      speed: 2000,
      autoplay: true,
      fade: true,
      arrows: false,
      slide: "img",
      adaptiveHeight: true,
      pauseOnHover: true,
    };
    return (
      <div className="deskSlider">
        <NavLink to="/category/residential-housing-complex">
          <div
            id="item--1"
            onClick={(e) =>
              this.props.showProjects("residential-housing-complex")
            }
          >
            <Slider autoplaySpeed={4300} className="i1" {...settings}>
              <img
                src="https://res.cloudinary.com/dubm0zyx2/image/upload/v1600848428/Maheshwari/slider/1.%20Residential%20Housing%20Complex/2_drfmls.jpg"
                alt=""
                // className="adjust"
                id="i1"
              />
              <img
                src="https://res.cloudinary.com/dubm0zyx2/image/upload/v1598537056/Maheshwari/slider/1.%20Residential%20Housing%20Complex/1_da5hpg.jpg"
                alt=""
                id="i1"
              />
            </Slider>

            <div className="captionn" onClick={this.props.onClick}>
              <div className="contennt">RESIDENTIAL: HOUSING COMPLEX</div>
            </div>
          </div>
        </NavLink>

        <NavLink to="/category/residential-bunglow">
          <div
            id="item--2"
            onClick={(e) => this.props.showProjects("residential-bunglow")}
          >
            <Slider autoplaySpeed={6800} className="i2" {...settings}>
              <img
                src="https://res.cloudinary.com/dubm0zyx2/image/upload/v1598537058/Maheshwari/slider/3.%20Residential%20Bungalow/1_mubn8d.jpg"
                alt=""
                id="i2"
              />
              <img
                src="https://res.cloudinary.com/dubm0zyx2/image/upload/v1598537058/Maheshwari/slider/3.%20Residential%20Bungalow/2_cfhhw0.jpg"
                alt=""
                id="i2"
              />
            </Slider>
            <div className="captionn" onClick={this.props.onClick}>
              <div className="contennt">RESIDENTIAL: BUNGLOW</div>
            </div>
          </div>
        </NavLink>

        <NavLink to="/category/commercial-office">
          <div
            id="item--3"
            onClick={(e) => this.props.showProjects("commercial-office")}
          >
            <Slider autoplaySpeed={11700} className="i3" {...settings}>
              <img
                src="https://res.cloudinary.com/dubm0zyx2/image/upload/v1598618531/Maheshwari/slider/5.%20Commercial%20Office/1_cqtb6e.jpg"
                alt=""
                id="i3"
              />
              <img
                src="https://res.cloudinary.com/dubm0zyx2/image/upload/v1600848912/Maheshwari/slider/5.%20Commercial%20Office/2_tjzyiu.jpg"
                alt=""
                id="i3"
              />
            </Slider>

            <div className="captionn" onClick={this.props.onClick}>
              <div className="contennt">COMMERCIAL: OFFICE</div>
            </div>
          </div>
        </NavLink>

        <NavLink to="/category/commercial-retail">
          <div
            id="item--4"
            onClick={(e) => this.props.showProjects("commercial-retail")}
          >
            <Slider autoplaySpeed={15700} className="i4" {...settings}>
              <img
                src="https://res.cloudinary.com/dubm0zyx2/image/upload/v1598537055/Maheshwari/slider/7.%20Commercial%20Retail/1_bqhvuc.jpg"
                alt=""
                id="i4"
              />
              <img
                src="https://res.cloudinary.com/dubm0zyx2/image/upload/v1600849299/Maheshwari/slider/7.%20Commercial%20Retail/2_mt3bky.jpg"
                alt=""
                id="i4"
              />
            </Slider>

            <div className="captionn" onClick={this.props.onClick}>
              <div className="contennt">COMMERCIAL: RETAIL</div>
            </div>
          </div>
        </NavLink>

        <NavLink to="/category/residential-high-rise-tower">
          <div
            id="item--5"
            onClick={(e) =>
              this.props.showProjects("residential-high-rise-tower")
            }
          >
            <Slider autoplaySpeed={17619} className="i5" {...settings}>
              <img
                src="https://res.cloudinary.com/dubm0zyx2/image/upload/v1598537059/Maheshwari/slider/2.%20Residential%20Towers/1_gcqqhl.jpg"
                alt=""
                id="i5"
              />
              <img
                src="https://res.cloudinary.com/dubm0zyx2/image/upload/v1600848669/Maheshwari/slider/2.%20Residential%20Towers/2_drhssz.jpg"
                alt=""
                id="i5"
              />
            </Slider>

            <div className="captionn" onClick={this.props.onClick}>
              <div className="contennt">RESIDENTIAL: HIGH RISE TOWER</div>
            </div>
          </div>
        </NavLink>

        <NavLink to="/category/interior-design">
          <div
            id="item--6"
            onClick={(e) => this.props.showProjects("interior-design")}
          >
            <Slider autoplaySpeed={20453} className="i6" {...settings}>
              <img
                src="https://res.cloudinary.com/dubm0zyx2/image/upload/v1598537055/Maheshwari/slider/4.%20Interior%20Design/1_ly1mwf.jpg"
                alt=""
                id="i6"
              />
              <img
                src="https://res.cloudinary.com/dubm0zyx2/image/upload/v1598537055/Maheshwari/slider/4.%20Interior%20Design/2_cz7iil.jpg"
                alt=""
                id="i6"
              />
            </Slider>

            <div className="captionn" onClick={this.props.onClick}>
              <div className="contennt">INTERIOR DESIGN</div>
            </div>
          </div>
        </NavLink>

        <NavLink to="/category/cultural">
          <div
            id="item--7"
            onClick={(e) => this.props.showProjects("cultural")}
          >
            <Slider autoplaySpeed={22562} className="i7" {...settings}>
              <img
                src="https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435304/Maheshwari/headerslide/7_lns88e.jpg"
                alt=""
                id="i7"
              />
              <img
                src="https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435304/Maheshwari/headerslide/7_lns88e.jpg"
                alt=""
                id="i7"
              />
            </Slider>

            <div className="captionn" onClick={this.props.onClick}>
              <div className="contennt">CULTURAL</div>
            </div>
          </div>
        </NavLink>

        <NavLink to="/category/mixed">
          <div id="item--8" onClick={(e) => this.props.showProjects("mixed")}>
            <Slider autoplaySpeed={23820} className="i8" {...settings}>
              <img
                src="https://res.cloudinary.com/dubm0zyx2/image/upload/v1598537055/Maheshwari/slider/6.%20Mixed%20Use/1_prabla.jpg"
                alt=""
                id="i8"
              />
              <img
                src="https://res.cloudinary.com/dubm0zyx2/image/upload/v1600849158/Maheshwari/slider/6.%20Mixed%20Use/2_mm1ctu.jpg"
                alt=""
                id="i8"
              />
            </Slider>

            <div className="captionn" onClick={this.props.onClick}>
              <div className="contennt">MIXED USE</div>
            </div>
          </div>
        </NavLink>

        <NavLink to="/category/hospitality">
          <div
            id="item--9"
            onClick={(e) => this.props.showProjects("hospitality")}
          >
            <Slider autoplaySpeed={25705} className="i9" {...settings}>
              <img
                src="https://res.cloudinary.com/dubm0zyx2/image/upload/v1598537056/Maheshwari/slider/8.Hospitality/1_yhe7fr.jpg"
                alt=""
                id="i9"
              />
              <img
                src="https://res.cloudinary.com/dubm0zyx2/image/upload/v1600853584/Maheshwari/slider/8.Hospitality/2_a5t53o.jpg"
                alt=""
                id="i9"
              />
            </Slider>

            <div className="captionn" onClick={this.props.onClick}>
              <div className="contennt">HOSPITALITY</div>
            </div>
          </div>
        </NavLink>

        <NavLink to="/category/institute">
          <div
            id="item--10"
            onClick={(e) => this.props.showProjects("institute")}
          >
            <Slider autoplaySpeed={28200} className="i10" {...settings}>
              <img
                src="https://res.cloudinary.com/dubm0zyx2/image/upload/v1600853842/Maheshwari/slider/9.%20Institutions/1_nt0lvi.jpg"
                alt=""
                id="i10"
              />
              <img
                src="https://res.cloudinary.com/dubm0zyx2/image/upload/v1563452887/Maheshwari/headerslide/61_gsup7z.jpg"
                alt=""
                id="i10"
              />
            </Slider>
            <div className="captionn" onClick={this.props.onClick}>
              <div className="contennt">INSTITUTE</div>
            </div>
          </div>
        </NavLink>

        {/*         
       
       

       

        <NavLink to="/category/hospitality">
          <div
            className="item--7-1"
            onClick={() => this.props.showProjects("hospitality")}
          >
            <div className="captionn">
              <div className="contennt">HOSPITALITY</div>
            </div>
            <img src={img9} alt="HOSPITALITY" className="gallery__img" />
          </div>
        </NavLink>

        <NavLink to="/category/institute">
          <div
            className="item--10"
            onClick={() => this.props.showProjects("institute")}
          >
            <img src={img10} alt="INSTITUTE" className="gallery__img" />
            <div className="captionn">
              <div className="contennt">INSTITUTE</div>
            </div>
          </div>
        </NavLink> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    projects: state.projects,
    showProjectsdiv: state.showProjects,
    showProjects2: state.showProjects2,
    categories: state.categories,
    showSubCategories: state.showSubCategories,
    subcategories: state.subcategories,
    activeCategory: state.activeCategory,
    activeSubCategory: state.activeSubCategory,
    showCategories: state.showCategories,
    setProjectPageComp: state.setProjectPageComp,
    homeRender: state.homeRender,
    activeProjectName: state.activeProjectName,
    loading: state.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    stateChange: () => dispatch({ type: "change_state" }),
    stateChange2: () => dispatch({ type: "change_state2" }),
    showSubCategories: (name) =>
      dispatch({ type: "display_subCategories", value: name }),
    showProjects: (name) => dispatch({ type: "display_Projects", value: name }),
    showData: (pname) => dispatch({ type: "display_info", value: pname }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeskSlider);
