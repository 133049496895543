import React, { Component } from "react";
import "./contactuscomponent.css";
import InputBoxComponent from "../inputboxcomponent/InputBoxComponent";
import Header from "../header/Header";
import { submit } from "../scripts/mail";
import { Spring } from "react-spring/renderprops";
import fb from "./images/fb.png";
import insta from "./images/insta.png";
import linkd from "./images/linkd.png";
import { Helmet } from "react-helmet";
import Footer2 from "../footer2/Footer2";

let data = {
  Fname: "test",
};

// import * as mail from "../mailer/mail.js";
class ContacttUsComponent extends Component {
  state = {
    value: "",
    value2: "",
    value3: "",
    value4: "",
    value5: "",
    isButtonDisabled: false,
  };

  handleChange = (event) => {
    this.setState({
      value: event.target.value,
    });
  };

  handleChange2 = (event) => {
    this.setState({
      value2: event.target.value,
    });
  };
  handleChange3 = (event) => {
    this.setState({
      value3: event.target.value,
    });
  };
  handleChange4 = (event) => {
    this.setState({
      value4: event.target.value,
    });
  };
  handleChange5 = (event) => {
    this.setState({
      value5: event.target.value,
    });
  };

  render() {
    const sendDataToScript = (e) => {
      // e.preventDefault();
      data.Fname = this.state.value;
      data.Lname = this.state.value2;
      data.EmailID = this.state.value3;
      data.MobNumber = this.state.value4;
      data.Message = this.state.value5;
      // this.setState({
      //   isButtonDisabled: true
      // });
      submit(data);

      // setInterval(function() {
      //   this.setState({
      //     isButtonDisabled: false
      //   });
      // }, 4000);
    };
    return (
      <Spring
        from={{ opacity: 0 }}
        to={{ opacity: 1 }}
        config={{ duration: 1500 }}
      >
        {(props) => (
          <div style={props}>
            <div>
              <div className="contact-header">
                <Helmet>
                  <title>Contact | Maheshwari & Associates</title>
                  {/* <link
                    rel="canonical"
                    href="https://www.architectm.com/contact"
                  /> */}
                  <meta
                    name="description"
                    content="We have offices in Mumbai and Kolkata. contact us Mumbai - (022) 28764610 , Kolkata - (033) 4601 7380  , Email: info@architectm.com , www.architectm.com"
                  />
                  <meta
                    name="keywords"
                    content="top architects in kolkata, top 10 architects in kolkata,famous architects mumbai,architects website,contact us"
                  />
                  <meta property="og:type" content="website" />
                  <meta
                    property="og:title"
                    content="Maheshwari and Associates"
                  />
                  <meta
                    property="og:url"
                    content="https://www.architectm.com"
                  />
                  <meta
                    property="og:description"
                    content="Maheshwari & Associates is one of the best architecture firm having their offices in mumbai and Kolkata"
                  ></meta>
                </Helmet>
                <Header />
              </div>
              <div className="contact-container">
                <section className="content">
                  <div className="columns">
                    <div className="branches">
                      <div className="position1">
                        <div className="divSquare">
                          <span className="title">MUMBAI</span>
                          <p>
                            Address:
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            Tel:
                          </p>
                          <div className="addabs">
                            808/809, Corporate Annex,
                            <br /> Sonawala Road, Goregaon (E) <br /> Mumbai –
                            400065
                            <br />
                            <br />
                            <br />
                            <a href="tel:02228764610">(022) 28764610</a>/{" "}
                            <a href="tel:02226853691">26853691</a>
                            <br />
                            <br />
                          </div>
                        </div>
                        {/* <div className="divSquare div2pad">
                          <p>
                            Tel: (022) 28764610 / 2685 3691
                            <br />
                            <br />
                            Email: info@architectm.com <br />
                            <br />
                            <br />
                          </p>
                        </div> */}
                      </div>
                      <div className="clear" />
                      <div className="position2">
                        <div className="divSquare">
                          <span className="title">KOLKATA</span>
                          <p>
                            Address:
                            <br />
                            <br />
                            <br />
                            <br />
                            Tel:
                            <br />
                            <br />
                            Email:
                          </p>
                          <div className="addabs">
                            37A, Baker Road, Alipore.
                            <br />
                            Kolkata – 700027
                            <br />
                            <br />
                            <br />
                            <a href="tel:033646017380">(033) 46017380</a>/{" "}
                            <a href="tel:03324490323">24490323</a>
                            <br />
                            <br />
                            <a
                              href="mailto:info@architectm.com"
                              target="_blank"
                            >
                              info@architectm.com
                            </a>
                          </div>
                        </div>
                      </div>

                      <div className="add11">
                        <p>Mumbai</p>
                        <table>
                          <tr>
                            <td className="titles">Address:</td>
                            <td className="descs">
                              808/809, Corporate Annex,
                              <br />
                              Sonawala Road, <br />
                              Goregaon (E) <br /> Mumbai – 400065
                            </td>
                            <td></td>
                          </tr>
                          <tr>
                            <td className="titles">Tel:</td>
                            <td className="descs">
                              <a href="tel:02228764610">(022) 28764610</a>/{" "}
                              <a href="tel:02226853691">26853691</a>
                            </td>
                          </tr>
                          <tr>
                            <td className="titles">Email:</td>
                            <td className="descs">
                              <a
                                href="mailto:info@architectm.com"
                                target="_top"
                              >
                                info@architectm.com
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2">
                              <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3768.772298663184!2d72.8485611149025!3d19.161441987038938!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b7ed317dfae1%3A0x61e9f5b1388de7e7!2sMaheswari%20%26%20Associates%20Architects!5e0!3m2!1sen!2sin!4v1572592768596!5m2!1sen!2sin"
                                width="600"
                                height="450"
                                frameborder="0"
                                allowfullscreen=""
                              ></iframe>
                            </td>
                          </tr>
                        </table>
                      </div>

                      <div className="add11">
                        <p>KOLKATA</p>
                        <table>
                          <tr>
                            <td className="titles">Address:</td>
                            <td className="descs">
                              37A, Baker Road, Alipore.
                              <br /> Kolkata – 700027
                            </td>
                          </tr>
                          <tr>
                            <td className="titles">Tel:</td>
                            <td className="descs">
                              {/* (033) 46017380 / 24490323 */}
                              <a href="tel:03346017380">(033) 46017380</a>/{" "}
                              <a href="tel:03324490323">24490323</a>
                            </td>
                          </tr>
                          <tr>
                            <td className="titles">Email:</td>
                            <td className="descs">
                              <a
                                href="mailto:info@architectm.com"
                                target="_blank"
                              >
                                info@architectm.com
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2">
                              <iframe
                                className="adjustMap"
                                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14741.561916131044!2d88.3368648!3d22.5270409!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xfe15611451a9a8d8!2sMaheshwari%20%26%20Associates!5e0!3m2!1sen!2sin!4v1577774106801!5m2!1sen!2sin"
                                width="600"
                                height="450"
                                frameborder="0"
                                allowfullscreen=""
                              ></iframe>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="social-media">
                        <a
                          href="https://www.facebook.com/architectmdotcom/"
                          target="_blank"
                        >
                          <div className="social-icons">
                            <img src={fb} alt="" />
                            <p> architectmdotcom</p>
                          </div>
                        </a>
                        <a
                          href="https://www.instagram.com/architectm.com_/"
                          target="_blank"
                        >
                          <div className="social-icons">
                            <img src={insta} alt="" />
                            <p>architectm.com_</p>
                          </div>
                        </a>
                        <a
                          href="https://www.linkedin.com/company/architectm/"
                          target="_blank"
                        >
                          <div className="social-icons">
                            <img src={linkd} alt="" />
                            <p>architectm</p>
                          </div>
                        </a>
                      </div>

                      <div className="mapConatiner">
                        <iframe
                          className="mumbaiMap"
                          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3768.772298663184!2d72.8485611149025!3d19.161441987038938!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b7ed317dfae1%3A0x61e9f5b1388de7e7!2sMaheswari%20%26%20Associates%20Architects!5e0!3m2!1sen!2sin!4v1572592768596!5m2!1sen!2sin"
                          width="600"
                          height="450"
                          frameborder="0"
                          allowfullscreen=""
                        ></iframe>

                        <iframe
                          className="mumbaiMap"
                          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14741.561916131044!2d88.3368648!3d22.5270409!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xfe15611451a9a8d8!2sMaheshwari%20%26%20Associates!5e0!3m2!1sen!2sin!4v1577774106801!5m2!1sen!2sin"
                          width="600"
                          height="450"
                          frameborder="0"
                          allowfullscreen=""
                        ></iframe>
                        {/* <iframe
                          className="mumbaiMap"
                          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d650.8883569571891!2d88.38623732283908!3d22.654513115878217!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x4b4b1032ed368279!2sMaheshwari%20Architects%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1571217706179!5m2!1sen!2sin"
                          width="600"
                          height="450"
                          frameborder="0"
                          allowfullscreen=""
                        ></iframe> */}
                      </div>
                    </div>
                    <div className="messages">
                      <div className="title pad3">Leave Us A Message</div>
                      <div className="contact-form">
                        <InputBoxComponent
                          placeholder="First Name"
                          id="Fname"
                          value={this.state.value}
                          Change={this.handleChange}
                        />
                        <InputBoxComponent
                          placeholder="Last Name"
                          id="Lname"
                          value={this.state.value2}
                          Change={this.handleChange2}
                        />
                        <InputBoxComponent
                          placeholder="Your Email"
                          id="EmailID"
                          value={this.state.value3}
                          Change={this.handleChange3}
                        />
                        <InputBoxComponent
                          placeholder="Mobile No."
                          id="MobNumber"
                          value={this.state.value4}
                          Change={this.handleChange4}
                        />

                        <textarea
                          rows="10"
                          cols="39"
                          placeholder="Message..."
                          id="Message"
                          value={this.state.value5}
                          onChange={this.handleChange5}
                        />
                        <br />
                        <button
                          className="subbtnb"
                          id="disable"
                          onClick={sendDataToScript}
                        >
                          SUBMIT
                        </button>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <Footer2 />
              {/* {$(window).width() > 800 ? <Footer2 /> : <Footer />} */}
            </div>
          </div>
        )}
      </Spring>
    );
  }
}

export default ContacttUsComponent;
