import $ from "jquery";
//imported images

let darpan1 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435102/Maheshwari/Projects/DARPAN%20MARKETING%20OFFICE/1_zr3uzu.jpg";
let darpan2 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435103/Maheshwari/Projects/DARPAN%20MARKETING%20OFFICE/2_prg7ct.jpg";
let darpan3 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435106/Maheshwari/Projects/DARPAN%20MARKETING%20OFFICE/3_ojyt5m.jpg";
let darpan4 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435102/Maheshwari/Projects/DARPAN%20MARKETING%20OFFICE/4_k29fm5.jpg";
let darpan5 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435103/Maheshwari/Projects/DARPAN%20MARKETING%20OFFICE/5_agxcry.jpg";
let darpan6 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435106/Maheshwari/Projects/DARPAN%20MARKETING%20OFFICE/6_ovz1du.jpg";
let unil1 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435159/Maheshwari/Projects/Unimark%20Asian%20Lobby/1_bletm1.jpg";
let rare1 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435127/Maheshwari/Projects/RARE%20EARTH/1_sbksw8.jpg";
let rare2 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435128/Maheshwari/Projects/RARE%20EARTH/2_zhuyjh.jpg";
let rare3 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435128/Maheshwari/Projects/RARE%20EARTH/3_h6d8jr.jpg";
let rare4 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435128/Maheshwari/Projects/RARE%20EARTH/4_ziy1lc.jpg";
let rare5 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435129/Maheshwari/Projects/RARE%20EARTH/5_tetwdf.jpg";
let rare6 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435129/Maheshwari/Projects/RARE%20EARTH/6_vuvbx4.jpg";
let singh1 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435131/Maheshwari/Projects/SINGHANIA/1_w49y6b.jpg";
let singh2 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435134/Maheshwari/Projects/SINGHANIA/2_riq0os.jpg";
let singh3 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435133/Maheshwari/Projects/SINGHANIA/3_g29yri.jpg";
let singh4 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435133/Maheshwari/Projects/SINGHANIA/4_obvaw7.jpg";
let singh5 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435135/Maheshwari/Projects/SINGHANIA/5_pobl4s.jpg";
let singh6 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435134/Maheshwari/Projects/SINGHANIA/6_fbzrtp.jpg";
let srijan1 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435143/Maheshwari/Projects/SRIJAN%20ENTENIS/1_rhfb6r.jpg";
let srijan2 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435139/Maheshwari/Projects/SRIJAN%20ENTENIS/2_rtcyeo.jpg";
let srijan3 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435138/Maheshwari/Projects/SRIJAN%20ENTENIS/3_s297bc.jpg";
let srijan4 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435142/Maheshwari/Projects/SRIJAN%20ENTENIS/4_uil5rq.jpg";
let srijan5 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435139/Maheshwari/Projects/SRIJAN%20ENTENIS/5_eykcw0.jpg";
let srijan6 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435140/Maheshwari/Projects/SRIJAN%20ENTENIS/6_odbfcq.jpg";
let team1 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563441931/Maheshwari/Team/AD/ACHAL_JAIN_krskva.jpg";
let team2 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435164/Maheshwari/Team/AD/Akash_Kumar_Shaw_bmazya.jpg";
let team3 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435164/Maheshwari/Team/AD/Aniruddha_Mutsuddi_wbcezg.jpg";
let team4 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435164/Maheshwari/Team/AD/Bibekananda_Mondal_weswep.jpg";
let team5 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435164/Maheshwari/Team/AD/Kajal_Roy_Choudhury_vrmfab.jpg";
let team6 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435165/Maheshwari/Team/AD/Kumar_Jyoti_Nandy_ov6f8i.jpg";
let team7 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435165/Maheshwari/Team/AD/MIHIR_PATEL_y5mygn.jpg";
let team8 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435165/Maheshwari/Team/AD/Mousumi_Banerjee_ay1wjd.jpg";
let team9 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435165/Maheshwari/Team/AD/Piyashi_Karmakar_oqer4r.jpg";
let team10 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435165/Maheshwari/Team/AD/Priyanka_Paul_anuxun.jpg";
let team11 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435165/Maheshwari/Team/AD/Rajan_Vishwakarma_cfdyb3.jpg";
let team12 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435165/Maheshwari/Team/AD/Rishika_Agarwal_kr5dau.jpg";
let team13 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435165/Maheshwari/Team/AD/Rohan_Datta_bjc0oi.jpg";
let team14 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435165/Maheshwari/Team/AD/RUSHI_BHOGATE_zzjtki.jpg";

let team16 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435166/Maheshwari/Team/AD/Shruti_Alam_jkzx8w.jpg";
let team17 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435166/Maheshwari/Team/AD/Subroto_Roy_tn5v9r.jpg";
let team18 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1580201132/Maheshwari/Team/Ar_Pooja_Banerjee_nm3bqz.jpg";
let team19 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1580197863/Maheshwari/Team/Ar_Aritra_Nath_iipx64.jpg";
let team20 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1580197897/Maheshwari/Team/Ar_Simply_Das_mdeys0.jpg";
let team21 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435166/Maheshwari/Team/ID/Mohan_Kukreja_eyeqiu.jpg";
let team22 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435166/Maheshwari/Team/ADMIN/Babu_Das_s7kfc0.jpg";
let team23 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1580197884/Maheshwari/Team/Ar_Arnab_Paul_jcr4g3.jpg";
let team24 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1580197557/Maheshwari/Team/Ar_Farah_Kausar_h4tpuv.jpg";
let team25 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1580201132/Maheshwari/Team/Ar_Madhusnata_Basu_khvf5e.jpg";
let team26 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1580197556/Maheshwari/Team/Ar_Ritam_Mitra_svzh6i.jpg";
let team27 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1580197905/Maheshwari/Team/Pinki_Sharma_mkaspu.jpg";
let team28 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1580197917/Maheshwari/Team/Souvagya_Palai_vgzbmw.jpg";

let tolly1 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435154/Maheshwari/Projects/Tolly%20Exotica/1_g6gag4.jpg";
let tolly2 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435155/Maheshwari/Projects/Tolly%20Exotica/2_lhhqlz.jpg";
let tolly3 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435155/Maheshwari/Projects/Tolly%20Exotica/3_jye64r.jpg";
let tolly4 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435155/Maheshwari/Projects/Tolly%20Exotica/4_fcpahj.jpg";
let add1 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435152/Maheshwari/Projects/The%20Address/1_arshvh.jpg";
let add2 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435152/Maheshwari/Projects/The%20Address/2_aavppu.jpg";
let add3 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435153/Maheshwari/Projects/The%20Address/3_vv6nfu.jpg";
let add4 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435153/Maheshwari/Projects/The%20Address/4_xiawtd.jpg";
let add5 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435154/Maheshwari/Projects/The%20Address/5_khhsiz.jpg";
let add6 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435154/Maheshwari/Projects/The%20Address/6_b7zumz.jpg";
let alt1 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435094/Maheshwari/Projects/Altius/1_c4fuku.jpg";
let alt2 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435094/Maheshwari/Projects/Altius/2_wtbquj.jpg";
let alt3 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435094/Maheshwari/Projects/Altius/3_xdmqy8.jpg";
let aura1 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435122/Maheshwari/Projects/Primac%20Aura/1_s20zlt.jpg";
let aura2 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435122/Maheshwari/Projects/Primac%20Aura/2_cvdtbd.jpg";
let aura3 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435122/Maheshwari/Projects/Primac%20Aura/3_dmflrt.jpg";
let aura4 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435122/Maheshwari/Projects/Primac%20Aura/4_tek3ob.jpg";
let happy1 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435112/Maheshwari/Projects/Happy%20Homes%20V/1_od35nt.jpg";
let happy2 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435112/Maheshwari/Projects/Happy%20Homes%20V/2_ya0slq.jpg";
let happy3 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435112/Maheshwari/Projects/Happy%20Homes%20V/3_h7qjux.jpg";
let happy4 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435112/Maheshwari/Projects/Happy%20Homes%20V/4_uz9vft.jpg";
let happy5 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435112/Maheshwari/Projects/Happy%20Homes%20V/5_tailab.jpg";
let happy6 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435113/Maheshwari/Projects/Happy%20Homes%20V/6_nzr5mx.jpg";
let ananda1 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435188/Maheshwari/Projects/Ananda/f7jdeyuhh6sp9w1rkenr.jpg";
let ananda2 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435096/Maheshwari/Projects/Ananda/2_pshoep.jpg";
let ananda3 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435096/Maheshwari/Projects/Ananda/3_oxtfgc.jpg";
let ananda4 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435096/Maheshwari/Projects/Ananda/4_wsfcun.jpg";
let ananda5 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435096/Maheshwari/Projects/Ananda/5_d4gtov.jpg";
let ananda6 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435096/Maheshwari/Projects/Ananda/6_vjxikj.jpg";
let ananda7 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1571987942/Maheshwari/Projects/Ananda/IMG-20190226-WA0010_mxjauz.jpg";
let ananda8 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1571987942/Maheshwari/Projects/Ananda/IMG-20190226-WA0014_rypk7v.jpg";
let ananda9 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1571987942/Maheshwari/Projects/Ananda/IMG-20190226-WA0006_ieq1zu.jpg";
let ananda10 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1571987942/Maheshwari/Projects/Ananda/IMG-20190226-WA0011_zcvhi8.jpg";
let ef1 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435107/Maheshwari/Projects/Embee%20Fortune/1_usxppo.jpg";
let ef2 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435108/Maheshwari/Projects/Embee%20Fortune/2_qpegtz.jpg";
let ef3 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435108/Maheshwari/Projects/Embee%20Fortune/3_wngj5v.jpg";
let ef4 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435110/Maheshwari/Projects/Embee%20Fortune/4_z33ftf.jpg";
let ef5 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435110/Maheshwari/Projects/Embee%20Fortune/5_i08txn.jpg";
let ef6 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435109/Maheshwari/Projects/Embee%20Fortune/6_h9ph6e.jpg";
let nh1 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435118/Maheshwari/Projects/Nirvana%20Homes/1_ccztsg.jpg";
let nh2 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435117/Maheshwari/Projects/Nirvana%20Homes/2_u1mpug.jpg";
let nh3 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435118/Maheshwari/Projects/Nirvana%20Homes/3_pmtydr.jpg";
let nh4 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435117/Maheshwari/Projects/Nirvana%20Homes/4_m87at3.jpg";
let nh5 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435118/Maheshwari/Projects/Nirvana%20Homes/5_prfzbk.jpg";
let nh6 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435118/Maheshwari/Projects/Nirvana%20Homes/6_pxpjcz.jpg";
let ah1 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435097/Maheshwari/Projects/Asian%20Habitat/1_qzey3b.jpg";
let ah2 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435098/Maheshwari/Projects/Asian%20Habitat/2_eluzu4.jpg";
let purti1 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435123/Maheshwari/Projects/Purti%20Planet/1_ph80jm.jpg";
let purti2 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435123/Maheshwari/Projects/Purti%20Planet/2_oyr3ws.jpg";
let purti3 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435124/Maheshwari/Projects/Purti%20Planet/3_xrkr1f.jpg";
let purti4 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435124/Maheshwari/Projects/Purti%20Planet/4_bo6fcd.jpg";
let purti5 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435124/Maheshwari/Projects/Purti%20Planet/5_zdfkal.jpg";
let purti6 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435124/Maheshwari/Projects/Purti%20Planet/6_no8vpm.jpg";
let pj1 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435123/Maheshwari/Projects/purti%20jewel/1_ohoogd.jpg";
let pj2 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435123/Maheshwari/Projects/purti%20jewel/2_ckufd1.jpg";
let pj3 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435123/Maheshwari/Projects/purti%20jewel/3_xpwlqw.jpg";
let pj4 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435123/Maheshwari/Projects/purti%20jewel/4_egbguf.jpg";
let pj5 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435123/Maheshwari/Projects/purti%20jewel/5_wrxhfc.jpg";
let pj6 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435123/Maheshwari/Projects/purti%20jewel/6_dt17iq.jpg";
let ls1 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435098/Maheshwari/Projects/6%20Loudon%20Street/1_kc1qmx.jpg";
let raghab1 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435125/Maheshwari/Projects/Raghabpur%20Marketing%20Office/1_yakp3n.jpg";
let raghab2 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435125/Maheshwari/Projects/Raghabpur%20Marketing%20Office/2_v7eevn.jpg";
let raghab3 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435125/Maheshwari/Projects/Raghabpur%20Marketing%20Office/3_hyvocf.jpg";
let raghab4 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435125/Maheshwari/Projects/Raghabpur%20Marketing%20Office/4_z5ebro.jpg";
let raghab5 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435125/Maheshwari/Projects/Raghabpur%20Marketing%20Office/5_w8iovi.jpg";
let raghab6 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435125/Maheshwari/Projects/Raghabpur%20Marketing%20Office/6_vthrql.jpg";
let uni1 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1564723775/Maheshwari/Projects/Unimark%20Asian/12_exqzfv.jpg";
let uni2 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1564723775/Maheshwari/Projects/Unimark%20Asian/10_qbrsew.jpg";
let uni3 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1564723775/Maheshwari/Projects/Unimark%20Asian/13_dcqwcf.jpg";
let uni4 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1564723775/Maheshwari/Projects/Unimark%20Asian/11_zfuzmk.jpg";
let uni5 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435156/Maheshwari/Projects/Unimark%20Asian/2_aht3lp.jpg";
let uni6 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435156/Maheshwari/Projects/Unimark%20Asian/3_syxfvn.jpg";
let ca1 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1571991966/Maheshwari/Projects/23%20Circus%20Avenue/IMG_1513_lchuke.jpg";
let ca2 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1571991966/Maheshwari/Projects/23%20Circus%20Avenue/IMG_1499_xqwqr1.jpg";
let ca3 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1571991966/Maheshwari/Projects/23%20Circus%20Avenue/IMG_1502_zd8dem.jpg";
let ca4 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1571991966/Maheshwari/Projects/23%20Circus%20Avenue/IMG_1506_s7ydhk.jpg";
let ca5 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1571991966/Maheshwari/Projects/23%20Circus%20Avenue/IMG_1496_qdogc2.jpg";
let ca6 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1571991966/Maheshwari/Projects/23%20Circus%20Avenue/IMG_1505_dkd17r.jpg";
let baid1 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1571988327/Maheshwari/Projects/Baid%27s%20Residence/20180624143241_IMG_0307_1_g2kyg3.jpg";
let baid2 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1571988326/Maheshwari/Projects/Baid%27s%20Residence/20180624141744_IMG_0265_ec1g0r.jpg";
let baid3 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1571988326/Maheshwari/Projects/Baid%27s%20Residence/20180624142639_IMG_0283_wb9apk.jpg";
let baid4 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1571988326/Maheshwari/Projects/Baid%27s%20Residence/20180624144407_IMG_0338_1_qbuhes.jpg";

let tec1 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435143/Maheshwari/Projects/Tata%20Eden%20Court/1_rpe5nh.jpg";
let tec2 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435143/Maheshwari/Projects/Tata%20Eden%20Court/2_u5d6b4.jpg";
let tec3 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435144/Maheshwari/Projects/Tata%20Eden%20Court/3_asimwc.jpg";
let tec4 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435144/Maheshwari/Projects/Tata%20Eden%20Court/4_dkc22h.jpg";
let tec5 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435144/Maheshwari/Projects/Tata%20Eden%20Court/5_wmikmu.jpg";
let tec6 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435144/Maheshwari/Projects/Tata%20Eden%20Court/6_q581nx.jpg";
let trump1 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435155/Maheshwari/Projects/Trump%20Tower/1_zhc2ku.jpg";
let trump2 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435155/Maheshwari/Projects/Trump%20Tower/2_xlinwc.jpg";
let trump3 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435155/Maheshwari/Projects/Trump%20Tower/3_pbpi2a.jpg";
let trump4 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435155/Maheshwari/Projects/Trump%20Tower/4_gm7gk1.jpg";
let trump5 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435155/Maheshwari/Projects/Trump%20Tower/5_lvwvvj.jpg";
let trump6 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435156/Maheshwari/Projects/Trump%20Tower/6_rfuwju.jpg";
let prana1 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435120/Maheshwari/Projects/Prana%20Housing%20Complex/1_unlkas.jpg";
let prana2 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435120/Maheshwari/Projects/Prana%20Housing%20Complex/2_vklbsq.jpg";
let prana3 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435121/Maheshwari/Projects/Prana%20Housing%20Complex/3_dfvp0a.jpg";
let prana4 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435121/Maheshwari/Projects/Prana%20Housing%20Complex/4_tttcu7.jpg";
let prana5 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435121/Maheshwari/Projects/Prana%20Housing%20Complex/5_hkpdpu.jpg";
let prana6 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435121/Maheshwari/Projects/Prana%20Housing%20Complex/6_p9hbiz.jpg";
let amarana1 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435095/Maheshwari/Projects/Amarana/1_qpuh4x.jpg";
let amarana2 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435094/Maheshwari/Projects/Amarana/2_nmitlx.jpg";
let amarana3 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435095/Maheshwari/Projects/Amarana/3_exmwfj.jpg";
let amarana4 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435095/Maheshwari/Projects/Amarana/4_ifvltt.jpg";
let amarana5 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435095/Maheshwari/Projects/Amarana/5_afuzfu.jpg";
let amarana6 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435095/Maheshwari/Projects/Amarana/6_sx2ek7.jpg";
let delight1 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435106/Maheshwari/Projects/Embee%20Delight/1_s8juvu.jpg";
let delight2 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435107/Maheshwari/Projects/Embee%20Delight/2_txinhe.jpg";
let delight3 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435106/Maheshwari/Projects/Embee%20Delight/3_brcozv.jpg";
let delight4 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435106/Maheshwari/Projects/Embee%20Delight/4_kddpxx.jpg";
let delight5 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435107/Maheshwari/Projects/Embee%20Delight/5_ywfrsq.jpg";
let delight6 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435107/Maheshwari/Projects/Embee%20Delight/6_ul6vtk.jpg";
let river1 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435159/Maheshwari/Projects/Vinayak%20Riverlink/1_opxvjg.jpg";
let river2 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435159/Maheshwari/Projects/Vinayak%20Riverlink/2_ki7vlq.jpg";
let river3 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435159/Maheshwari/Projects/Vinayak%20Riverlink/3_d0ywps.jpg";
let river4 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435160/Maheshwari/Projects/Vinayak%20Riverlink/4_wlnssr.jpg";
let river5 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435160/Maheshwari/Projects/Vinayak%20Riverlink/5_jfxxle.jpg";
let river6 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435160/Maheshwari/Projects/Vinayak%20Riverlink/6_vbwj1l.jpg";
let lake1 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435114/Maheshwari/Projects/Lakewood%20Estate/1_fxfv8k.jpg";
let lake2 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435114/Maheshwari/Projects/Lakewood%20Estate/2_eartuv.jpg";
let lake3 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435115/Maheshwari/Projects/Lakewood%20Estate/3_cdc1nq.jpg";
let lake4 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435115/Maheshwari/Projects/Lakewood%20Estate/4_hymywl.jpg";
let lake5 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435115/Maheshwari/Projects/Lakewood%20Estate/5_qexxoq.jpg";
let lake6 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435115/Maheshwari/Projects/Lakewood%20Estate/6_g97zg5.jpg";
let kidwai =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435098/Maheshwari/Projects/43%20Kidwai%20Road/1_ggce5u.jpg";
let shyam1 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435130/Maheshwari/Projects/Shyam%20Steel/1_fewzxy.jpg";
let shyam2 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435130/Maheshwari/Projects/Shyam%20Steel/2_vbs4sx.jpg";
let shyam3 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435130/Maheshwari/Projects/Shyam%20Steel/3_jumdw0.jpg";
let dd27 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435106/Maheshwari/Projects/DD27/1_vygvct.jpg";
let star1 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435142/Maheshwari/Projects/Starlit%20Suites/1_tajxqb.jpg";
let star2 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435142/Maheshwari/Projects/Starlit%20Suites/2_jo92ku.jpg";
let star3 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435143/Maheshwari/Projects/Starlit%20Suites/3_xn7nmj.jpg";
let star4 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435143/Maheshwari/Projects/Starlit%20Suites/4_psh1px.jpg";
let star5 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435143/Maheshwari/Projects/Starlit%20Suites/5_u17evj.jpg";
let star6 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435144/Maheshwari/Projects/Starlit%20Suites/6_ifuqxy.jpg";
let empire1 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435116/Maheshwari/Projects/Magnolia%20Empire/1_dfqc6y.jpg";
let empire2 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435117/Maheshwari/Projects/Magnolia%20Empire/2_uu0gnn.jpg";
let empire3 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435117/Maheshwari/Projects/Magnolia%20Empire/3_exlaht.jpg";
let empire4 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435117/Maheshwari/Projects/Magnolia%20Empire/4_rtngnk.jpg";
let murari =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435117/Maheshwari/Projects/Muraripukur/1_uh3jxc.jpg";
let gm1 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435111/Maheshwari/Projects/Grand%20Mall/1_kvch98.jpg";
let gm2 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435111/Maheshwari/Projects/Grand%20Mall/2_oo1xbr.jpg";
let gm3 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435111/Maheshwari/Projects/Grand%20Mall/3_o0svpv.jpg";
let gm4 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435112/Maheshwari/Projects/Grand%20Mall/4_alzlqo.jpg";
let gm5 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435112/Maheshwari/Projects/Grand%20Mall/5_hgqdxo.jpg";
let gm6 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435112/Maheshwari/Projects/Grand%20Mall/6_p3d5kv.jpg";
// let besco1 = "../images/Projects/Besco/1.jpg";
// let besco2 = "../images/Projects/Besco/2.jpg";
// let besco3 = "../images/Projects/Besco/3.jpg";
// let besco4 = "../images/Projects/Besco/4.jpg";
// let besco5 = "../images/Projects/Besco/5.jpg";
// let besco6 = "../images/Projects/Besco/6.jpg";
// let bt1 = "../images/Projects/BT Mall/1.jpg";
// let bt2 = "../images/Projects/BT Mall/2.jpg";
// let bt3 = "../images/Projects/BT Mall/3.jpg";
// let bt4 = "../images/Projects/BT Mall/4.jpg";
// let bt5 = "../images/Projects/BT Mall/5.jpg";
// let bt6 = "../images/Projects/BT Mall/6.jpg";
let cc1 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435101/Maheshwari/Projects/Cinema%20Centenary/1_grmbal.jpg";
let cc2 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435192/Maheshwari/Projects/Cinema%20Centenary/c74krk2phwvo0nzqeirg.jpg";
let old1 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435119/Maheshwari/Projects/Old%20Age%20Home/1_i086hd.jpg";
let old2 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435119/Maheshwari/Projects/Old%20Age%20Home/2_uv946c.jpg";
let old3 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435119/Maheshwari/Projects/Old%20Age%20Home/3_l6ydcs.jpg";
let old4 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435120/Maheshwari/Projects/Old%20Age%20Home/4_i7rtii.jpg";
let old5 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435120/Maheshwari/Projects/Old%20Age%20Home/5_dchdfk.jpg";
let old6 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435120/Maheshwari/Projects/Old%20Age%20Home/6_qtdnhe.jpg";
let yuva1 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435162/Maheshwari/Projects/Yuva%20Kendra/1_ljfzct.jpg";
let yuva2 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435162/Maheshwari/Projects/Yuva%20Kendra/2_jsdyfq.jpg";
let yuva3 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435162/Maheshwari/Projects/Yuva%20Kendra/3_edckcn.jpg";
let techno1 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435155/Maheshwari/Projects/Techno%20India/1_m9wlux.jpg";
let techno2 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435154/Maheshwari/Projects/Techno%20India/2_tifiua.jpg";
let techno3 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435154/Maheshwari/Projects/Techno%20India/3_nud6es.jpg";
let techno4 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435149/Maheshwari/Projects/Techno%20India/4_xqfikv.jpg";
let techno5 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435150/Maheshwari/Projects/Techno%20India/5_mecmw9.jpg";
let techno6 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435151/Maheshwari/Projects/Techno%20India/6_axfdim.jpg";
let kalim1 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435113/Maheshwari/Projects/Kalimpong%20Mall/1_d8wac6.jpg";
let kalim2 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435113/Maheshwari/Projects/Kalimpong%20Mall/2_jpmubi.jpg";
let kalim3 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435115/Maheshwari/Projects/Kalimpong%20Mall/3_t0qnss.jpg";
let kalim4 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435113/Maheshwari/Projects/Kalimpong%20Mall/4_vomj1n.jpg";
let kalim5 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435113/Maheshwari/Projects/Kalimpong%20Mall/5_nssoxh.jpg";
let kalim6 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435114/Maheshwari/Projects/Kalimpong%20Mall/6_h9ef2k.jpg";
let austin1 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435188/Maheshwari/Projects/Austin%20Tower/nv42zpbpo5ws8sdplfsl.jpg";
let austin2 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435099/Maheshwari/Projects/Austin%20Tower/2_fi6tdc.jpg";
let austin3 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435099/Maheshwari/Projects/Austin%20Tower/3_hzxgf6.jpg";
let magnus1 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435098/Maheshwari/Projects/90%20Magnus/1_tl4vg3.jpg";
let kamakhya1 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435115/Maheshwari/Projects/Kamakhya%20Tower/1_df8ukx.jpg";
let kamakhya2 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435114/Maheshwari/Projects/Kamakhya%20Tower/2_gs4hyb.jpg";
let madhur1 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435116/Maheshwari/Projects/Madurdaha/1_gstdhh.jpg";
let madhur2 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435115/Maheshwari/Projects/Madurdaha/2_mwujiv.jpg";
let madhur3 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435116/Maheshwari/Projects/Madurdaha/3_zdtusq.jpg";
let madhur4 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435116/Maheshwari/Projects/Madurdaha/4_hp3x0o.jpg";
let madhur5 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435116/Maheshwari/Projects/Madurdaha/5_k55mde.jpg";
let gmall =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435111/Maheshwari/Projects/G%20Centre%20Mall/1_hnlnvq.jpg";
// let wtc1 = "../images/Projects/WTC/1.jpg";
// let wtc2 = "../images/Projects/WTC/2.jpg";
// let wtc3 = "../images/Projects/WTC/3.jpg";
// let wtc4 = "../images/Projects/WTC/4.jpg";
// let wtc5 = "../images/Projects/WTC/5.jpg";
// let wtc6 = "../images/Projects/WTC/6.jpg";
// let wtc7 = "../images/Projects/WTC/7.jpg";
let raghabb1 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435125/Maheshwari/Projects/Raghabpur%20Marketing%20Office/Interiors/1_vq4and.jpg";
let raghabb2 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435125/Maheshwari/Projects/Raghabpur%20Marketing%20Office/Interiors/2_yxumun.jpg";
let raghabb3 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435126/Maheshwari/Projects/Raghabpur%20Marketing%20Office/Interiors/3_cidlw2.jpg";
let raghabb4 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435126/Maheshwari/Projects/Raghabpur%20Marketing%20Office/Interiors/4_vil90r.jpg";
let raghabb5 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435126/Maheshwari/Projects/Raghabpur%20Marketing%20Office/Interiors/5_zmsdgc.jpg";
let raghabb6 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435126/Maheshwari/Projects/Raghabpur%20Marketing%20Office/Interiors/6_lmjg9r.jpg";
let unimark1 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1571987267/Maheshwari/Projects/Unimark%20Grand/IMG_1562_mzupua.jpg";
let unimark2 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1571987267/Maheshwari/Projects/Unimark%20Grand/IMG_1567_qj5iv6.jpg";
let unimark3 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1571987268/Maheshwari/Projects/Unimark%20Grand/IMG_1559_xz0lrs.jpg";
let unimark4 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1571987268/Maheshwari/Projects/Unimark%20Grand/IMG_1575_rvxz8i.jpg";
let unimark5 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1571987270/Maheshwari/Projects/Unimark%20Grand/IMG_1574_wiquxk.jpg";
let unimark6 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1571987588/Maheshwari/Projects/Unimark%20Grand/IMG_1558_xykwcl.jpg";
let x2 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435161/Maheshwari/Projects/X2/1_n5n4qk.jpg";
let cosmo1 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1571989717/Maheshwari/Projects/Cosmopolitant/IMG_1535_om6hdh.jpg";
let cosmo2 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1571989718/Maheshwari/Projects/Cosmopolitant/IMG_1542_ps0ahi.jpg";
let cosmo3 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1571989718/Maheshwari/Projects/Cosmopolitant/IMG_1526_wpdxe1.jpg";
let cosmo4 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1571989718/Maheshwari/Projects/Cosmopolitant/IMG_1534_pv0yfu.jpg";
let cosmo5 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1571989717/Maheshwari/Projects/Cosmopolitant/IMG_1520_ypyvq3.jpg";
let cosmo6 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1571989717/Maheshwari/Projects/Cosmopolitant/IMG_1516_ivetqr.jpg";
let cosmo7 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1571989717/Maheshwari/Projects/Cosmopolitant/IMG_1522_id4kkf.jpg";
let team15 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435166/Maheshwari/Team/AD/Sagar_Das_sxzunu.jpg";

let ornet1 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1571991107/Maheshwari/Projects/THE%20ORNATE%20HOTEL/JR4A3618_dosvbq.jpg";
let ornet2 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1571991107/Maheshwari/Projects/THE%20ORNATE%20HOTEL/JR4A3730_gz6jtp.jpg";
let ornet3 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1571991107/Maheshwari/Projects/THE%20ORNATE%20HOTEL/JR4A3468_xlu21k.jpg";
let ornet4 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1571991107/Maheshwari/Projects/THE%20ORNATE%20HOTEL/JR4A3497_stuoyj.jpg";
let ornet5 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1571991106/Maheshwari/Projects/THE%20ORNATE%20HOTEL/JR4A3215_oa2hb4.jpg";
let ornet6 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1571991107/Maheshwari/Projects/THE%20ORNATE%20HOTEL/JR4A3835_kzavvm.jpg";
let prasad =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563975846/Maheshwari/Team/AD/Ashutosh_Prasad_xqcprp.jpg";
let adika =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563975846/Maheshwari/Team/AD/Adrika_Muhuri_xyddzc.jpg";
let chayan =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563975846/Maheshwari/Team/AD/Chayan_Moitra_sbkyii.jpg";
let sakha =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563975846/Maheshwari/Team/AD/Sankhadeep_muymjj.jpg";
let som =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563975846/Maheshwari/Team/AD/SOMPRIYA_wneofj.jpg";

let sem1_1 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1564560389/Maheshwari/Seminar/100.0_zgs4wy.jpg";
let sem1_2 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1564560390/Maheshwari/Seminar/100.1_mzqm4g.jpg";
let sem1_3 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1564560389/Maheshwari/Seminar/100.2_w7tnyp.jpg";
let sem2_1 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1564560390/Maheshwari/Seminar/101.1_rubas6.jpg";
let sem2_2 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1564560390/Maheshwari/Seminar/101.2_mvbmkq.jpg";
let sem2_3 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1564560390/Maheshwari/Seminar/101.3_drn8ka.jpg";
let sem3_1 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1564560391/Maheshwari/Seminar/102.1_iiu1td.jpg";
let sem3_2 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1564560390/Maheshwari/Seminar/102.2_drrvp9.jpg";
let sem3_3 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1564560390/Maheshwari/Seminar/102.3_otfteo.jpg";
let sem4_1 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1564560391/Maheshwari/Seminar/103.1_u37x0o.jpg";
let sem5_1 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1564560391/Maheshwari/Seminar/104.0_bo4p5p.jpg";
let sem5_2 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1564560391/Maheshwari/Seminar/104.1_djmy6y.jpg";
let sem6_1 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1564560391/Maheshwari/Seminar/105.1_dxdivs.jpg";
let sem7_1 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1564560391/Maheshwari/Seminar/106.0_bpbszg.jpg";
let sem7_2 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1564560391/Maheshwari/Seminar/106.1_fwzfne.jpg";
let sem8_1 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1564560391/Maheshwari/Seminar/107.0_o1cwei.jpg";
let sem9_1 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1566567502/Maheshwari/Seminar/sem9_1_mlg0rd.jpg";
let sem9_2 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1566567501/Maheshwari/Seminar/sem9_2_jsgdwf.jpg";
let sem9_3 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1566567503/Maheshwari/Seminar/sem9_3_ahjzo1.jpg";
let sem9_4 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1566567501/Maheshwari/Seminar/sem9_4_lanot7.jpg";
let gaggar1 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1566565272/Maheshwari/Projects/Gaggar%20office/1_sa69cl.jpg";
let gaggar2 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1566565272/Maheshwari/Projects/Gaggar%20office/2_rtpru8.jpg";
let gaggar3 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1566565272/Maheshwari/Projects/Gaggar%20office/3_tabq20.jpg";
let gaggar4 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1566565272/Maheshwari/Projects/Gaggar%20office/4_c122dn.jpg";
let gaggar5 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1566565272/Maheshwari/Projects/Gaggar%20office/5_wqaal8.jpg";
let gaggar6 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1566565272/Maheshwari/Projects/Gaggar%20office/6_yowupg.jpg";
let sem10_1 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1571983529/Maheshwari/Seminar/sem10_1_jzp3ys.jpg";
let sem10_2 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1571983529/Maheshwari/Seminar/sem10_2_cxltz0.jpg";
let sem10_3 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1571983529/Maheshwari/Seminar/sem10_3_xhubcx.jpg";
let sem10_4 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1571983529/Maheshwari/Seminar/sem10_4_fjq617.jpg";
let sem10_5 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1571983529/Maheshwari/Seminar/sem10_5_fdhxlk.jpg";

//blog images

//Collective Living
let cl1 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1584689370/Maheshwari/blog/Collective%20Living/Image_4_dqooxd.jpg";
let cl2 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1584964492/Maheshwari/blog/Collective%20Living/Image_2_aolbaj.jpg";
let cl3 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1584964492/Maheshwari/blog/Collective%20Living/Image_3_z9vjqp.jpg";
let cl4 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1584964492/Maheshwari/blog/Collective%20Living/Image_5_ipduys.jpg";

//vertical Horizon
let vh1 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1584815310/Maheshwari/blog/Vertical%20Horizon/Image_1_gbawb8.jpg";
let vh2 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1584815309/Maheshwari/blog/Vertical%20Horizon/Image_2_kj7zdf.jpg";
let vh3 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1584815310/Maheshwari/blog/Vertical%20Horizon/Image_3_aihk1w.jpg";
let vh4 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1584815309/Maheshwari/blog/Vertical%20Horizon/Image_4_tzt4bx.jpg";
let vh5 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1584815308/Maheshwari/blog/Vertical%20Horizon/Image_6_dwgpzj.jpg";
let vh6 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1584815308/Maheshwari/blog/Vertical%20Horizon/Image_7_bu6ygw.jpg";
let vh7 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1584815310/Maheshwari/blog/Vertical%20Horizon/Image_8_vmergn.jpg";
let vh8 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1584815310/Maheshwari/blog/Vertical%20Horizon/Image_9_ihidfv.jpg";
let vhTable1 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1584815340/Maheshwari/blog/Vertical%20Horizon/table1_rq7yoi.png";
let vhTable2 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1584815340/Maheshwari/blog/Vertical%20Horizon/table2_eqqy5n.png";
let vhTable3 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1584815340/Maheshwari/blog/Vertical%20Horizon/table3_lnkjmb.png";
let vhTable4 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1584815341/Maheshwari/blog/Vertical%20Horizon/table4_tn2sdh.png";
let vhMegazine =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1584815309/Maheshwari/blog/Vertical%20Horizon/Magazine_Cover_on_bottom_of_the_page_gzteln.jpg";

//cinema cenetary
let cciu1 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1584856197/Maheshwari/blog/Cinema%20Cenetary:%20Imagine%20Unimagined/header_kalj0g.jpg";
let cciu2 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1584856197/Maheshwari/blog/Cinema%20Cenetary:%20Imagine%20Unimagined/VIEWS_pcysbd.jpg";
let cciu3 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1584856197/Maheshwari/blog/Cinema%20Cenetary:%20Imagine%20Unimagined/9_n5xsia.jpg";
//3102 BCE
let bce31021 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1585206262/Maheshwari/blog/3102%20BCE%20Vedic%20Resort/Courtyard_View_cu8jgs.jpg";
let bce31022 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1584858752/Maheshwari/blog/3102%20BCE%20Vedic%20Resort/image3_ronnjr.jpg";
let bce31023 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1584858751/Maheshwari/blog/3102%20BCE%20Vedic%20Resort/image2_itvtvv.jpg";
let bce31024 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1584858751/Maheshwari/blog/3102%20BCE%20Vedic%20Resort/image4_hotjoi.jpg";

// influencing design

let idesign1 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1584965988/Maheshwari/blog/Influencing%20Design/Unimark_5_lt05qx.jpg";
let idesign2 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1564723775/Maheshwari/Projects/Unimark%20Asian/13_dcqwcf.jpg";

//Simplicity & Complexity
let Simplicity1 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1597402368/Maheshwari/blog/Simplicity%20and%20Complexity%20In%20Architecture/2_ualkym.jpg";
let Simplicity2 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1597402367/Maheshwari/blog/Simplicity%20and%20Complexity%20In%20Architecture/1_mokqdb.jpg";

//whirlwind-of-covid-19

let whirlwind =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1599076882/Maheshwari/blog/FUTURE%20WORKPLACES/3622110_payccw.jpg";

//future impact of covid

let futureimpact =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1599576638/Maheshwari/blog/FUTURE%20WORKPLACES/3980501_nofwdw.jpg";


// Creating A Landmark in kolkata
let landmark1 = "https://res.cloudinary.com/dubm0zyx2/image/upload/v1613024444/Maheshwari/blog/Creating%20a%20landmark%20in%20kolkata/Unimark_-_Side_profile_1_iamdh0.jpg";
let landmark2 = "https://res.cloudinary.com/dubm0zyx2/image/upload/v1613024450/Maheshwari/blog/Creating%20a%20landmark%20in%20kolkata/Facade_1_vuacim.jpg";
let landmark3 = "https://res.cloudinary.com/dubm0zyx2/image/upload/v1613024458/Maheshwari/blog/Creating%20a%20landmark%20in%20kolkata/Unimark-Asian_5_xtgtph.jpg";
let landmark4 = "https://res.cloudinary.com/dubm0zyx2/image/upload/v1613024448/Maheshwari/blog/Creating%20a%20landmark%20in%20kolkata/Design_Development_nzk1dx.jpg";

//  Re-inventing architecture with steel
let rei1 = "https://res.cloudinary.com/dubm0zyx2/image/upload/v1613028328/Maheshwari/blog/Re-inventing%20architecture%20with%20steel/Unimark-Asian_1_wsregf.jpg";
let rei2 = "https://res.cloudinary.com/dubm0zyx2/image/upload/v1613028322/Maheshwari/blog/Re-inventing%20architecture%20with%20steel/Unimark_-_Side_profile_2_vk9rru.jpg"

// Designing Amenities Spaces
let das1 = "https://res.cloudinary.com/dubm0zyx2/image/upload/v1619477640/Maheshwari/blog/Designing%20Amenities%20Spaces/1_wyw2ed.jpg";
let das2 = "https://res.cloudinary.com/dubm0zyx2/image/upload/v1619477640/Maheshwari/blog/Designing%20Amenities%20Spaces/2_zxbcdx.jpg";
let das3 = "https://res.cloudinary.com/dubm0zyx2/image/upload/v1619477639/Maheshwari/blog/Designing%20Amenities%20Spaces/3_jdz4ct.jpg";
let das4 = "https://res.cloudinary.com/dubm0zyx2/image/upload/v1619477639/Maheshwari/blog/Designing%20Amenities%20Spaces/4_s7d3js.jpg";
let das5 = "https://res.cloudinary.com/dubm0zyx2/image/upload/v1619477639/Maheshwari/blog/Designing%20Amenities%20Spaces/5_mmpv4d.jpg";
let das6 = "https://res.cloudinary.com/dubm0zyx2/image/upload/v1619477639/Maheshwari/blog/Designing%20Amenities%20Spaces/6_hmmceo.jpg";
let das7 = "https://res.cloudinary.com/dubm0zyx2/image/upload/v1619477639/Maheshwari/blog/Designing%20Amenities%20Spaces/7_mhyimi.jpg";
let das8 = "https://res.cloudinary.com/dubm0zyx2/image/upload/v1619477639/Maheshwari/blog/Designing%20Amenities%20Spaces/8_mksqk1.jpg";
let das9 = "https://res.cloudinary.com/dubm0zyx2/image/upload/v1619477639/Maheshwari/blog/Designing%20Amenities%20Spaces/9_vasgxx.jpg";
let das10 = "https://res.cloudinary.com/dubm0zyx2/image/upload/v1619477639/Maheshwari/blog/Designing%20Amenities%20Spaces/10_b5rtmc.jpg";
let das11 = "https://res.cloudinary.com/dubm0zyx2/image/upload/v1619477640/Maheshwari/blog/Designing%20Amenities%20Spaces/11_aggmg1.jpg";
let das12 = "https://res.cloudinary.com/dubm0zyx2/image/upload/v1619477639/Maheshwari/blog/Designing%20Amenities%20Spaces/12_vdma4z.jpg";
const projects = [
  // {
  //   category: "commercial-office",
  //   subcategory: "UPCOMING PROJECTS",
  //   title: "DARPAN MARKETING OFFICE",
  //   desc: "Silliguri",
  //   imgSRC: darpan2,
  //   thumbs: [darpan2, darpan1, darpan3, darpan4, darpan5, darpan6],
  //   header: "DARPAN MARKETING OFFICE",
  //   place: "Silliguri",
  //   Client: " Trupti",
  //   completion: "",
  //   area: "1250 sq.ft",
  //   description:
  //     "Materials used are Tiles, Laminate and Marble. Kubiq partitons. Aurooms- Acoustic panels, carpet flooring. Profile lights, downlights. Reception Area - Film on the circular ceiling. Reception, conference, Director's cabin, Accounts, Executive 1 & 2, Pantry, Pooja area, Common toilet."
  // },
  {
    category: "interior-design",
    subcategory: "UPCOMING PROJECTS",
    title: "Darpan Marketing Office",
    desc: "Siliguri, West Bengal",
    imgSRC: darpan2,
    thumbs: [darpan2, darpan1, darpan3, darpan4, darpan5, darpan6],
    header: "darpan-marketing-office ",
    place: "Siliguri, West Bengal",
    Client: "",
    completion: "",
    area: "1250 sq.ft",
    alt: "best architecture firms in Mumbai",
    description:
      "Materials used are Tiles, Laminate and Marble. Kubiq partitons. Aurooms- Acoustic panels, carpet flooring. Profile lights, downlights. Reception Area - Film on the circular ceiling. Reception, conference, Director's cabin, Accounts, Executive 1 & 2, Pantry, Pooja area, Common toilet.",
  },
  // {
  //   category: "commercial-office",
  //   subcategory: "COMPLETED PROJECTS",
  //   title: "RAGHABPUR MARKETING OFFICE ",
  //   desc: "Raghabpur",
  //   imgSRC: raghab2,
  //   thumbs: [raghab2, raghab3, raghab4, raghab5, raghab6],
  //   header: "RAGHABPUR MARKETING OFFICE ",
  //   place: "Raghabpur",
  //   Client: " Archana, Saynik & Karina",
  //   completion: "",
  //   area: "2560 sq.ft",
  //   description:
  //     "Minimilistic traditional interiors with the use of mouldings and trims and neutral color tone. The furnitures are made as per the theme with carved legs and European fur to it."
  // },
  {
    category: "interior-design",
    subcategory: "COMPLETED PROJECTS",
    title: "RAGHABPUR MARKETING OFFICE",
    desc: "Raghabpur, West Bengal",
    imgSRC: raghabb2,
    thumbs: [raghabb2, raghabb3, raghabb4, raghabb5, raghabb6],
    header: "raghabpur-marketing-office",
    place: "Raghabpur, West Bengal",
    Client: "",
    alt: "best architect firms in india",
    completion: "",
    area: "2560 sq.ft",
    description:
      "Minimilistic traditional interiors with the use of mouldings and trims and neutral color tone. The furnitures are made as per the theme with carved legs and European fur to it.",
  },

  // {
  //   category: "commercial-office",
  //   subcategory: "UPCOMING PROJECTS",
  //   title: "UNIMARK ASIAN LOBBY",
  //   desc: "Shakespeare Sarani",
  //   imgSRC: unil1,
  //   thumbs: [unil1],
  //   header: "UNIMARK ASIAN LOBBY",
  //   place: "Shakespeare Sarani",
  //   Client: " Archana, Subroto & Karina",
  //   completion: "",
  //   area: "2500 sq.ft",
  //   description:
  //     "Minimilistic traditional interiors with neutral color tone materials such as grey tiles and Italian black marble. A bronze metal jhali breaking the monochrome look. THe focal point would be a customised Chandeliers."
  // },
  {
    category: "interior-design",
    subcategory: "UPCOMING PROJECTS",
    title: "UNIMARK ASIAN LOBBY ",
    desc: "Shakespear Sarani, Kolkata",
    imgSRC: unil1,
    thumbs: [unil1],
    header: "unimark-asian-lobby ",
    place: "Shakespear Sarani, Kolkata",
    Client: " Unimark Group",
    alt: "interior designer in mumbai",
    completion: "",
    area: "2500 sq.ft",
    description:
      "Minimilistic traditional interiors with neutral color tone materials such as grey tiles and Italian black marble. A bronze metal jhali breaking the monochrome look. THe focal point would be a customised Chandeliers.",
  },
  {
    category: "hospitality",
    subcategory: "UPCOMING PROJECTS",
    title: "RARE EARTH",
    desc: "Narkeldanga, Kolkata",
    imgSRC: rare2,
    thumbs: [rare1, rare2, rare3, rare4, rare5, rare6],
    header: "rare-earth",
    place: "Narkeldanga, Kolkata",
    Client: " Prasad Group",
    completion: "",
    area: " 14,000 sqft",
    alt: "architect firm near me",
    description:
      "Minimilistic modern interiors with monochrome look and usage of luxurious materials such as Italian marble, wood, glass, and customised wallpaper.",
  },
  {
    category: "interior-design",
    subcategory: "UPCOMING PROJECTS",
    title: "RARE EARTH ",
    desc: "Narkeldanga, Kolkata",
    imgSRC: rare2,
    thumbs: [rare1, rare2, rare3, rare4, rare5, rare6],
    header: "rare-earth ",
    place: "Narkeldanga, Kolkata",
    Client: " Prasad Group",
    completion: "",
    alt: "architects website",
    area: " 14,000 sqft",
    description:
      "Minimilistic modern interiors with monochrome look and usage of luxurious materials such as Italian marble, wood, glass, and customised wallpaper.",
  },
  {
    category: "interior-design",
    subcategory: "UPCOMING PROJECTS",
    title: "SINGHANIA",
    desc: "",
    imgSRC: singh1,
    thumbs: [singh1, singh2, singh3, singh4, singh5, singh6],
    header: "singhania",
    place: "",
    Client: "",
    alt: "architect firms in mumbai",
    completion: "",
    area: "",
    description: "",
  },
  {
    category: "hospitality",
    subcategory: "UPCOMING PROJECTS",
    title: "SRIJAN ETERNIS",
    desc: "Madhyamgram, Kolkata",
    imgSRC: srijan2,
    thumbs: [srijan1, srijan2, srijan6, srijan4, srijan5, srijan3],
    header: "srijan-eternis",
    place: "Madhyamgram, Kolkata",
    Client: "",
    alt: "architecture companies in mumbai",
    completion: "2019",
    area: "24000 sq.ft",
    description:
      "Minimilistic modern interiors with open ceiling ducting concept and usage of luxurious materials with emphasis given on customised Chandeliers/hanging.",
  },
  {
    category: "interior-design",
    subcategory: "UPCOMING PROJECTS",
    title: "SRIJAN ETERNIS ",
    desc: "Madhyamgram, Kolkata",
    imgSRC: srijan2,
    thumbs: [srijan1, srijan2, srijan6, srijan4, srijan5, srijan3],
    header: "srijan-eternis ",
    place: "Madhyamgram, Kolkata",
    Client: "",
    alt: "interior design firms in kolkata",
    completion: "2019",
    area: "24000 sq.ft",
    description:
      "Minimilistic modern interiors with open ceiling ducting concept and usage of luxurious materials with emphasis given on customised Chandeliers/hanging.",
  },
  {
    category: "mixed",
    subcategory: "COMPLETED PROJECTS",
    title: "Tolly Exotica",
    desc: "Tollygunge, Kolkata",
    imgSRC: tolly1,
    thumbs: [tolly1, tolly2, tolly3, tolly4],
    header: "tolly-exotica",
    place: "Tollygunge, Kolkata",
    Client: "",
    completion: "2019",
    area: " 2655 sqm",
    alt: "best architecture firms in Mumbai",
    description:
      "The architecture responds to its dense urban context of south Kolkata and provides a mix of functions accompanied by luxurious spatial layouts. It allows the user to reconnect with nature in balconies and leisure terraces since it is a key to a healthy and holistic lifestyle.",
  },
  {
    category: "mixed",
    subcategory: "COMPLETED PROJECTS",
    title: "The Address",
    desc: "Dhapa, Kolkata",
    imgSRC: add6,
    thumbs: [add6, add2, add3, add4, add5, add1],
    header: "the-address",
    place: "Dhapa, Kolkata",
    Client: "",
    completion: "2017",
    area: "3721 sqm",
    alt: "best architect firms in india",
    description:
      "Adjacent to the East Kolkata Westlands and along the EM Bypass development corridor, the project delivers a mix of functions to cater to the fast urban lifestyle – its live-work relationship and its leisure.",
  },
  {
    category: "residential-high-rise-tower",
    subcategory: "COMPLETED PROJECTS",
    title: "Altius",
    desc: "Off Em Bypass, Kolkata",
    imgSRC: alt2,
    thumbs: [alt2, alt1, alt3],
    header: "altius",
    place: "Off Em Bypass, Kolkata",
    Client: " Amaya Realty",
    completion: "Work in Progress",
    area: "8159 sqm",
    alt: "residential project in kolkata",
    description:
      "Kolkata, being a crucial economic generator of Bengal, has transformed over time and undergoing a revamp of its lifestyle. The architecture of the two towers, hence, is designed bold and sharp, to complement the extroverted youthfulness of the modern society. Different apartment layouts have been worked out to cater to the user groups, along with an amenities terrace for social gatherings.",
  },
  {
    category: "residential-housing-complex",
    subcategory: "COMPLETED PROJECTS",
    title: "Primac Aura",
    desc: "Mankundu, West Bengal",
    imgSRC: aura1,
    thumbs: [aura1, aura2, aura3, aura4],
    header: "primac-aura",
    place: " Mankundu, West Bengal",
    Client: " Primac Group",
    alt: "maheswari and associates architects",
    completion: " Phase 1 complete, work in progress",
    area: "16896 sq.m",
    description:
      "The residential towers has been oriented to the local climate and provides state-of-the-art amenities, like landscaped garden, pool, gymnasium, mini theatre, library and kid's play areas for a more holistic lifestyle.",
  },
  {
    category: "residential-housing-complex",
    subcategory: "COMPLETED PROJECTS",
    title: "Happy Homes V",
    desc: "Ramchandrapur, Kolkata",
    imgSRC: happy2,
    thumbs: [happy6, happy2, happy3, happy1, happy5, happy4],
    header: "happy-homes-v",
    place: "Ramchandrapur, Kolkata",
    Client: " N K Realtors",
    alt: "maheshwari and associates",
    completion: "Work in Progress",
    area: "28926 sq.m",
    description:
      "The housing complex has been planned with optimum spatial layout strategies which responds to the community’s social space - in the form of a courtyard centric layout with landscape features for sitting and gathering. The built volume, oriented according to the sun path, keeps the courtyard shaded while the micro-climate generated keeps it breezy.",
  },
  {
    category: "residential-housing-complex",
    subcategory: "COMPLETED PROJECTS",
    title: "Ananda Housing",
    desc: "Dumdum, Kolkata",
    imgSRC: ananda1,
    thumbs: [
      ananda1,
      ananda2,
      ananda3,
      ananda4,
      ananda5,
      ananda6,
      ananda7,
      ananda8,
      ananda9,
      ananda10,
    ],
    header: "ananda-housing",
    place: "Dumdum, Kolkata",
    alt: "architecture firms in Kolkata",
    Client: " BBA Group",
    completion: "2014",
    area: " 9377 sq.m",
    description:
      "The fringes of Kolkata have undergone rapid development over the past few years, reinventing itself into one of the busiest and largest urban sprawl with residential ever increasing residential densities. In such rapidly changing times, the residential complex reinterprets the way we live by giving a taste of living in the laps of luxury. The built masses have been strategically planned around a courtyard responding to the local climate and wind flow patterns. Pauses in the built masses create circulation paths and visual connectivity in the site. The luxurious lifestyle overlooks tiers in the landscape generating podium level greens. The greenscape is activated with elegant features such as outdoor sitting spaces and ‘adda’ zones, children’s’ play area, pools, decks, etc. The design strives to create a unity between the built and the unbuilt through varied spatial typologies.",
  },
  {
    category: "residential-housing-complex",
    subcategory: "COMPLETED PROJECTS",
    title: "Embee Fortune",
    desc: "Siliguri, West Bengal",
    imgSRC: ef1,
    thumbs: [ef1, ef2, ef3, ef4, ef5, ef6],
    header: "embee-fortune",
    alt: "best architects in Mumbai",
    place: "Siliguri, West Bengal",
    Client: " Embee Builders",
    completion: "2018",
    area: "2.5 Acres",
    description:
      "Spread across 2.5 acres, 265 residential units have been juxtaposed with the natural setting at the lap of the Himalayan range to redefine luxurious urban life. It is hence crucial to create a site responsive, environment friendly design which consciously attempts to bring architecture closer to nature through spatial design and typologies. The exotic natural setting has been harmonised with amenities to create social spaces and activity magnets for the residential community.  A colour palette has been generated which tones the softness of the landscape with the vibrancy of life it sustains.",
  },
  {
    category: "residential-bunglow",
    subcategory: "COMPLETED PROJECTS",
    title: "Nirvana Homes",
    desc: "Siliguri, West Bengal",
    imgSRC: nh1,
    thumbs: [nh1, nh2, nh3, nh4, nh5, nh6],
    header: "nirvana-homes",
    place: "Siliguri",
    Client: " CP Group",
    alt: "architect Kolkata",
    completion: "2018",
    area: "2 Acres",
    alt: "residential project in kolkata",
    description:
      "At the lap of the Himalayan range and at the fringes of the urban sprawl, a residential community has been designed to create unity between the built and the unbuilt advocating pockets of green courts. With voluminous punctures in the masses and exquisite choice of materials, the design generates a seam with the continuing landscape and the urbanscape. The spatial planning generates a space of enclosure, ensuring a sense of security and tranquillity within the communal space – thus Nirvana homes!",
  },
  {
    category: "residential-high-rise-tower",
    subcategory: "COMPLETED PROJECTS",
    title: "Asian Habitat",
    desc: "Howrah, West Bengal",
    imgSRC: ah1,
    thumbs: [ah1, ah2],
    header: "asian-habitat",
    place: " Howrah, West Bengal",
    alt: "top architects in Kolkata",
    Client: " Asian Group",
    alt: "best architecture firms in Mumbai",
    completion: " 2017",
    area: " 1690 sq.m",
    description:
      "The extreme densities of the greater Kolkata metropolitan region – in context Howarh – challenged the spatial layout. The design creates optimum spatial optimization of its units and responds to the users interests and lifestyle.",
  },
  {
    category: "residential-housing-complex",
    subcategory: "COMPLETED PROJECTS",
    title: "Purti Planet",
    desc: "Kolkata",
    imgSRC: purti1,
    thumbs: [purti6, purti2, purti3, purti4, purti5, purti1],
    header: "purti-planet",
    place: "Kolkata",
    Client: " Purti Realty",
    alt: "best architects in Kolkata",
    completion: "2018",
    area: "5503 sq.m",
    description:
      "The southern part of Kolkata witnessed an increase of population during the partition of Bengal. In the extreme dense residential fabric, the buildings have been designed to allow maximum light and ventilation along with optimum spatial layout units of 2BHKs and 3BHKs. ",
  },
  {
    category: "residential-housing-complex",
    subcategory: "COMPLETED PROJECTS",
    title: "Purti Jewel",
    desc: "Kolkata",
    imgSRC: pj1,
    thumbs: [pj1, pj2, pj3, pj4, pj5, pj6],
    header: "purti-jewel",
    place: "Kolkata",
    Client: " Purti Realty",
    alt: "best architecture firms in Mumbai",
    completion: "2018",
    area: "3340 sq.m",
    description:
      "The southern part of Kolkata witnessed an increase of population during the partition of Bengal. In the extreme dense residential fabric, the buildings have been designed to allow maximum light and ventilation along with optimum spatial layout units of 2BHKs, 3BHKs and duplexes.",
  },
  {
    category: "commercial-office",
    subcategory: "COMPLETED PROJECTS",
    title: "6 Loudon Street",
    desc: "Loudon Street, Kolkata",
    imgSRC: ls1,
    thumbs: [ls1],
    header: "6-loudon-street",
    place: "Loudon Street, Kolkata",
    alt: "architect firm near me",
    Client: " Erudite Realty",
    completion: "2017",
    area: "1325 sq.m",
    description:
      "Near Kolkata's CBD, the office leaves a rectangular footprint with 7 storeyes. Voids in the massing and perforated metal skin creates an interesting flow of light and shadow.",
  },
  // {
  //   category: "commercial-office",
  //   subcategory: "COMPLETED PROJECTS",
  //   title: "Arch Waterfront",
  //   desc: "Salt Lake Sector V, Kolkata",
  //   imgSRC: pp7,
  //   thumbs: [k1, k2, k3, k4, k5],
  //   header: "Arch Waterfront",
  //   place: "Salt Lake Sector V, Kolkata",
  //   Client: " Subroto",
  //   completion: "",
  //   area: "153000 sq.ft",
  //   description:
  //     "The building has diverse range of functions, from retail showrooms to office spaces to multi-level car parks. The G + 17 structure overlooks the adjascent waterbodies and the layout is optimised to ensure a proper viewing angle."
  // },
  // {
  //   category: "commercial-office",
  //   subcategory: "COMPLETED PROJECTS",
  //   title: "Merlin Matrix",
  //   desc: "Salt Lake Sector V, Kolkata",
  //   imgSRC: pp7,
  //   thumbs: [k1, k2, k3, k4, k5],
  //   header: "Merlin Matrix",
  //   place: "Salt Lake Sector V, Kolkata",
  //   Client: "",
  //   completion: "",
  //   area: "",
  //   description:
  //     "The building envisions and compliments its sorrounding IT Hub. The exterior reflective glass reduces heat gain as well as adds to the contemporary aesthetics of the G + 9 structure."
  // },
  {
    category: "commercial-office",
    subcategory: "COMPLETED PROJECTS",
    title: "UNIMARK ASIAN",
    desc: "Shakespeare Sarani, Kolkata",
    imgSRC: uni1,
    thumbs: [uni1, uni2, uni3, uni4, uni5, uni6],
    header: "unimark-asian",
    place: "Shakespeare Sarani, Kolkata",
    Client: "",
    alt: "architecture companies in mumbai",
    completion: "",
    area: "2295 sq.m",
    description:
      "In the CBD of Kolkata, the building was designed with steel to create seamless outdoor and indoor environment for better workplace productivity maximization and generate FAR free sellable spaces and provide a range of office spaces. The staggered built form thus provides green terraces and an iconic structure to the city.",
  },
  {
    category: "commercial-office",
    subcategory: "COMPLETED PROJECTS",
    title: "23 Circus Avenue",
    desc: "Kolkata",
    imgSRC: ca1,
    alt: "top 10 architects in kolkata",
    thumbs: [ca1, ca2, ca3, ca4, ca5, ca6],
    header: "23-circus-avenue",
    place: "Kolkata",
    Client: " Isha Group",
    completion: "",
    area: "",
    description:
      "The premium office space designed in this project, envisions an efficient and comfortable work environment. Through exploration to re-imagine office spaces, the project is an anti-thesis to the present vocabulary where employees lack a social life created by the monotonous and generic spaces created. Use of open interactive green spaces, fins to block the western side heat gain, use of reflective materials for its facade and the science of vastu shastra has helped to craft the design.",
  },
  {
    category: "commercial-retail",
    subcategory: "COMPLETED PROJECTS",
    title: "G Centre Mall",
    desc: "Rabindra Sarani, Kolkata",
    imgSRC: gmall,
    thumbs: [gmall],
    header: "g-centre-mall",
    place: "Rabindra Sarani, Kolkata",
    alt: "architect Kolkata",
    Client: "",
    completion: "",
    area: "",
    description: "",
  },
  {
    category: "commercial-office",
    subcategory: "COMPLETED PROJECTS",
    title: "KAMAKHYA TOWER",
    desc: "Guwahati, Assam",
    imgSRC: kamakhya1,
    thumbs: [kamakhya1, kamakhya2],
    alt: "famous architects mumbai",
    header: "kamakhya-tower",
    place: "Guwahati, Assam",
    Client: "",
    completion: "",
    area: "4047 sq.m",
    description: "",
  },
  {
    category: "commercial-retail",
    subcategory: "COMPLETED PROJECTS",
    title: "Austin Tower",
    desc: "Newtown, Kolkata",
    imgSRC: austin1,
    thumbs: [austin1, austin2, austin3],
    header: "austin-tower",
    place: "Newtown, Kolkata",
    Client: "",
    alt: "top architects in Kolkata",
    completion: "",
    area: "",
    description:
      "The dynamicity of the radically changing smart city is introduced by the facade design and choice of materials to imbibe the essence of modernity. The B+G+9 storied business building incorporates office spaces and a car showroom.",
  },
  // {
  //   category: "institute",
  //   subcategory: "COMPLETED PROJECTS",
  //   title: "Narayana School",
  //   desc: "",
  //   imgSRC: pp7,
  //   thumbs: [k1, k2, k3, k4, k5],
  //   header: "Narayana School",
  //   place: "",
  //   Client: "",
  //   completion: "",
  //   area: "",
  //   description: ""
  // },
  // {
  //   category: "residential-housing-complex",
  //   subcategory: "COMPLETED PROJECTS",
  //   title: "Purti Colors",
  //   desc: "",
  //   imgSRC: pp7,
  //   thumbs: [k1, k2, k3, k4, k5],
  //   header: "Purti Colors",
  //   place: "",
  //   Client: " Bibek",
  //   completion: "",
  //   area: "",
  //   description: ""
  // },
  // {
  //   category: "residential-housing-complex",
  //   subcategory: "COMPLETED PROJECTS",
  //   title: "Saltee Spacio",
  //   desc: "",
  //   imgSRC: pp7,
  //   thumbs: [k1, k2, k3, k4, k5],
  //   header: "Saltee Spacio",
  //   place: "",
  //   Client: "",
  //   completion: "",
  //   area: "",
  //   description: ""
  // },
  {
    category: "residential-bunglow",
    subcategory: "COMPLETED PROJECTS",
    title: "Baid's Residence",
    desc: "Raichak, West Bengal",
    imgSRC: baid1,
    thumbs: [baid1, baid2, baid4, baid3],
    header: "baids-residence",
    place: "Raichak",
    Client: " Mr. Promod Baid",
    completion: " 2016",
    alt: "architect mumbai",
    area: " 1500 sq.m",
    description:
      "The basic goal of this project was to craft a space amidst the rural scape of Raichak, in the southern Bengal, beside Hoogli, which serves the experience of perfect leisure during the holidays off the city (Kolkata). The space thus, required to be a perfect amalgamation of a built form incorporated within the nature.  As the design was meant to be serene, the built form has been designed such that the horizontality acts to catch the cognitive psychology of the user towards calm and leisure feeling. With a minimalistic approach of design maximum aesthetic output has been derived with the use of basic materials, i.e., concrete and brick. The planning of the spaces have been done to separate the private and public spaces with a security options for the user. The incorporation of courtyard between the enclosed spaces always re-alives the biophilic affection of the people and thus is a place to celebrate nature.",
  },
  {
    category: "residential-housing-complex",
    subcategory: "COMPLETED PROJECTS",
    title: "Tata Eden Court",
    desc: "Rajarhat, Kolkata",
    imgSRC: tec1,
    thumbs: [tec1, tec2, tec3, tec4, tec5, tec6],
    header: "tata-eden-court",
    alt: "best architect firms in india",
    place: "Rajarhat, Kolkata",
    Client: " Tata Housing",
    completion: "",
    area: "8 Acres",
    description:
      "The design vision for New Town, Rajarhat project is to create an urban oasis in a new development of Kolkata. An urban street grid is superimposed on the site to draw upon series of lines representing main streets, alley ways, and centralized urban green area, providing a wide range of opportunities for all users of the buildings to mix. Five residential towers are rising from within an urban grid that is juxtaposed onto building elevations. The towers are uplifting and highlighted by coloured glass lantern screen and surrounded by fluid green garden with the club house located centrally as the jewel in the park. Tower D and E has been designed and configured with Vastu principle with a strong emphasis on south east exp sure for natural light and morning sun. Standing 23 stories high, Tower D and E are premium 3 BHK and duplex level penthouses unit creating strong visually stunning skyline with a lush 3 story sky garden at 17 floor. The tower elevations have been overlaid with a series of informal projecting frames as patterns, superimposed one over the other to create a visual depth to the facades at uneven intervals, accentuating the feeling of movement.",
  },
  // {
  //   category: "residential-high-rise-tower",
  //   subcategory: "UPCOMING PROJECTS",
  //   title: "SAMPURNA",
  //   desc: "Kamarhati",
  //   imgSRC: tec1,
  //   thumbs: [tec1, tec2, tec3, tec4, tec5, tec6],
  //   header: "SAMPURNA",
  //   place: "Kamarhati",
  //   Client: "",
  //   completion: "",
  //   area: "",
  //   description: ""
  // },
  {
    category: "residential-high-rise-tower",
    subcategory: "UPCOMING PROJECTS",
    title: "TRUMP TOWER",
    desc: "Off Em Bypass, Kolkata",
    imgSRC: trump1,
    thumbs: [trump1, trump2, trump3, trump4, trump6, trump5],
    header: "trump-tower",
    place: "Off Em Bypass, Kolkata",
    alt: "best architect firms in india",
    Client: " Unimark Group",
    completion: "",
    area: "",
    description: "",
  },
  {
    category: "residential-housing-complex",
    subcategory: "UPCOMING PROJECTS",
    title: "PRANA HOUSING COMPLEX",
    desc: "Siliguri, West Bengal",
    imgSRC: prana1,
    thumbs: [prana1, prana2, prana4, prana3, prana5, prana6],
    header: "prana-housing-complex",
    place: "Siliguri, West Bengal",
    Client: " Tiru Fine Residency LLP",
    alt: "best architect firms in india",
    completion: "",
    area: "",
    description: "",
  },
  {
    category: "residential-high-rise-tower",
    subcategory: "UPCOMING PROJECTS",
    title: "AMARANA",
    desc: "Maheswartala, Kolkata",
    imgSRC: amarana1,
    thumbs: [amarana1, amarana5, amarana6, amarana4, amarana2, amarana3],
    header: "amarana",
    place: "Maheswartala, Kolkata",
    Client: " Salarpuris Sattva Group",
    alt: "best architect firms in india",
    completion: "",
    area: "",
    description: "",
  },
  {
    category: "residential-housing-complex",
    subcategory: "UPCOMING PROJECTS",
    title: "Embee Delight",
    desc: "Siliguri, West Bengal",
    imgSRC: delight1,
    thumbs: [delight1, delight2, delight3, delight4, delight5, delight6],
    header: "embee-delight",
    place: "Siliguri, West Bengal",
    alt: "architect firms in mumbai",
    Client: " Embee Builders",
    completion: "",
    area: "",
    description: "",
  },
  {
    category: "residential-high-rise-tower",
    subcategory: "UPCOMING PROJECTS",
    title: "VINAYAK RIVERLINK",
    desc: "Howrah, West Bengal",
    imgSRC: river1,
    thumbs: [river1, river2, river3, river4, river5, river6],
    header: "vinayak-riverlink",
    place: "Howrah, West Bengal",
    alt: "best architect firms in india",
    Client: " Jai Vinayak Group",
    completion: "",
    area: "",
    description: "",
  },
  {
    category: "residential-housing-complex",
    subcategory: "UPCOMING PROJECTS",
    title: "LAKEWOOD ESTATE",
    desc: "Patuli, Kolkata",
    imgSRC: lake1,
    thumbs: [lake1, lake2, lake3, lake5, lake4, lake6],
    header: "lakewood-estate",
    place: "Patuli, Kolkata",
    Client: " Unimark Group",
    completion: "",
    area: "",
    description: "",
  },
  // {
  //   category: "residential-housing-complex",
  //   subcategory: "UPCOMING PROJECTS",
  //   title: "BAGRAJ PRAKRITI",
  //   desc: "",
  //   imgSRC: lake1,
  //   thumbs: [lake1, lake2, lake3, lake5, lake4, lake6],
  //   header: "BAGRAJ PRAKRITI",
  //   place: "",
  //   Client: "",
  //   completion: "",
  //   area: "",
  //   description: ""
  // },
  {
    category: "commercial-office",
    subcategory: "UPCOMING PROJECTS",
    title: "43 KIDWAI ROAD",
    desc: "Park Street, Kolkata",
    imgSRC: kidwai,
    thumbs: [kidwai],
    header: "43-kidwai-road",
    alt: "architects website",
    place: "Park Street, Kolkata",
    Client: "",
    completion: "",
    area: "",
    description: "",
  },
  // {
  //   category: "commercial-office",
  //   subcategory: "UPCOMING PROJECTS",
  //   title: "1005",
  //   desc: "",
  //   imgSRC: kidwai,
  //   thumbs: [kidwai],
  //   header: "1005",
  //   place: "",
  //   Client: "",
  //   completion: "",
  //   area: "",
  //   description: ""
  // },
  {
    category: "commercial-office",
    subcategory: "UPCOMING PROJECTS",
    title: "SHYAM STEEL",
    desc: "New Town, Kolkata",
    imgSRC: shyam3,
    thumbs: [shyam3, shyam2, shyam1],
    header: "shyam-steel",
    place: "New Town, Kolkata",
    alt: "architect firms in mumbai",
    Client: " Shyam Steel Industries",
    completion: "",
    area: "",
    description: "",
  },
  {
    category: "commercial-office",
    subcategory: "UPCOMING PROJECTS",
    title: "X2",
    desc: "Salt Lake, Kolkata",
    imgSRC: x2,
    thumbs: [x2],
    header: "X2",
    place: "Salt Lake, Kolkata",
    alt: "architects website",
    Client: " Arch Group",
    completion: "",
    area: "",
    description: "",
  },
  {
    category: "commercial-office",
    subcategory: "UPCOMING PROJECTS",
    title: "90 MAGNUS",
    desc: "Salt Lake, Kolkata",
    imgSRC: magnus1,
    thumbs: [magnus1],
    header: "90-magnus",
    place: "Salt Lake, Kolkata",
    Client: " 90Degrees",
    alt: "architect firms in mumbai",
    completion: "",
    area: "",
    description: "",
  },
  {
    category: "commercial-retail",
    subcategory: "UPCOMING PROJECTS",
    title: "DD27",
    desc: "Salt Lake, Kolkata",
    imgSRC: dd27,
    thumbs: [dd27],
    header: "dd27",
    place: "Salt Lake, Kolkata",
    Client: "",
    completion: "",
    area: "",
    alt: "best architects in Kolkata",
    description: "",
  },
  {
    category: "hospitality",
    subcategory: "UPCOMING PROJECTS",
    title: "STARLIT SUITES",
    desc: "",
    imgSRC: star1,
    thumbs: [star1, star2, star3, star4, star5, star6],
    header: "starlit-suites",
    place: "",
    Client: "",
    completion: "",
    alt: "top 10 architects in kolkata",
    area: "",
    description: "",
  },
  // {
  //   category: "hospitality",
  //   subcategory: "UPCOMING PROJECTS",
  //   title: "BC 2031 (VEDIC HOTEL)",
  //   desc: "",
  //   imgSRC: star1,
  //   thumbs: [star1, star2, star3, star4, star5, star6],
  //   header: "BC 2031 (VEDIC HOTEL)",
  //   place: "",
  //   Client: "",
  //   completion: "",
  //   area: "",
  //   description: ""
  // },
  // {
  //   category: "commercial-office",
  //   subcategory: "CONCEPTUAL",
  //   title: "WTC",
  //   desc: "Salt Lake, Kolkata",
  //   imgSRC: wtc1,
  //   thumbs: [wtc1, wtc2, wtc3, wtc4, wtc5, wtc6],
  //   header: "WTC",
  //   place: "Salt Lake, Kolkata",
  //   Client: " Bibek",
  //   completion: "",
  //   area: "",
  //   description: ""
  // },
  {
    category: "residential-housing-complex",
    subcategory: "CONCEPTUAL",
    title: "MAGNOLIA EMPIRE",
    desc: "Madhyamgram, Kolkata",
    imgSRC: empire2,
    thumbs: [empire2, empire1, empire3, empire4],
    header: "magnolia-empire",
    place: "Madhyamgram, Kolkata",
    Client: " Magnolia Realty",
    completion: "",
    area: "",
    description:
      "The residential towers are designed for luxurious living with state-of-the-art facilities such as landscaped gardens, infinity pool, gazebos to indoor libraries, community halls and gymnasiums.",
  },
  {
    category: "residential-high-rise-tower",
    subcategory: "CONCEPTUAL",
    title: "MURARIPUKUR TOWER",
    desc: "Ultadanga, Kolkata",
    imgSRC: murari,
    thumbs: [murari],
    header: "muraripukar-tower",
    place: "Ultadanga, Kolkata",
    Client: " Lunawat Realty",
    alt: "architect firms in mumbai",
    completion: "",
    area: "",
    description: "",
  },
  {
    category: "residential-high-rise-tower",
    subcategory: "CONCEPTUAL",
    title: "MADURDAHA",
    desc: "Madurdaha, Kolkata",
    imgSRC: madhur1,
    thumbs: [madhur5, madhur2, madhur3, madhur4, madhur1],
    header: "madurdaha",
    place: "Madurdaha, Kolkata",
    Client: " Merlin Group",
    completion: "",
    alt: "top architects in Kolkata",
    area: "",
    description: "",
  },
  // {
  //   category: "residential-high-rise-tower",
  //   subcategory: "CONCEPTUAL",
  //   title: "DURAGAPUR",
  //   desc: "Durgapur",
  //   imgSRC: murari,
  //   thumbs: [murari],
  //   header: "DURAGAPUR",
  //   place: "Durgapur",
  //   Client: "",
  //   completion: "",
  //   area: "",
  //   description: ""
  // },
  {
    category: "commercial-retail",
    subcategory: "CONCEPTUAL",
    title: "GRAND MALL",
    desc: "Guwahati, Assam",
    imgSRC: gm1,
    thumbs: [gm1, gm2, gm3, gm4, gm5, gm6],
    header: "grand-mall",
    place: "Guwahati, Assam",
    Client: " Brahmaputra Infrastructure Ltd",
    completion: "",
    alt: "best architecture firms in Mumbai",
    area: "",
    description: "",
  },

  {
    category: "cultural",
    subcategory: "CONCEPTUAL",
    title: "CINEMA CENTENARY",
    desc: "Tollygunge, Kolkata",
    imgSRC: cc1,
    thumbs: [cc1, cc2],
    header: "cinema-centenary",
    place: "Tollygunge, Kolkata",
    Client: "",
    completion: "",
    area: "",
    description:
      "The major concept that drove the evolution of the design was to create a mental imagery on the people about the history of movie making. By creating a built form that celebrates and imitates the history, could possibly a strategy to preserve the art that possibly shaped the present. It is also a journey to the memory lane for those who experienced the thing in their youths.  ",
  },
  {
    category: "hospitality",
    subcategory: "CONCEPTUAL",
    title: "OLD AGE HOME",
    desc: "New Town, Kolkata",
    imgSRC: old1,
    thumbs: [old1, old2, old3, old4, old5, old6],
    header: "old-age-home",
    place: "New Town, Kolkata",
    alt: "famous architects mumbai",
    Client: "",
    completion: "",
    area: "",
    description: "",
  },
  {
    category: "institute",
    subcategory: "CONCEPTUAL",
    title: "YUVA KENDRA",
    desc: "Kolkata",
    imgSRC: yuva1,
    thumbs: [yuva1, yuva2, yuva3],
    header: "yuva-kendra",
    place: "Kolkata",
    Client: "",
    completion: "",
    area: "",
    alt: "maheswari and associates architects",
    description: "",
  },
  {
    category: "institute",
    subcategory: "CONCEPTUAL",
    title: "TECHNO INDIA",
    desc: "Em Bypass, Kolkata",
    imgSRC: techno6,
    thumbs: [techno1, techno2, techno3, techno4, techno5],
    header: "techno-india",
    place: "Em Bypass, Kolkata",
    Client: "",
    completion: "",
    alt: "maheshwari and associates",
    area: "",
    description:
      "The college campus being crafted by exploring the aesthetics of modernism with a blend of globalized thoughts, which tries to capture the time and spirit of the students. One of the effective aspect of the building is people can get different views of the campus from the different connecting levels of the building. The person, who is at the higher level of the building at outer-side can get the view of lower level.",
  },
  {
    category: "commercial-retail",
    subcategory: "CONCEPTUAL",
    title: "KALIMPONG MALL",
    desc: "Kalimpong, West Bengal",
    imgSRC: kalim1,
    thumbs: [kalim1, kalim2, kalim3, kalim4, kalim5, kalim6],
    header: "kalimpong-mall",
    place: "Kalimpong, West Bengal",
    Client: "",
    completion: "",
    area: "",
    alt: "best architect firms in india",
    description: "",
  },
  {
    category: "interior-design",
    subcategory: "COMPLETED PROJECTS",
    title: "Gaggar Office",
    desc: "Guwahati, Assam",
    imgSRC: gaggar1,
    thumbs: [gaggar1, gaggar2, gaggar3, gaggar4, gaggar5, gaggar6],
    header: "gaggar-office",
    place: "Guwahati, Assam",
    Client: "",
    alt: "architect mumbai",
    completion: "2019",
    area: "4492 sf carpet area",
    description:
      "This project is a commercial office interior space developed on MG Road, Guwahati for P Gaggar & Associates. The office has contemporary sleek look with touch of modern colours and features. Furniture & desk spaces have grey tone laminated finish which suits the best for CA office culture. The plywood on one end of the office space is painted rich blue to contrast the monotony in office space. The cubicles have carpet tiles with glass partition to promote openness. Conference room has wall paper which was designed in-house by our designers. ",
  },
  {
    category: "residential-high-rise-tower",
    subcategory: "COMPLETED PROJECTS",
    title: "THE UNIMARK GRAND",
    desc: "Ballygunge, Kolkata",
    imgSRC: unimark2,
    thumbs: [unimark1, unimark2, unimark3, unimark4, unimark5, unimark6],
    header: "the-unimark-grand",
    place: "Ballygunge, Kolkata",
    alt: "best architects in Kolkata",
    Client: " Unimark Group",
    completion: "2014",
    area: "",
    description:
      "The Unimark Grand situated in the heart of South Kolkata is a lavish designed condominium.  With solo apartment on each floor, The Unimark Grand offers its users the privacy of homes in post location of Ballygunge. It is designed to standout in the skyline of Kolkata. ",
  },
  {
    category: "interior-design",
    subcategory: "COMPLETED PROJECTS",
    title: "COSMOPOLITANT GROUP",
    desc: "Ballygunge, Kolkata",
    imgSRC: cosmo1,
    thumbs: [cosmo1, cosmo2, cosmo3, cosmo4, cosmo5, cosmo6, cosmo7],
    header: "cosmopolitant-group",
    place: "Ballygunge, Kolkata",
    Client: "",
    alt: "top architects in Kolkata",
    completion: "",
    area: "",
    description:
      "Phase 1 of Renovating Cosmopolitan Club included designing modern, elegant restaurant with bar and community hall.",
  },
  {
    category: "interior-design",
    subcategory: "COMPLETED PROJECTS",
    title: "THE ORNATE HOTEL",
    desc: "Guwahati, Assam",
    imgSRC: ornet1,
    thumbs: [ornet1, ornet2, ornet3, ornet4, ornet5, ornet6],
    header: "the ornate-hotel",
    place: "Guwahati, Assam",
    Client: "",
    completion: "",
    area: "",
    alt: "best architects in Kolkata",
    description:
      "The luxury boutique hotel in Guwahati has been designed keeping comfort and pleasing aesthetics in mind.",
  },
  {
    category: "hospitality",
    subcategory: "COMPLETED PROJECTS",
    title: "THE ORNATE HOTEL ",
    desc: "Guwahati",
    imgSRC: ornet1,
    thumbs: [ornet1, ornet2, ornet3, ornet4, ornet5, ornet6],
    header: "the ornate-hotel ",
    place: "Guwahati",
    Client: "",
    completion: "",
    alt: "architects website",
    area: "",
    description:
      "The luxury boutique hotel in Guwahati has been designed keeping comfort and pleasing aesthetics in mind.",
  },
];
const categories = [
  {
    category: "RESIDENTIAL-HOUSING COMPLEX", //RESIDENTIAL-HOUSINGCOMPLEX
    link: "residential-housing-complex",
  },
  {
    category: "RESIDENTIAL-HIGH RISE TOWER", //RESIDENTIAL-HIGH RISE TOWER
    link: "residential-high-rise-tower",
  },
  {
    category: "RESIDENTIAL-BUNGLOW", //RESIDENTIAL-BUNGLOW
    link: "residential-bunglow",
  },
  {
    category: "COMMERCIAL-OFFICE", //COMMERCIAL-OFFICE
    link: "commercial-office",
  },
  {
    category: "COMMERCIAL-RETAIL", //COMMERCIAL-RETAIL
    link: "commercial-retail",
  },
  {
    category: "MIXED",
    link: "mixed",
  },
  {
    category: "INSTITUTE",
    link: "institute",
  },
  {
    category: "HOSPITALITY",
    link: "hospitality",
  },
  {
    category: "INTERIOR DESIGN",
    link: "interior-design",
  },
  {
    category: "CULTURAL",
    link: "cultural",
  },
];

const subcategories = [
  {
    subcategory: "ALL",
  },
  {
    subcategory: "COMPLETED PROJECTS",
  },
  {
    subcategory: "UPCOMING PROJECTS",
  },
  {
    subcategory: "CONCEPTUAL",
  },
];

const teams = [
  {
    timage: team4,
    name: "Ar. Bibek Mandal",
    post: "Senior Architect",
    teamname: "AD",
  },
  {
    timage: team7,
    name: "Ar. Mihir Patel",
    post: "Senior Architect",
    teamname: "AD",
  },
  {
    timage: team11,
    name: "Ar. Rajan Vishwakarma",
    post: "Senior Architect",
    teamname: "AD",
  },

  //EDIT
  {
    timage: team20,
    name: "Ar. Simply Das",
    post: "Jr. Architect",
    teamname: "AD",
  },
  {
    timage: team18,
    name: "Ar. Pooja Banerjee",
    post: "Jr. Architect",
    teamname: "AD",
  },
  {
    timage: team19,
    name: "Ar. Aritra Nath",
    post: "Project Architect",
    teamname: "AD",
  },
  {
    timage: team23,
    name: "Ar. Arnab Paul",
    post: "Jr. Architect",
    teamname: "AD",
  },
  {
    timage: team24,
    name: "Ar. Farah Kausar",
    post: "Jr. Architect",
    teamname: "AD",
  },
  {
    timage: team25,
    name: "Ar. Madhusnata Basu",
    post: "Jr. Architect",
    teamname: "AD",
  },
  {
    timage: team26,
    name: "Ar. Ritam Mitra",
    post: "Project Architect",
    teamname: "AD",
  },
  {
    timage: team27,
    name: "Pinki Sharma",
    post: "Front Office - Executive",
    teamname: "ADMIN",
  },
  {
    timage: team28,
    name: "Souvagya Palai",
    post: "IT - Networking",
    teamname: "ADMIN",
  },

  //EDIT endS here

  {
    timage: team1,
    name: "Ar. Achal Jain",
    post: "Junior Architect",
    teamname: "AD",
  },
  // {
  //   timage: team2,
  //   name: "Ar. Akash Shaw",
  //   post: "Junior Architect",
  //   teamname: "AD"
  // },
  // {
  //   timage: team3,
  //   name: "Ar. Aniruddha Mutsuddi",
  //   post: "Junior Architect",
  //   teamname: "AD"
  // },

  {
    timage: team5,
    name: "Ar. Kajal Roy Choudhury",
    post: "Junior Architect",
    teamname: "AD",
  },
  // {
  //   timage: team6,
  //   name: "Ar. Kumar Jyoti Nandy",
  //   post: "Junior Architect",
  //   teamname: "AD"
  // },

  {
    timage: team8,
    name: "Ar. Mousumi Banerjee",
    post: "Junior Architect",
    teamname: "AD",
  },
  // {
  //   timage: team9,
  //   name: "Ar. Piyashi Karmakar",
  //   post: "Junior Architect",
  //   teamname: "AD"
  // },
  {
    timage: team10,
    name: "Ar. Priyanka Paul",
    post: "Junior Architect",
    teamname: "AD",
  },

  {
    timage: team12,
    name: "Ar. Rishika Aggarwal ",
    post: "Junior Architect",
    teamname: "AD",
  },
  {
    timage: team13,
    name: "Ar. Rohan Datta",
    post: "Junior Architect",
    teamname: "AD",
  },
  // {
  //   timage: team14,
  //   name: "Ar. Rushi Bhogate",
  //   post: "Junior Architect",
  //   teamname: "AD"
  // },

  {
    timage: team16,
    name: "Ar. Shruti Alam",
    post: "Junior Architect",
    teamname: "AD",
  },

  // {
  //   timage: team18,
  //   name: "Ar. Trupti Prajapat",
  //   post: "Senior Architect",
  //   teamname: "AD"
  // },
  {
    timage: adika,
    name: "Ar. Adrika Muhuri",
    post: "Junior Architect",
    teamname: "AD",
  },
  {
    timage: prasad,
    name: "Ar. Ashutosh Prasad",
    post: "Junior Architect",
    teamname: "AD",
  },
  {
    timage: chayan,
    name: "Ar. Chayan Moitra",
    post: "Junior Architect",
    teamname: "AD",
  },
  // {
  //   timage: sakha,
  //   name: "Ar. Sankhadeep Ghosh",
  //   post: "Junior Architect",
  //   teamname: "AD"
  // },
  {
    timage: som,
    name: "Ar. Sompriya Dalui",
    post: "Junior Architect",
    teamname: "AD",
  },

  {
    timage: "",
    name: "Ar. Rahul Majumder",
    post: "Senior Architect",
    teamname: "AD",
  },

  {
    timage: "",
    name: "Ar. Sonali Mallick",
    post: "Junior Architect",
    teamname: "AD",
  },

  // {
  //   timage: team19,
  //   name: "Ar. Jyoti Hase",
  //   post: "Senior Interior Designer",
  //   teamname: "ID"
  // },
  // {
  //   timage: team20,
  //   name: "Ar. Karina Shetty",
  //   post: "Senior Interior Designer",
  //   teamname: "ID"
  // },
  {
    timage: team21,
    name: "Ar. Mohan Kukreja",
    post: "Junior Interior Designer",
    teamname: "ID",
  },
  // {
  //   timage: "",
  //   name: "Ar. Akash Basu",
  //   post: "Junior Interior Designer",
  //   teamname: "ID"
  // },
  {
    timage: team22,
    name: "Babu Das",
    post: "Accountant",
    teamname: "ADMIN",
  },
  // {
  //   timage: team23,
  //   name: "Gurpreet Kaur Raah",
  //   post: "Human Resources",
  //   teamname: "ADMIN"
  // },

  // {
  //   timage: "",
  //   name: "Vikash Vaidya",
  //   post: "Senior Accountant",
  //   teamname: "ADMIN"
  // },
  {
    timage: "",
    name: "Ar. Sudipto Roy",
    post: "Civil Engineer",
    teamname: "SS",
  },
  {
    timage: "",
    name: "Ar. Jit Roy",
    post: "Jr. Architect",
    teamname: "AD",
  },
  {
    timage: "",
    name: "Ar. Subhadip Ghosh",
    post: "Senior Architect",
    teamname: "AD",
  },
];

const seminar = [
  {
    img: [sem1_1, sem1_3],
    BWimg: sem1_3,
    idd: 0,
  },
  {
    img: [sem2_1, sem2_2, sem2_3],
    BWimg: sem2_1,
    idd: 1,
  },
  {
    img: [sem3_1, sem3_2, sem3_3],
    BWimg: sem3_1,
    idd: 2,
  },
  {
    img: [sem4_1],
    BWimg: sem4_1,
    idd: 3,
  },
  {
    img: [sem5_1, sem5_2],
    BWimg: sem5_1,
    idd: 4,
  },
  {
    img: [sem6_1],
    BWimg: sem6_1,
    idd: 5,
  },
  {
    img: [sem7_1, sem7_2],
    BWimg: sem7_1,
    idd: 6,
  },
  {
    img: [sem8_1],
    BWimg: sem8_1,
    idd: 7,
  },
  {
    img: [sem9_1, sem9_2, sem9_3, sem9_4],
    BWimg: sem9_1,
    idd: 8,
  },

  {
    img: [sem10_1, sem10_2, sem10_3, sem10_4, sem10_5],
    BWimg: sem10_1,
    idd: 9,
  },
];

const SEOTags = [
  {
    category: "residential-housing-complex",
    meta_title: "Residential Housing Projects | Maheshwari & Associates",
    meta_desc:
      "Maheshwari & Associates have designed Residential Housing Complex Projects in Mumbai, Kolkata with units including apartments, row houses and duplexes.",
    meta_tag:
      "top 10 architects in kolkata,famous architects mumbai,Residential housing Complex,,residential project in kolkata",
  },
  {
    category: "residential-bunglow",
    meta_title: "Residential Bunglow Projects | Maheshwari & Associates",
    meta_desc:
      "Maheshwari & Associates have designed residential bunglow Projects in Silliguri and Raichak. Property are planned to optimise the real estate value by design.",
    meta_tag:
      "Residential Bunglow Projects ,Famous architects mumbai,residential bunglow,architects website",
  },
  {
    category: "commercial-office",
    meta_title: "Commercial Office Projects | Maheshwari & Associates",
    meta_desc:
      "Maheshwari & Associates have designed various commercial office projects in Kolkata. There are a few upcoming real estate projects in Northeastern India. ",
    meta_tag:
      "Commercial Office Projects, top architects in kolkata, top 10 architects in kolkata,famous architects mumbai,Commercial office projects",
  },
  {
    category: "commercial-retail",
    meta_title: "Commercial Retail Projects | Maheshwari & Associates",
    meta_desc:
      "Maheshwari & Associates are one of the top architects in India. We have designed Commercial retail Projects and shopping malls in Guwahati and Kolkata.",
    meta_tag:
      "top architects in kolkata, top 10 architects in kolkata,famous architects mumbai,Commercial retail projects",
  },
  {
    category: "residential--high-rise-tower",
    meta_title: "Residential Tower Projects | Maheshwari & Associates",
    meta_desc:
      "Maheshwari & Associates are top architects in India. We have designed various iconic and skyline defining residential high rise Tower Projects.",
    meta_tag:
      "famous architects mumbai,Residential high projects,top architects in kolkata, top 10 architects in kolkata",
  },
  {
    category: "interior-design",
    meta_title: "Interior Design Projects | Maheshwari & Associates",
    meta_desc:
      "Maheshwari & Associates is one of the best interior designing firm in India. We have designed lobby, clubhouse, restaurants, marketing office and duplexes.",
    meta_tag:
      "interior projects,interior design firms in kolkata,best interior designing firm in kolkata,best interior designing firm in mumbai",
  },
  {
    category: "cultural",
    meta_title: "Cultural Projects | Maheshwari & Associates",
    meta_desc:
      "Maheshwari & Associates are famous architects from Mumbai and Kolkata. We have conceptualized Cultural projects design giving it modern architural outlook.",
    meta_tag:
      "top architects in kolkata, top 10 architects in kolkata,famous architects mumbai, cultural projects",
  },
  {
    category: "mixed",
    meta_title: "Mixed Use Projects | Maheshwari & Associates",
    meta_desc:
      "Maheshwari & Associates designed commercial and residential projects. The real estate consists of Mixed Use properties with office spaces and apartments.",
    meta_tag:
      "famous architects mumbai, top architects in kolkata,famous architects mumbai,Mixed Projects",
  },
  {
    category: "hospitality",
    meta_title: "Hospitality Projects | Maheshwari & Associates",
    meta_desc:
      "Maheshwari & Associates have designed Hospitility Projects such as hotels, suites, restaurants and lounge. Architecturally we provide various type of services.",
    meta_tag:
      "top architects in kolkata, famous architects mumbai,Hospitility projects",
  },
  {
    category: "institute",
    meta_title: "INSTITUTE Projects | Maheshwari & Associates",
    meta_desc:
      "Maheshwari & Associates are one of the top architects in Kolkata .We have designed Institute Projects such as college and schools with contemporary look. ",
    meta_tag:
      "top 10 architects in kolkata,famous architects mumbai,Institute projects",
  },
];
const blog = [
  {
    title: "Designing Amenities Spaces: Insight into premium residential towers",
    link: "designing-amenities-spaces",
    tags: "Residential Project, Collective Living, High-rise tower, Kolkata",
    tags: [
      {
        tag: "Residential Project,",
        link: "/category/residential-bunglow",
      },
      {
        tag: " Collective Living,",
        link: "/publication/collective-living-in-design",
      },
      {
        tag: " High-rise tower,",
        link: "/category/residential-high-rise-tower",
      },
      {
        tag: " Kolkata",
        link: "#",
      }
    ],
    published: "",
    blogBy: "Diksha Kabra",
    date: "November 2020",
    headerImg: das1,

    sideDescription: [
      "Architecture is about designing spaces and planning resources to fit in the budget while adeptly suiting the needs of the users. Designing amenities and common area (arrival & lobby) spaces to fit the economic category of the buyers along with their social status is a challenge. A good design is a considerate balance and a harmonious aggregate of desirable elements that reflect the internal and structural planning.",
    ],
    description1: [
      "Architecture is about designing spaces and planning resources to fit in the budget while adeptly suiting the needs of the users. Designing amenities and common area (arrival & lobby) spaces to fit the economic category of the buyers along with their social status is a challenge. A good design is a considerate balance and a harmonious aggregate of desirable elements that reflect the internal and structural planning.",
      "Ornamentation in interior designing isn’t the only way to achieve a luxurious look and feel of premium residential towers. These spaces need to be well-crafted plans that captivate the attention and give a luxurious feel by using a variety of materials, hues, and textures.",
      "The high-end residential complexes have become a part of the endless wish list in the urban fabric. However, it is also important to keep the fabric of the city and the needs of its residents in mind. Collective living has been in the fabric of Indian lifestyle since decades and centuries. However, with the changing urban landscape and vertical villages, it's becoming ever so difficult to keep this principle alive. While designing the amenities spaces as a designer, it is important to ensure the private lives are merged well with the social life to inculcate the innate feeling of togetherness. In one of our premium residential tower projects, Altius is taking the living standards of Kolkata to the next level. Located in the heart of the city and close to all modern amenities, Altius incorporates a complete lifestyle zone with two towers, G+27 floor, connecting both the towers on amenities and roof level.",
      "As architects, it is important for us to understand the requirements of the client, potential residents and cater to their needs. The brief included designing spaces which gave the experience of luxury. From the arrival experience to the clubhouse and the rooftop garden, the impression needed to stay consistent with the luxury sized apartments. Each space has been designed meticulously to provide that experience."
    ],

    img4: "",
    otherInfo: [
      {
        title: "Entrance",
        image1: das1,
        description1: [
          "I.	Entrance - The first impression is the last impression, as some say. The entrance architectural structure should establish the influence of the type of project one is entering to and contribute its streetscape accordingly.",
          "This is accomplished by creating a drop-off plaza that flows seamlessly between the two towers mimicking the clubhouse which connects both the towers. The dramatic arrival experience is created with the use of yellow (4000K) lighting which provides a smooth texture to the interior, evoking warmth, and comfort to the eyes. The design inculcates spacious entries which replicate the principle of design used in the apartments. "
        ],
        tableImg: "",
        megazineCover: "",
      },
      {
        image2: das2,
        description2: [
          "II.	Lobby- The luxurious arrival experience continues right to the lobby. The play of light & shadow and repetitive design elements with equally spaced frames. This gives a sense of perpetual never-ending lobby area. This visual play complements the feel of premium.",
        ]
      },
      {
        image2: das3,
        description2: [
          "The slim black glass framing complements the overall contemporary look at the lobby. The linear walls carved with the backlit feature, at the reception, create an astounding visual experience. On the other hand, the marble on the lift facia augments the premiere residential tower finish. To complete the look, a frame with unique art embroiders the area near the lift.",
        ],
      },
      {
        image2: das4,
        description2: [
          "In our project, a prayer room at the entrance lobby is constructed. The room induces a sense of calmness. Yellow marble flooring breaks the continuity of the monochromatic scheme; setting the prayer room apart and giving it a divine look. With such subtle and sober interiors, the image of the entrance place adds a traditional touch with contemporary interiors.",
        ]
      },
      {
        title: "III. Waiting Area",
        description1: [
          "While designing waiting spaces in premium residential towers, sophistication isn’t just another added element. Waiting spaces should create a lasting impression in the mind of potential buyers and daily visitors. From a designer’s point of view, it sets the tone for the impression of the entire project.",
        ],
        image2: das5,
        description2: [
          "The seating and waiting arrangements in Altius are not only designed with absolute exactness but with sophistication and refinement. The leather lounge chairs promptly add class to space, exuding grace and tranquillity. The territory with wooden seats and a table is stunningly ornamented with outlines and a gilt-edged mirror. An artistic partition is built between the seating area and reception, to keep the composition undisturbed."
        ],
        tableImg: '',
        megazineCover: "",
      },
      {
        title: "IV.	The Club House",
        description1: [
          "Clubhouse in a residential project is the focal point for amenity spaces. The design should reflect the economic group resident in the project and must cater to the requirement of all ages. While designing the clubhouse for premium projects, it is important to achieve the look and feel without compromising the shoestring budget which we need to achieve this on."
        ],
        image2: das6,
        description2: [
          "In Altius, both the Clubhouse and Cards Room are crafted depicting a comfortable yet enthusiastic environment for social activities. The glass segment not only keeps both the settings undisturbed but also gives the room an improvident look and an undiluted perspective on the city horizon.",
          "The use of contrasting colours in the seating arrangement was like a cherry on top, ",
        ],
        description3: [
          "making the place lively. The Clubhouse looks opulent with the customized chandelier. On the other hand, the prolonged light set up is the point of convergence of the Cards Room.",
        ]
      },
      {
        image2: das7,
        description2: [
          "Potted plants simply add life to any area. Thus following the trend the tablespaces are made secluded and cosy with the use of potted planters. The light brown wooden flooring intensifies the use of dark chocolate brown plywood creating a classy look. The indirect hidden lights in the show-piece shelves set the perfect ambience for a game of cards. Undoubtedly, crafting every nook and corner of the area is done meticulously and with utmost creativity."
        ]
      },
      {
        title: "V.	Gymnasium",
        description1: [
          "Earlier criteria for designing gym spaces were having adequate spaces equipped with only the machinery. However, to find the motivation to workout becomes manageable, if the gym has"
        ],
        image2: das8,
        description2: [
          "impeccable aesthetics. Altius includes a gym as another top-class amenity which is designed on the 24th floor. The imbued mirror wall from the flooring to the canopy gives a high-ceilinged feel and an incredible top-view while working out. The false ceiling is designed with complete uniformity to make the room capacious.",
          "To complete the interiors, cove lighting encourages the workout spirit and creates a perfect ambience. Warm, cosy, pale, bright, and energetic illumination sets the mood right and motivates the workout spirit."
        ]
      },
      {
        title: "VI.	Changing room-",
        description1: [
          "While designing this area in any place or tower, it should be designed in a way to define its usability along with its functionality. The designing elements in such spaces are highly personal and depend on the taste of the users."
        ],
        image1: das9,
        description3: [
          "In Altius, these spaces are designed keeping in mind the functionality of a changing room without disturbing the affluence of the modernity provided. It gives an impression of personal touches that satisfies the taste of the users. This amenity ensures both privacy and intimacy, building a comfortable environment for all. "
        ]
      },
      {
        title: "VII. Lounge-",
        description1: [
          "Possessing a comfortable, friendly, carefree environment with subtle and interiors is a winning point in any property. And, just adding bright seats and ornamented items isn't sufficient to make a place ravish."
        ],
        image2: das10,
        description2: [
          "The lounge area of this high-rise tower manages to inculcate tranquillity and fulfil its purpose to create a dynamic social mix. The subtle colour scheme and sober brightness add an immediate glow of sophistication. Whereas, the comfortable and deluxe seating arrangements provide an aesthetic appeal. Finally, the area is embellished with some artistic frames that calm the mood, making it an exclusive amenity."
        ]
      },
      {
        title: "VIII.	Community Halls-",
        description1: [
          "Hosting an event with friends and loved ones is always a great idea. With regards to the high-end residential complexes, where the architectural statements are bold and interiors designs move with the trend, it should be a ravishing one."
        ],
        image1: das11,
        description3: [
          "Following the exquisite design principle, the community hall is designed with utmost precision and minimalistic interiors. The walls of the hall are painted in teal, giving a pioneering contact to the region. Instead of keeping the designs of the walls simple and plain, the offset and grooving effect adds materialism to space, establishing a light environment. Additionally, to maintain the uniqueness of designing a space the flooring of the passage breaks the unvarying pattern. The main area and entrance are separated, with different floorings, without the need for sliding doors or partition."
        ]
      },
      {
        title: "IX.	Home theatre-",
        description1: [
          "Home theatre- Having home theatres is one of the advanced and classy solaces one yearns to encounter. Chic aesthetics and soothing colour scheme interiors, complement the royal seating organization of this space. The fifteen seater recliners with golden leather and curtains give a royal look to this home theatre in Altius, creating a perfect example. The implementation of illuminating the space with indirect lighting adds to the luxurious experience of the theatre. The Bollywood black and white poster certainly augments the cinema experience."
        ],
        image1: das12,
        description3: [
          "Nowadays, the high end-residential areas touch the heightened-urban skyline, formulating bold statements. The contemporary architectural models incorporate elements of social mix appeasing the wishes of the clients. These residential towers are known to be timeless structures and are taken to a whole new level of style. Altius is one of our major projects that use refined design as well as structural elements."
        ]
      }
    ],
    relatedPost: [
      "https://www.instagram.com/p/CAj9-o0HAdD/",
      "https://www.instagram.com/p/CAc_dMGnpb7/",
      "https://www.instagram.com/p/CDEK2wRH2o2/",
      "https://www.instagram.com/p/CDL7sU4HuE_/",
      "https://www.instagram.com/p/CEWrgQrH0cW/",
      "https://www.instagram.com/p/CDWIzUHHGwp/"
    ],
    relatedProjects: [
      {
        name: "High-Rise Residential Towers",
        link: "/category/residential-high-rise-tower",
      },
      {
        name: "Residential Housing Complexes",
        link: "/category/residential-housing-complex",
      }
    ],
    relatedBlog: [
      {
        name: "Collective Living In Design",
        link: "collective-living-in-design",
      },
      {
        name: "Vertical Horizon",
        link: "vertical-horizon"
      }
    ],
    meta: {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      mainEntityOfPage: {
        "@type": "WebPage",
        "@id": "https://www.architectm.com/publication/designing-amenities-spaces",
      },
      headline: "Designing Amenities Spaces",
      description:
        "Architecture is about designing spaces and planning resources to fit in the budget while adeptly suiting the needs of the users. Designing amenities and common area (arrival & lobby) spaces to fit the economic category of the buyers along with their social status is a challenge. A good design is a considerate balance and a harmonious aggregate of desirable elements that reflect the internal and structural planning.",
      image:
        "https://res.cloudinary.com/dubm0zyx2/image/upload/v1619477640/Maheshwari/blog/Designing%20Amenities%20Spaces/1_wyw2ed.jpg",
      author: {
        "@type": "Organization",
        name: "Maheshwari & Associates",
      },
      publisher: {
        "@type": "Organization",
        name: "Maheshwari & Associates",
        logo: {
          "@type": "ImageObject",
          url: "https://www.architectm.com/static/media/logo.2dc7ae21.png",
        },
      },
      datePublished: "2020-11-01",
      dateModified: "2020-10-14",
    },
  },
  {
    title: "Re-inventing architecture with steel",
    link: "re-inventing-architecture-with-steel",
    tags: [
      {
        tag: " Architecture,",
        link: "/category/commercial-office",
      },
      {
        tag: " Steel Structure,",
        link: "#",
      },
      {
        tag: " Civil Engineering,",
        link: "#",
      }
    ],
    published: "",
    date: "Feb 2021",
    blogBy: "Sneha Sadhu",
    headerImg: rei1,

    sideDescription: [
      "Thousands of years back, when humans first started noticing the land beneath their feet, they discovered something which changed the world forever. Centuries before the breakthroughs that built skyscrapers, civilizations on Earth twisted and fiddled with techniques to make iron, getting closer to steel. And in 1880s, the mighty metal found its way, for the first time ever, in building the grandest engineering enterprises of that age: bridges. Since then, steel has been one of the most vital metals for construction of architectural structures.",
    ],
    description1: [
      "Thousands of years back, when humans first started noticing the land beneath their feet, they discovered something which changed the world forever. Centuries before the breakthroughs that built skyscrapers, civilizations on Earth twisted and fiddled with techniques to make iron, getting closer to steel. And in 1880s, the mighty metal found its way, for the first time ever, in building the grandest engineering enterprises of that age: bridges. Since then, steel has been one of the most vital metals for construction of architectural structures.",
      "Today, architects are re-inventing the future with the help of steel, establishing architectural solutions for a sustainable opportunity.",
      "One such project, which has re-invented the use of steel is Unimark Asian. The client requirement was to build an office space. What we, Maheshwari & Associates, delivered was a space for the future.",
      "The need was – a functional, multi-storied office building, in Central Kolkata. The solution was simple – building a functional office building, which satisfies FAR utilisation, offers amenities and parking requirements – as adopted by the majority of buildings.",
      "However, our aim was to achieve a goal that re-invents the future for a better experience.",
      "Our first step was to understand the future of workplaces and the criteria we planned to meet. An experience which maximises productivity, is unique and elevates the market demand. We brainstormed to arrive at creating seamless outdoor-indoor integrated spaces, flourishing a unique landmark and generating sellable spaces with a variety of offices.The design was our experimentation in re-imagining the future of office buildings.",
      "Before we went on-ground, we studied the busy streets of central Kolkata, studied - the traffic patterns, site geometry to achieve maximum space efficiency, developed the optimised build mass - abiding the statutory laws.",
      "Our design placed a vertical core on the western side, which helps in reducing cooling load and keeping out of office space volume helped in achieving an uninterrupted space. We added green spaces to the building design, adding vibrancy to the ambience.",
      "The result was an iconic design concept, with major gains.",
      "●	10% extra sellable terraces generation that is FAR free.",
      "●	A spatial experience enhanced by integrating terraces as recharge spaces.",
      "●	Core treated as a separate volume gave a clean and unobstructed volume.",
      "●	Reduced heat gain in office spaces",
      "●	Variety of spaces creating flexibility options.",
      "●	A staggered volume giving an iconic look to the built form, standing out in the surrounding.",
      "M&A developed the structural solution, reducing the volume in steel construction as compared to RCC resulting in reduced material handling and achieving sleek aesthetics. The re-imagination of steel structure also reduced the time by 2.5 times as compared to RCC structure. Thus, less disturbance to the neighbourhood.",
      "RCC construction involves a complicated on-site scaffolding work and tenacious settling processes with proper skilled supervision. Whereas steel structures are custom made at factory and are ready to be installed at site, without any delays and settling time.",
      "The steel construction system helps in gaining around 25% of internal spatial volume as compared to RCC construction system, thus creating the experience of larger space. This requires lower beam depth in order to craft the cantilever, thus creating less obstruction to the floor beneath the cantilever. ",
      "However, it was also important to judiciously use steel to optimise cost and material. M&A developed the core and basement in RCC, to reduce unnecessary use of steel. We also used composite columns with steel framing inside and developed floor plates using steel structures frames, ultimately wrapping steel frames by 100m thick PT slab and glass members on vertical surfaces.",
    ],
    otherInfo: [
      {
        title: "The outcome",
        image1: rei1,
        description1: [
          "An iconic structure, a spatial experience with an elevated tall lounge, recreation spaces and a vibrant environment to create a motivating workspace with interactive opportunities, transforming offices to experiences, the future is looking for.",
          "We learnt that the design concept was made possible due to the use of steel over RCC, especially because we wanted to – achieve interior spatial volume, save construction time, reduce material handling and movement, optimise cost and raw resources like water."
        ],
      },
      {
        title: "Learning from the project",
        image1: rei2,
        description1: [
          "Steel is the answer to solutions which require future oriented designs, aiming at sustainable structures, optimised resources which generate profitability, as we achieved with Unimark Asian. "
        ],
      },
    ],
    relatedPost: [
      "https://www.instagram.com/p/B0yEwhfDi4i/?igshid=dzjjknhmiq8a",
      "https://www.instagram.com/p/B0qQRqPHTIY/?igshid=17akuswxrze53",
    ],
    relatedProjects: [
      {
        name: "23 Circus Avenue",
        link: "/project/commercial-office/project/23-circus-avenue",
      },
      {
        name: "Unimark Asian",
        link: "/project/commercial-office/project/unimark-asian",
      },
    ],
    relatedBlog: [
      {
        name: "Influencing Design through Facade",
        link: "influencing-design",
      },
    ],
    meta: {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      mainEntityOfPage: {
        "@type": "WebPage",
        "@id":
          "https://www.architectm.com/publication/re-inventing-architecture-with-steel",
      },
      headline: "Re-inventing architecture with steel",
      description:
        "Thousands of years back, when humans first started noticing the land beneath their feet, they discovered something which changed the world forever. Centuries before the breakthroughs that built skyscrapers, civilizations on Earth twisted and fiddled with techniques to make iron, getting closer to steel. And in 1880s, the mighty metal found its way, for the first time ever, in building the grandest engineering enterprises of that age: bridges. Since then, steel has been one of the most vital metals for construction of architectural structures.",
      image:
        "https://res.cloudinary.com/dubm0zyx2/image/upload/v1613028328/Maheshwari/blog/Re-inventing%20architecture%20with%20steel/Unimark-Asian_1_wsregf.jpg",
      author: {
        "@type": "Organization",
        name: "Maheshwari & Associates",
      },
      publisher: {
        "@type": "Organization",
        name: "Maheshwari & Associates",
        logo: {
          "@type": "ImageObject",
          url: "https://www.architectm.com/static/media/logo.2dc7ae21.png",
        },
      },
      datePublished: "2020-03-01",
      dateModified: "2020-10-14",
    },
  },
  {
    title: "Unimark Asian: Creating a landmark in Kolkata",
    link: "creating-a-landmark-in-kolkata",
    tags: [
      {
        tag: " Architecture,",
        link: "/category/commercial-office",
      },
      {
        tag: " Steel Structure,",
        link: "#",
      },
      {
        tag: " Civil Engineering,",
        link: "#",
      }
    ],
    published: "",
    date: "Jan 2021",
    blogBy: "Sneha Sadhu",
    headerImg: landmark1,

    sideDescription: [
      "A landmark is a symbol which becomes an identity of the space around. A sign which says you have arrived. Here’s the story of a landmark that stands out in the hustle and bustle of central Kolkata.",
      "The Unimark Asian tower, standing tall in the central Kolkata, is a symbol of re-imagining and delivering a unique experience.",
    ],
    description1: [
      "A landmark is a symbol which becomes an identity of the space around. A sign which says you have arrived. Here’s the story of a landmark that stands out in the hustle and bustle of central Kolkata.",
      "The Unimark Asian tower, standing tall in the central Kolkata, is a symbol of re-imagining and delivering a unique experience.",
      "What is special about this building? It re-invents the future.",
      "The brief to us, Maheshwari & Associates, was to create an office building. The solution was simple, however we experimented to create a structure which defines the future of workspaces. The distinctive profile of Unimark Asian’s new 16-storey headquarter office, in downtown Kolkata, is derived from incorporating GFA-free, triple height external terraces in a dynamic way by stacking and sliding five glass frames. These terraces are envisioned to be lushly planted, providing a valuable, rejuvenating space at the workspace. The core of the structure is placed asymmetrically with only four further columns required to create a column free space in this steel framed building.",
      "The ideal orientation with short facades to east and west allows maximum use of low e-glazing to maximise daylight into office and minimise artificial lighting, a prime energy user. With further green initiatives, the building ensures to achieve Gold LEED rating."
    ],
    otherInfo: [
      {
        title: "Our design philosophy",
        image1: landmark1,
        description1: [
          "Workspaces are usually associated with a stressful environment, concrete laden and unyielding. With this in mind, we approached the project to create an office space which redefines the work environment. ",
          "TOur research helped us to understand the current workplace scenarios such as - typical building design, monotonous and packed environment, lack of recreational space and isolation from nature. With this understanding we developed strategies to redefine the office environment. ",
          "Studies suggest that humans are naturally at peace in nature. Being around nature, with fresh air and natural light increases productivity and enhances memory functions. Thus, we incorporated nature elements to create a refreshing ambience in the recreational as well as the workspaces. We integrated biophilic spaces within the workspace."
        ],
      },
      {
        title: "Design development",
        image1: landmark4,
        description1: [
          "The aim while developing the design, was to optimise space, time and resources to create a structure which is unique and futuristic, being sustainable and close to nature.",
          "We started with studying the site and space requirements, to create a design which is aesthetically pleasing and functional, as well as welcomes nature."
        ],
      },
      {
        title: "Structural solution",
        image1: landmark2,
        description1: [
          "As we defined the design and concept of the building, the next step was to reach a solution which provides structural flexibility and optimises man, money and resources. ",
          "The answer – steel.",
          "The steel construction system helped in gaining around 25% of internal spatial volume as compared to RCC construction system, thus creating the experience of larger space. This required lower beam depth to craft the cantilever, thus creating less obstruction to the floor beneath the cantilever. ",
          "With smart use of steel, we were able to achieve a unique design optimally. The result – a landmark design, which redefines work, delivers a vibrant experience, and stands tall, looking at the future."
        ],
      },
    ],
    img4: landmark3,
    relatedPost: [
      "https://www.instagram.com/p/B0yEwhfDi4i/?igshid=dzjjknhmiq8a",
      "https://www.instagram.com/p/B0qQRqPHTIY/?igshid=17akuswxrze53",
    ],
    relatedProjects: [
      {
        name: "23 Circus Avenue",
        link: "/project/commercial-office/project/23-circus-avenue",
      },
      {
        name: "Unimark Asian",
        link: "/project/commercial-office/project/unimark-asian",
      },
    ],
    relatedBlog: [
      {
        name: "Influencing Design through Facade",
        link: "influencing-design",
      },
    ],
    meta: {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      mainEntityOfPage: {
        "@type": "WebPage",
        "@id":
          "https://www.architectm.com/publication/creating-a-landmark-in-kolkata",
      },
      headline: "Unimark Asian: Creating a landmark in Kolkata",
      description:
        "A landmark is a symbol which becomes an identity of the space around. A sign which says you have arrived. Here’s the story of a landmark that stands out in the hustle and bustle of central Kolkata.",
      image:
        "https://res.cloudinary.com/dubm0zyx2/image/upload/v1613024458/Maheshwari/blog/Creating%20a%20landmark%20in%20kolkata/Unimark-Asian_5_xtgtph.jpg",
      author: {
        "@type": "Organization",
        name: "Maheshwari & Associates",
      },
      publisher: {
        "@type": "Organization",
        name: "Maheshwari & Associates",
        logo: {
          "@type": "ImageObject",
          url: "https://www.architectm.com/static/media/logo.2dc7ae21.png",
        },
      },
      datePublished: "2020-03-01",
      dateModified: "2020-10-14",
    },
  },
  {
    title: "How Will COVID-19 Impact Future Workplaces ?",
    link: "covid-19-workplaces-future-impact",
    tags: [
      {
        tag: "Architecture,",
        link: "#",
      },
      {
        tag: " Workplaces,",
        link: "#",
      },
      {
        tag: " Covid-19,",
        link: "#",
      },
      {
        tag: " Technology",
        link: "#",
      },
    ],
    published: "",
    blogBy: "Pramita Basu",
    date: "August 2020",
    headerImg: futureimpact,

    sideDescription: [
      "The COVID-19 pandemic is mightily the big bang of this century that reshapes our norms and will evolve the working assets of the camaraderie. COVID-19 is the societal wake-up call for the office culture.",
    ],
    description1: [
      "The COVID-19 pandemic is mightily the big bang of this century that reshapes our norms and will evolve the working assets of the camaraderie. COVID-19 is the societal wake-up call for the office culture. The pandemic forced us to rewire work, correct hygienic sins, and double-down on our understanding of the cause and spread of infectious disease—simultaneously. This breakout has ripped the chain of several workforces compelling them to be stagnant. The impact of COVID-19 has disrupted daily life and caused a shift in technology trends within the workplace. Over recent weeks, businesses have found themselves hastily searching for and implementing temporary solutions to help them deliver an uninterrupted service. These solutions are highlighting the significant role of technology in helping companies adapt to the situation and maintain daily operations.",
      "“Offices after pandemic will balance physical and virtual work” – says Perkins and Will, the Interior design director in an interview by De Zeen.",
      "This enforces a hybrid model for work that encourages big name companies to build a fluid work environment to accommodate innovation to reside amongst us. It must never be forgotten that every crisis in the world has given birth to some brilliant inventions that the denizens have benefited with. Hence, the tech-based functions transitioned to online work-from-home processes after the social distancing and lockdown norms came into action, altering the usage of office spaces. And this transition could be only supported with the help of advanced technological infrastructures. The collective work-from-home experience has now given us the opportunity  to throw an insight into what we need to work most effectively.",
      "Modular furnishings with proper maintenance of social distance have led to simple yet flexible open plan systems to cater the office's suitable needs. This highly encourages architects and interior designers to come forth with new innovations that heartily fills the gap of the post pandemic communication. With this, remote working would no longer be unusual. ",
    ],

    img4: "",
    otherInfo: [
      {
        title: "Reimagining hygiene in office spaces",
        image1: futureimpact,
        description1: [
          "Perhaps the offices would relinquish its functions as a provider of private workstations and instead would lean into its role of a 'meeting space' – that is, the office is designed to support collaboration and connection. Post -pandemic offices should be spaces that give employees the opportunity to meet up in conjunction with home offices. This enables the office to rethink and redesign the office intramural by maintaining social distance. Not only an office will bring their profit through work but would also ensure a healthy environment for its employees.",
          "Thus, every office turns out into a health-care dome that protects its employees and employers. Offices have now catered to the best use of its employees' physical and spiritual well being to fetch the most profitable outcomes from their hired hands. Smart contactless check-ins with proper sanitising machines is a must for the cause. Office equipment being sanitised daily for a fresh start each day checks the possibility of any contamination. ",
        ],
      },
      {
        title: "Flexible models for office space design",
        image1: "",
        description1: [
          "Architecture can offer true mobility via demountable walls, furniture on wheels, mobile writable surfaces, and a well-integrated and distributed electrical and audio-visual infrastructure that doesn't bring people physically too close. Use of distance markers in interior design would become a norm. Touch less technology and cubicle systems with sneeze screens are ideal for the situation. Areas are designed with details like privacy curtains that can open and close off certain zones . Screens for Zoom calls so that they can be easily adapted for different uses. Creating a staggered attendance plan — effectively bringing shift work to the corporate workplace — coupled with zigzagged seating arrangements in open work areas is a logical solution that has started popping up in a lot of return to office strategies. Alternate work days for different teams, skip-stop seating to allow more social distancing, and amended protocols can be used, including assigning enclosed spaces to those who may normally sit in the open plan. Hence, a “ flex office ” can be visualised in the near future!",
        ],
      },
      {
        title: "Hybrid model of work from home and office culture",
        image1: "",
        description1: [
          "Remarkably design, whether it’s through architecture or graphics and wayfinding, is about experience and behaviour — sometimes, like following a pandemic, it’s about a changing behaviour. Specific proposals given by architects and their firms serve beyond health and safety measures, and focus on encouraging collaboration and creativity during and after the coronavirus pandemic. With the reduced desire to use public transport amongst people and be among large groups, the future is more distributed than consolidated. Its focus is on smaller satellite, community-based offices.",
          "As working remotely becomes a comfort, more of us will expect to do so more often and it will be one of the most significant impacts of Covid-19 on how we design and use our workplaces in the future. Working from home has, for many, become mandatory , perhaps the need for an office as a physical workspace might die within a few years. We are soon moving into a digital world, in which our avatars work and communicate without having to leave the bubble of our homes. But at the same time, few realisations have come across industries that there are limits to remote connection—collaboratively sketching a drawing  simply cannot be replicated via a video call. This will result in workers desiring a return to the physical office, at least for part of the week. The flexibility offered by mass-adoption of new technologies, and perhaps more flexible policies around remote working, will change our experience and expectations for time in the office.",
          "But as technological infrastructure proceeds, working spheres  are going to become borderless connecting us with a huge web of digitalisation. Therefore, an efficient and a smart future is envisioned where the notion of distance within a city, state or country doesn't seem to be a barrier in-front of knowledge, creativity and good performance .",
        ],
      },
    ],
    relatedPost: [
      "https://www.instagram.com/p/B1qtA3BlKfy/?igshid=17s7rfwkghq28",
      "https://www.instagram.com/p/B1tQrZKFv3-/?igshid=15ys1al7pp5q0",
    ],
    relatedProjects: [
      {
        name: "Gaggar Office",
        link: "/project/interior-design/project/gaggar-office",
      },
    ],
    relatedBlog: [
      {
        name: "Acclimatizing to the whirlwind of Covid-19",
        link: "whirlwind-of-covid-19",
      },
    ],
    meta: {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      mainEntityOfPage: {
        "@type": "WebPage",
        "@id":
          "https://www.architectm.com/publication/covid-19-workplaces-future-impact",
      },
      headline: "How Will COVID-19 Impact Future Workplaces ?",
      description:
        "The COVID-19 pandemic is mightily the big bang of this century that reshapes our norms and will evolve the working assets of the camaraderie. COVID-19 is the societal wake-up call for the office culture. The pandemic forced us to rewire work, correct hygienic sins, and double-down on our understanding of the cause and spread of infectious disease—simultaneously. This breakout has ripped the chain of several workforces compelling them to be stagnant. The impact of COVID-19 has disrupted daily life and caused a shift in technology trends within the workplace. Over recent weeks, businesses have found themselves hastily searching for and implementing temporary solutions to help them deliver an uninterrupted service. These solutions are highlighting the significant role of technology in helping companies adapt to the situation and maintain daily operations.",
      image:
        "https://res.cloudinary.com/dubm0zyx2/image/upload/v1599576638/Maheshwari/blog/FUTURE%20WORKPLACES/3980501_nofwdw.jpg",
      author: {
        "@type": "Person",
        name: "Pramita Basu",
      },
      publisher: {
        "@type": "Organization",
        name: "Maheshwari & Associates",
        logo: {
          "@type": "ImageObject",
          url: "https://www.architectm.com/static/media/logo.2dc7ae21.png",
        },
      },
      datePublished: "2020-08-01",
      dateModified: "2020-10-14",
    },
  },
  {
    title: "Acclimatizing to the whirlwind of Covid-19",
    link: "whirlwind-of-covid-19",
    tags: [
      {
        tag: "Architecture,",
        link: "#",
      },
      {
        tag: " Workplaces,",
        link: "#",
      },
      {
        tag: " Covid-19,",
        link: "#",
      },
      {
        tag: " Technology",
        link: "#",
      },
    ],
    published: "",
    blogBy: "Sreoshmita Chandra",
    date: "August 2020",
    headerImg: whirlwind,

    sideDescription: [
      "With the unwelcome entry of Covid-19, everyone’s life around the world came to a standstill. A wave of uncertainty and hopelessness spread in the air along with the virus, leading to a sudden halt in the day-to-day lifestyle of people. Nevertheless, Covid-19, despite its frightening start could only manage to pause our lives, not stop it.",
    ],
    description1: [
      "With the unwelcome entry of Covid-19, everyone’s life around the world came to a standstill. A wave of uncertainty and hopelessness spread in the air along with the virus, leading to a sudden halt in the day-to-day lifestyle of people. Nevertheless, Covid-19, despite its frightening start could only manage to pause our lives, not stop it. As they say, “when the going gets tough, the tough get going”; we learnt to adapt to the new normal- the why and how are discussed below:",
    ],

    img4: "",
    otherInfo: [
      {
        title: "Adapting to the change",
        image1: whirlwind,
        description1: [
          "We, at Maheshwari and Associates always aim towards the future. Therefore, we took this situation as an opportunity to make several significant changes in upgrading our office technologically. Among several measures undertaken, a noteworthy mention is our shift to Cloud Computing. It extended improved continuity to the business, providing employees with greater flexibility in working and enhanced collaboration efficiency, making the shift a smart move for the years to come. A world with controlled physical interactions summons increase in virtual connections. In the present scenario of scarce corporeal meetings, we chose the path of readily available Zoom Meetings. Conferences became swift and handy- only a click away, discussions are now more frequent due to increased accessibility, generating rapid and better outputs.",
        ],
      },
      {
        title: "Getting technologically adept",
        image1: "",
        description1: [
          "Staying connected across two sides of the country during a pandemic is a brilliant feat that we have achieved. Not only software, we have made different hardware entities available to our staff all across the country ensuring uninterrupted connectivity between our offices in Mumbai and Kolkata. We did not stop at just providing up-to-date software and hardware to our employees. Rather, we upgraded all existing devices to escalate their compatibility with a temporary virtual office setup.",
          "With the doors of our homes locked, we have opened the windows of our minds. We can have the entire globe and all it has to offer on our very own computer screens today, thanks to great networking infrastructure. So, we tried to make the best out of such a convenience by participating in numerous webinars and variant online workshops on a regular basis to expand the horizons of our knowledge.",
        ],
      },
      {
        title: "Reshaping our work spaces",
        image1: "",
        description1: [
          "An important element amongst our adaptation to the current situation is the modification in our office layout. Our office design is now in an open plan fashion that fosters greater team spirit and being a compact arrangement, minimizes our exposure to the deadly virus.",
          "A rather ‘fresh’ initiative incorporated in current times is recruiting more young minds into our business that bring in exciting energy and a jovial creative spirit. It facilitates speedy workflow and dynamic innovation, both essential tools for sustainability.",
          "Addition of two new members in our family- an automatic hand sanitizer dispenser and a mobile disinfection box- have reduced our susceptibility to contraction of the virus, offering us a reassuring environment to work in amidst such times of distress.",
        ],
      },
      {
        title: "Staying ahead of the curve",
        image1: "",
        description1: [
          "The rise in universal connectivity in this period has enabled us to reach the depths of ingenious wonders around the world. Due to boosted exposure of variant talents across the globe, it is now easier to access them sitting miles away in your own office. Having an eye for such geniuses has always been our forte so with uncountable chances at our disposal, we have extended our warm welcome to such brains.",
          "Reliance on virtual integration has made people more technologically-literate. Attending online seminars and receiving all work necessities at one’s doorstep has made employees more efficient and committed to their work, resulting in an overall development and upliftment of craft in the business.",
          "This is precisely how we have adapted to a world with Covid-19. We firmly believe that every small step taken by us will pave the way for a better future. As H. G. Wells said, “The crisis of today is the joke of tomorrow”, we hope to see this phase as an opportunity to grow rather than succumb to its hardships and aspire to be able to laugh at all the inconveniences it caused looking back in the years to come.",
        ],
      },
    ],
    relatedPost: [
      "https://www.instagram.com/p/B1ylDhIFrK3/?igshid=lx2eidry5jcn",
    ],
    relatedProjects: [
      {
        name: "Gaggar Office",
        link: "/project/interior-design/project/gaggar-office",
      },
    ],
    relatedBlog: [
      {
        name: "How Will COVID-19 Impact Future Workplaces ?",
        link: "covid-19-workplaces-future-impact",
      },
    ],
    meta: {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      mainEntityOfPage: {
        "@type": "WebPage",
        "@id": "https://www.architectm.com/publication/whirlwind-of-covid-19",
      },
      headline: "Acclimatizing to the whirlwind of Covid-19",
      description:
        "With the unwelcome entry of Covid-19, everyone’s life around the world came to a standstill. A wave of uncertainty and hopelessness spread in the air along with the virus, leading to a sudden halt in the day-to-day lifestyle of people. Nevertheless, Covid-19, despite its frightening start could only manage to pause our lives, not stop it. As they say, “when the going gets tough, the tough get going”; we learnt to adapt to the new normal",
      image:
        "https://res.cloudinary.com/dubm0zyx2/image/upload/v1599076882/Maheshwari/blog/FUTURE%20WORKPLACES/3622110_payccw.jpg",
      author: {
        "@type": "Person",
        name: "Sreoshmita Chandra",
      },
      publisher: {
        "@type": "Organization",
        name: "Maheshwari & Associates",
        logo: {
          "@type": "ImageObject",
          url: "https://www.architectm.com/static/media/logo.2dc7ae21.png",
        },
      },
      datePublished: "2020-08-01",
      dateModified: "2020-10-14",
    },
  },
  {
    title:
      "Simplicity & Complexity In Architecture: Analysis between House II & Villa Rotonda",
    link: "simplicity-and-complexity-in-architecture",
    tags: [
      {
        tag: "Architecture Research,",
        link: "#",
      },
      {
        tag: " Housing,",
        link: "/category/residential-housing-complex",
      },
      {
        tag: " Eisenman,",
        link: "#",
      },
      {
        tag: " Palladio",
        link: "#",
      },
    ],
    published: "",
    blogBy: "Raj Periwal",
    date: "November 2016",
    headerImg: Simplicity2,
    sideDescription: [
      "This essay analyses the similarities and differences in design of two famous",
    ],
    description1: [
      "This essay analyses the similarities and differences in design of two famous architectural works, House II, by Peter Eisenman, and Villa Rotonda, by Palladio.",
      "House II (1969-70, Vermont) was designed by Peter Eisenman who is an influential post-modernist architect. It is organized around a square plan and a structured grid of columns. Eisenman added rectangular elements, lines, planes and volumes to create a complex space which followed the formal operation of de-constructivism. William J. R. Curtis writes that Eisenman argued that such buildings as ‘House II’ (1969) were explorations of basic formal syntax and the logical structure of space.1 Villa Rotonda (1567- 70, Italy) was designed by Andrea Palladio and is one of the most recognized buildings of the Renaissance. This villa models the ancient roman classical buildings but its innovative design still has an impact on future generations of architects all across the globe. The design is completely symmetrical which has a square plan with four facades each projecting a portico. Its simplicity led to Palladio calling it a pure work of art. An in-depth analysis of the design of the two buildings shows how these designs are surprisingly similar but contrasting as well to a great extent.",
      "Both the buildings used nine square grid plans as a generative device in the process of design. House II follows a systematic development of how information can be interpreted which is by the interaction of formal relationships. It is defined originally as a square volume divided into nine smaller squares. These squares are marked by a matrix of sixteen square columns. The similar geometry of the square seem to apply to the plan of Villa Rotunda as well. Several imaginary squares can be identified which define the spaces in the villa. The grid pattern which can be seen in the plan of house II can be found in the façade of villa rotunda combining squares and rectangles of the doors and windows. The columns placed in house II follow an even spacing in the x and y axis. Thus the notation AAA could be used but Eisenman suggests the use of notion ABC due to the variations of the wall positions within the space of the design.Similarly, Villa Rotonda’s interior organization of volumes follow bilateral symmetry and follows the notion of ABCBA.The process of design in house II was a diagonal displacement of the pillar’s grid.Through a series of formal abstraction, the project became purely abstract.",
    ],
    img2: Simplicity1,
    img3: Simplicity2,
    description2: [
      "Though Palladio was considered as a mannerist, displacement was seen in the plan and in the perspective of the elevation too.The disproportion was seen as spaces were compressed due to the rigid set of principles followed by Palladio in the nine square grid figure.Eisenman in his analysis of Palladio’ carefully constructs a series of analytic diagrams to trace out processes of spatial dislocation in villa Rotonda and giving the lie to the inherited—yet misguided—image of him as a truly classical figure.2 The site was important to the both the design of the buildings.House II was to be designed as a weekend home.So the site plays an important role of serving the purpose as it is adjacent to a barn with 110 acres of rolling meadows and woodlands.The picturesque backdrop from Villa rotunda is not coincidental.The lovely hills and the changing view of the breathtaking scenery from the portico was well planned with the design.",
      "Palladio was considered a mannerist as he showed perfect symmetry, harmony and balance in his project. He also used rhythmical mathematical ratios to define his spaces and it’s a visual delight to the eyes due to its simplicity. Eisenman on the other hands used multiple set of operations to create a seemingly complex set of spaces which are unconventional. His design totally disregards symmetry and proportion. Palladio’s villa was a timeless structure as Palladio used classical elements to define a universal architecture language. Visually soothing and technically sound Villa Rotonda was used as a residence as well as a space for agricultural production facility.",
    ],
    img4: "",
    description3: [
      "Eisenman’s design was technically and functionally not practical. The owners of the house complained that it was hard to live in and poorly detailed. There were are series of odd elements in the design like a column in the middle of the bed room, stairs which lead nowhere, holes in floors etc. Villa Rotunda was designed in keeping the public and private spaces in mind which would be used for different activities whereas House II did not have a prior motive of creating well planned spaces. It followed a set of operations due to which spaces were created. Palladio was a humanist architect who establishes a genuine reciprocity between landscape and architecture. Eisenman on the other hand is conceptual in his way of approach and gives his attention to the form of the design focusing on plan elevation and section.",
      "Eisenman designs are concerned with construction of art whereas Palladio’s design is related to art is construction. It is a clear distinction between method and madness. Both the designs started from a similar nine square grid plan and had complexity involved in designing of the spaces. Palladio added simplicity to the complexity and Eisenman added complexity to complexity. It cannot be said that Eisenman was not thoughtful about his design as he had multiple iterations for this designs. The major difference lies in the goal what they wanted to achieve. Eisenman’s goal could be referred to as dumb practice as he was concerned only about the visual complexity and not about the practicality. Palladio succeed in pragmatic realism as he managed to create both practical and visually appealing design. It is an irony as a Renaissance building succeed in practicing the right mix of architecture design over the building designed in postmodern era. A right balance of modernism and mannerism is the key to successful designs in the 21st century.",
    ],
    // otherInfo: [
    //   {
    //     title: "",
    //     image1: "",
    //     description1: [
    //       "Eisenman’s design was technically and functionally not practical. The owners of the house complained that it was hard to live in and poorly detailed. There were are series of odd elements in the design like a column in the middle of the bed room, stairs which lead nowhere, holes in floors etc. Villa Rotunda was designed in keeping the public and private spaces in mind which would be used for different activities whereas House II did not have a prior motive of creating well planned spaces. It followed a set of operations due to which spaces were created. Palladio was a humanist architect who establishes a genuine reciprocity between landscape and architecture. Eisenman on the other hand is conceptual in his way of approach and gives his attention to the form of the design focusing on plan elevation and section.",
    //       "Eisenman designs are concerned with construction of art whereas Palladio’s design is related to art is construction. It is a clear distinction between method and madness. Both the designs started from a similar nine square grid plan and had complexity involved in designing of the spaces. Palladio added simplicity to the complexity and Eisenman added complexity to complexity. It cannot be said that Eisenman was not thoughtful about his design as he had multiple iterations for this designs. The major difference lies in the goal what they wanted to achieve. Eisenman’s goal could be referred to as dumb practice as he was concerned only about the visual complexity and not about the practicality. Palladio succeed in pragmatic realism as he managed to create both practical and visually appealing design. It is an irony as a Renaissance building succeed in practicing the right mix of architecture design over the building designed in postmodern era. A right balance of modernism and mannerism is the key to successful designs in the 21st century.",
    //     ],
    //   },
    // ],

    relatedProjects: [
      {
        name: "Unimark Asian",
        link: "/project/commercial-office/project/unimark-asian",
      },
      {
        name: "Altius",
        link: "/project/residential-high-rise-tower/project/altius",
      },
      {
        name: "Tata Eden Court",
        link: "/project/residential-housing-complex/project/tata-eden-court",
      },
      // {
      //   name: "Arch Waterfront",
      //   link: "/project/residential-housing-complex/project/ananda-housing"
      // }
    ],
    relatedBlog: [
      {
        name: "Collective Living In Design",
        link: "collective-living-in-design",
      },
    ],
    meta: {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      mainEntityOfPage: {
        "@type": "WebPage",
        "@id":
          "https://www.architectm.com/publication/simplicity-and-complexity-in-architecture",
      },
      headline:
        "Simplicity & Complexity In Architecture: Analysis between House II & Villa Rotonda",
      description:
        "This essay analyses the similarities and differences in design of two famous architectural works, House II, by Peter Eisenman, and Villa Rotonda, by Palladio.",
      image:
        "https://res.cloudinary.com/dubm0zyx2/image/upload/v1597402368/Maheshwari/blog/Simplicity%20and%20Complexity%20In%20Architecture/2_ualkym.jpg",
      author: {
        "@type": "Organization",
        name: "Maheshwari & Associates",
      },
      publisher: {
        "@type": "Organization",
        name: "Maheshwari & Associates",
        logo: {
          "@type": "ImageObject",
          url: "https://www.architectm.com/static/media/logo.2dc7ae21.png",
        },
      },
      datePublished: "2016-11-01",
      dateModified: "2020-10-14",
    },
  },
  {
    title: "Collective Living in Design",
    link: "collective-living-in-design",
    tags: [
      {
        tag: "Residential Project,",
        link: "/category/residential-bunglow",
      },
      {
        tag: " Housing Complex",
        link: "/category/residential-housing-complex",
      },
    ],
    published: "",
    date: "March 2020",
    blogBy: "Anjana Sasikumar",
    headerImg: cl2,

    sideDescription: [
      "Co-existence is a simple principle that we unknowingly comply with every day, from adjusting to the day’s weather to survival amongst 7.8 billion people and twice that many situations.",
      "In India, concept of collective living has been in our fabric since ages with joint family systems. Homes were designed with courtyards in the center and less privacy among family members. With the coming of urbanization, nuclear families became the norm due to urban city lifestyle and ease in far transportation.",
    ],
    description1: [
      "Co-existence is a simple principle that we unknowingly comply with every day, from adjusting to the day’s weather to survival amongst 7.8 billion people and twice that many situations.",
      "In India, concept of collective living has been in our fabric since ages with joint family systems. Homes were designed with courtyards in the center and less privacy among family members. With the coming of urbanization, nuclear families became the norm due to urban city lifestyle and ease in far transportation.",
      "In terms of planning, in today’s scenario with the growing demand for habitable spaces and lack of land on the contrary, the once Utopian concept has become the key to strike balance between the two.",
      "The yearning for community spaces even in our individualistic lives is resilient. With diminishing safe habitable gathering spaces in the city decreasing, the onus is on architects to incorporate it in the projects. In our residential designs, the concept of collective living is formulated by demarcating public spaces and private spaces.",
      "In our premium residential project at Siliguri, Darpan 88 is designed keeping collective living at its core. The 2 towers are placed on a podium to create a space at the foot of these towers for community to spend time without being reminded of being in a concrete maze by constant disturbance of vehicles. The podium has private gardens for apartments on that level and public amenities spaces for the entire community. This demarcation of public & private, pedestrian & vehicles and open lawns & closed amenities is pragmatically utilized to go from concept of collective living to applying it. ",
    ],
    img2: cl1,
    img3: cl3,
    description2: [
      "The terraces are given amenities of varying typologies, such as, the 15th floor terrace at Tower Pegasus comprises of spa beds and yoga area which promotes spiritual living; whereas Tower Cygnus consists of party area, catering tables, open lawn which is for gatherings and celebratory purposes.",
      "Similarly, while planning the residential towers, Ananda at Dumdum, reflects back to the native courtyard houses adapted on a larger scale. The project consists of 5 towers which are placed on a podium in a U alignment with a void that forms the centrum. The total volume is divided into individual entities to improvise circulation and impose visual connectivity. The zoning is kept such that vehicular traffic is only on periphery of the project site while the pedestrian movement is in the center of the project. The project is designed so to inculcate the idea of collective living enabling citizens of the residential complex to gather at footprint of the building without any vehicular interference. The central podium consists of amenities such as , swimming pool, seating spaces, game rooms, open lawns; assigned to encourage informal gatherings where residents of all age groups can indulge, to formulate active and close knit havens. The floors above comprises of residences that look into the central space keeping them involved with happenings in the public spaces.",
    ],
    img4: cl4,
    description3: [
      "The success of such a concept, which has the potential to improvise social behaviour of an area, depends upon the strategy adopted to navigate and allocate functions to spaces that can create significant changes in the inner circulation and ambiance. These spaces eventuate into public living rooms, metaphorically tying all units and yet establish a collective privacy.",
      "Collective living is a concept that requires immense discussions, negotiations and strategies for both designing and working. With the constant evolution of living standards, it is to become the imminent future of architecture.",
    ],
    relatedPost: [
      "https://www.instagram.com/p/B8L66UNn_kS/?igshid=12ardau9nsd9w",
      "https://www.instagram.com/p/B8S73aMHNSO/?igshid=kzs9s0yjx4hd",
      "https://www.instagram.com/p/B8YuzMzHdVk/?igshid=7v2whyc5lwj7",
      "https://www.instagram.com/p/B8dyQN9HZWQ/?igshid=1u2j7tup3jwdw",
    ],
    relatedProjects: [
      {
        name: "Ananda",
        link: "/project/residential-housing-complex/project/ananda-housing",
      },
    ],
    relatedBlog: [
      {
        name: "Vertical Horizon",
        link: "vertical-horizon",
      },
    ],
    meta: {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      mainEntityOfPage: {
        "@type": "WebPage",
        "@id":
          "https://www.architectm.com/publication/collective-living-in-design",
      },
      headline: "Collective Living in Design",
      description:
        "Co-existence is a simple principle that we unknowingly comply with every day, from adjusting to the day’s weather to survival amongst 7.8 billion people and twice that many situations.",
      image:
        "https://res.cloudinary.com/dubm0zyx2/image/upload/v1584689370/Maheshwari/blog/Collective%20Living/Image_4_dqooxd.jpg",
      author: {
        "@type": "Organization",
        name: "Maheshwari & Associates",
      },
      publisher: {
        "@type": "Organization",
        name: "Maheshwari & Associates",
        logo: {
          "@type": "ImageObject",
          url: "https://www.architectm.com/static/media/logo.2dc7ae21.png",
        },
      },
      datePublished: "2020-03-01",
      dateModified: "2020-10-14",
    },
  },
  {
    title: "Vertical Horizon",
    link: "vertical-horizon",
    tags: "High-rise tower, Housing complex, Residential Project",
    tags: [
      {
        tag: "High-rise tower,",
        link: "/category/residential-high-rise-tower",
      },
      {
        tag: " Housing complex,",
        link: "/category/residential-housing-complex",
      },
      {
        tag: " Residential Project",
        link: "/category/residential-bunglow",
      },
    ],
    published: "Neptune Glitz on November 2015",
    blogBy: "",
    date: "October 2015",
    headerImg: vh5,

    sideDescription: [
      "We dwell in a scenario where urbanisation is the current trend, leading to growing demands for facilities hence better living standards that start from the very places we reside. The image of an urban space has become a skyline consisting of high-rise towers with an appealing facade accented by posh utilities such as swimming pools, gyms, arcades  to name a few. This version of spaces which once used to be associated to places such as Manhattan, Seattle, and Dubai can now be seen adapted into our context to perfection in the eastern side of India.",
    ],
    description1: [
      "We dwell in a scenario where urbanisation is the current trend, leading to growing demands for facilities hence better living standards that start from the very places we reside. The image of an urban space has become a skyline consisting of high-rise towers with an appealing facade accented by posh utilities such as swimming pools, gyms, arcades  to name a few. This version of spaces which once used to be associated to places such as Manhattan, Seattle, and Dubai can now be seen adapted into our context to perfection in the eastern side of India.",
      "Such developments are quite essential as they have great potential of relieving the urban congestion which Indian cities are experiencing due to migration from nearby towns and villages, ever growing housing demand and soaring rise of real estate. With the collective vision of some truly ambitious builders, the increasing middle-class disposition to buy such houses and the scarcity of land; the area has seen an uprising of such projects in the recent past.",
      "High rise buildings are a result of strategic vertical zoning and a coping mechanism for metropolis to suffice the dense population and their demands.",
      "Developers see such edifices as a good way to attract potential buyers – high-rise buildings are a good gambit to differentiate their offerings from the rest of the pack. In recent years, Kolkata has received some of the interesting proposals with unique elevation features. ",
    ],

    img4: "",
    otherInfo: [
      {
        title: "UNIMARK ASIAN",
        image1: vh1,
        description1: [
          "Unimark Asian which stands 75m tall with 18 stories is an upcoming project near Shakespeare Sarani that takes advantage of the gross floor area free terraces to create a striking profile high above the fast changing city. Conceptualised in 2012, having about a lakh sq.ft, the building has been designed to appear as an intricate stack of six 'boxes' in staggered arrangement  with the terraces incorporated in the overhangs .To extend the effect the boxes vary in height from two stories high to 6 stories.",
        ],
        image2: vh2,
        description2: [
          "Unimark Asian has received the award for the best upcoming commercial project of 2014 by CREDAI. Architect Kamal Periwal has also been felicitated for his role in this project The Large open terraces removes the monotonous box induced claustrophobia with its open to sky nature and elements that the occupants can use to relax at these terraces. The livelier the workspace, the better is the employee’s motivation and productivity.The design and construction of skyscrapers involves creating safe, habitable spaces in very tall buildings.The buildings must support their weight, resist wind and earthquakes, and protect occupants in case of fire. ",
        ],
        description3: [
          "They must also be conveniently accessible, even on the upper floors, and provide utilities and must have a comfortable microclimate for the occupants. The problems posed in skyscraper design are considered among the most complex to be encountered given the balances required between economics, engineering, and construction management.",
        ],
        tableImg: vhTable1,
        megazineCover: "",
      },
      {
        title: "ALTIUS",
        image1: vh3,
        description1: [
          "Altius is a high-end residential condominium consisting of 2 towers of 25 floors (G+24 storied) each total of with a bridge connecting the two. It is a green building with multi-level car parking on 1st & 2nd floors. The 24th floor houses a club and an infinity edge pool. Designed by Architect Kamal Periwal with concept support from aCta, Singapore, this project is likely to be ready by 2017.",
        ],
        image2: vh4,
        tableImg: vhTable2,
        megazineCover: "",
      },
      {
        title: "TATA EDEN COURT",
        image1: vh5,
        description1: [
          "Tata Eden Court consists of 5 towers spread over a land of nearly 8 acres. Phase I of Eden Court consists of three towers which is of G+19 floors, comprising 2BHK and 3BHK apartments. The remaining two are of G+23 floors having 3BHK premium apartments. The project is designed by Architect Kamal Periwal along with Callision USA, and hence changed the market dynamics of Rajarhat, Kolkata.",
          "The design vision for the project was to create an Urban Oasis. An urban street was metaphorically superimposed on the site to draw upon series of lines representing Main Street, alley ways and centralised urban green area, providing a wide range of opportunities for all users of the building to mix.",
        ],
        image2: vh6,
        description2: [
          "Five residential towers are rising from within an urban grid that is juxtaposed onto building elevations. The towers are uplifting and highlighted by coloured glass lantern screen and surrounded by fluid green garden with the club house located centrally as the jewel in the park.",
          "Tower D and E has been designed and configured with vastu principle with a strong emphasis on south east exposure for natural light and morning sun. Standing 23 stories high, Tower D and E creates a strong visually stunning skyline with a lush 3 storey sky garden at the 17th floor.",
        ],
        description3: [
          "The tower elevations have been overlaid with a series of informal projecting frames as patterns, superimposed one over the other to create a visual depth to the facades at uneven intervals, accentuating the feeling of movement.. A dark background colour, over which the contrasting white grids shine and a layer of copper accent elements, giving the building a playful feel, seemingly smooth facade and a strong visual impact.",
        ],
        tableImg: vhTable3,
        megazineCover: "",
      },
      {
        title: "Arch Waterfront",
        image1: vh7,
        description1: [
          "Good design greatly affects the quality of life, whether it is at home, the workplace or the city we live in. The Arch Water Front proves that elements such as space, utility, energy and environmental efficiency doesn’t have to be sacrificed for breath-taking aesthetic appeal.",
          "Situated in the heart of the IT corridor of Kolkata, Arch waterfront, designed by Architect Periwal, sits on the land’s end, occupying the most exquisite waterside location. It has been designed as a Grade A modern high rise tech commercial building. The elevation is contemporary with a combination of glass and perforated metal sheets.",
        ],
        image2: vh8,
        description2: [
          "The large, bright open plan floor plates offer upto 10,300 sq ft (approx) on a single floor. The generous dimension of floor plate with a column grid of 8x8m and Floor height of 4m enables efficient use of space and can accommodate large offices as well as can be divided into small offices.",
          "The Tall G+17 tower overlooks an adjoining water body. The design of the elevation has been kept minimalistic yet with a contemporary glass facade and perforated metal sheets used in combination. High Performance glass coated with anti-reflection coatings has been used from all sides to minimize glare.",
        ],
        description3: [
          "Architect Periwal has used glass quite extensively in many of his projects, working closely with International Glass manufacturers and their R&D teams, and hence has been able to change the perception of usage of glass in Climate control in Eastern India.",
          "Energy efficient air conditioning system has been used at Arch Waterfront with Central Chiller plant connected though AHU of the occupier through chilled water.",
          "Intelligent fire fighting system with water sprinkler and smoke detectors and microprocessor based alarm panel has also been used.",
        ],
        tableImg: vhTable4,
        megazineCover: vhMegazine,
      },
    ],
    relatedPost: [
      "https://www.instagram.com/p/B7s9hzfHDkc/?igshid=2lwr6gltwx89",
      "https://www.instagram.com/p/B7ir-ikHlEs/?igshid=kmpkikdwi3p2",
    ],
    relatedProjects: [
      {
        name: "Unimark Asian",
        link: "/project/commercial-office/project/unimark-asian",
      },
      {
        name: "Altius",
        link: "/project/residential-high-rise-tower/project/altius",
      },
      {
        name: "Tata Eden Court",
        link: "/project/residential-housing-complex/project/tata-eden-court",
      },
      // {
      //   name: "Arch Waterfront",
      //   link: "/project/residential-housing-complex/project/ananda-housing"
      // }
    ],
    relatedBlog: [
      {
        name: "Collective Living In Design",
        link: "collective-living-in-design",
      },
    ],
    meta: {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      mainEntityOfPage: {
        "@type": "WebPage",
        "@id": "https://www.architectm.com/publication/vertical-horizon",
      },
      headline: "Vertical Horizon",
      description:
        "We dwell in a scenario where urbanisation is the current trend, leading to growing demands for facilities hence better living standards that start from the very places we reside. The image of an urban space has become a skyline consisting of high-rise towers with an appealing facade accented by posh utilities such as swimming pools, gyms, arcades to name a few. This version of spaces which once used to be associated to places such as Manhattan, Seattle, and Dubai can now be seen adapted into our context to perfection in the eastern side of India.",
      image:
        "https://res.cloudinary.com/dubm0zyx2/image/upload/v1584815310/Maheshwari/blog/Vertical%20Horizon/Image_1_gbawb8.jpg",
      author: {
        "@type": "Organization",
        name: "Maheshwari & Associates",
      },
      publisher: {
        "@type": "Organization",
        name: "Maheshwari & Associates",
        logo: {
          "@type": "ImageObject",
          url: "https://www.architectm.com/static/media/logo.2dc7ae21.png",
        },
      },
      datePublished: "2015-10-01",
      dateModified: "2020-10-14",
    },
  },
  {
    title: "Cinema Cenetary: Imagine Unimagined",
    link: "imagine-the-unimagined",
    tags: [
      {
        tag: "Cultural,",
        link: "/category/cultural",
      },
      {
        tag: " Conceptual Project",
        link: "#",
      },
    ],

    published: "FOAID Magazine 2018",
    blogBy: "",
    headerImg: cciu1,
    projectInfo: [
      {
        projectName: "Cinema Cenetary Building Tollygunge",
        projectLocation: "Kolkata",
        projectStatus: "Unbuilt",
        siteArea: "20,145 Sq.ft",
        projectArea: "47,900 Sq.ft",
        desginTeam: "Kamal Periwal, Subhadip Ghosh, Jenson Cherian",
        type: "",
      },
    ],
    sideDescription: [
      "Forms follows function – a profound thought of 20th century modernist architects, is based on principle that the shape of the building or object should primarily relate to its intended function or purpose. However, drowning in the complexity of functions, organisation and optimizing it with the external and internal factors, it is a challenge to give priority to the form that describes the space.Basically, doing so means creating a mark on the surrounding scape to generate an identity of the place.",
    ],
    description1: [
      " In this project, the goal was to craft a built form that imitates & celebrate the expression of the art of movie making in the Indian Context.",
    ],
    img2: cciu2,
    img3: "",
    description2: [
      "The major concept that drove the design was to create a mental imagery on the people about the history of movie making. And, to objectify the concept we imitated the form of camera reel that was used an age ago. And, and one shot view of the built form could stir the mind to travel to the mental imagery. This, is a vision, which we thought could help in preserving the history of movie making and a urban scape that stimulates the people to have a thought.",
    ],
    img4: cciu3,

    relatedProjects: [
      {
        name: "Cinema Cenetary",
        link: "/project/cultural/project/cinema-centenary",
      },
    ],
    relatedBlog: [
      {
        name: "3102-bce-vedic-resort",
        link: "3102-bce-vedic-resort",
      },
    ],
    meta: {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      mainEntityOfPage: {
        "@type": "WebPage",
        "@id": "https://www.architectm.com/publication/imagine-the-unimagined",
      },
      headline: "Cinema Cenetary: Imagine Unimagined",
      description:
        "Forms follows function – a profound thought of 20th century modernist architects, is based on principle that the shape of the building or object should primarily relate to its intended function or purpose. However, drowning in the complexity of functions, organisation and optimizing it with the external and internal factors, it is a challenge to give priority to the form that describes the space.Basically, doing so means creating a mark on the surrounding scape to generate an identity of the place.",
      image:
        "https://res.cloudinary.com/dubm0zyx2/image/upload/v1584856197/Maheshwari/blog/Cinema%20Cenetary:%20Imagine%20Unimagined/VIEWS_pcysbd.jpg",
      author: {
        "@type": "Organization",
        name: "Maheshwari & Associates",
      },
      publisher: {
        "@type": "Organization",
        name: "Maheshwari & Associates",
        logo: {
          "@type": "ImageObject",
          url: "https://www.architectm.com/static/media/logo.2dc7ae21.png",
        },
      },
      datePublished: "2018-01-01",
      dateModified: "2020-10-14",
    },
  },
  {
    title: "3102 BCE Vedic Resort",
    link: "3102-bce-vedic-resort",
    tags: [
      {
        tag: "Hospitality,",
        link: "/category/hospitality",
      },
      {
        tag: " Resort",
        link: "#",
      },
    ],
    published: "FOAID Magazine 2019",
    blogBy: "",
    headerImg: bce31021,
    projectInfo: [
      {
        projectName: "3102 BCE Vedic Resort",
        projectLocation: "Goa",
        projectStatus: "Ongoing ",
        siteArea: "21679 Sq.ft",
        projectArea: "26000 Sq.ft",
        projectCompletion: "2019",
        projectInitiation: "2015",
        clientName: "Annapurna Savings & Finance Co. Pvt. Ltd.",
        desginTeam:
          "Subhadip Ghosh, Aniruddha Mutsuddi,Akash Shaw, Ashutosh Prasad",
        type: "",
      },
    ],
    sideDescription: [
      "The client’s brief was to design a contemporary Hotel on the lines of the ancient culture of the Vedic age, with incorporation of local elements. The Hotel must register its uniqueness and its ambience must be welcoming and warm not making it a one-time affair.",
      "The design was conceptualised on the theme of Indus Valley civilisation, with Incorporation themed fortification, with the concept of great baths, Harappa City walls with reducing wall widths and theme site entry points, exposed bricks construction, wells and many other aspects.",
    ],
    description1: [
      "The pitched roof represented Goan local culture and overall outlook of the building was to create a unique structure with magnificent ambience.The built form has been drawn on the Vedic science of Vastu Shastra of 9x 9 grid pattern, and thus, it forms a built space surrounding a courtyard. The courtyards also act as a space for recreation and leisure having amenities derived from re-Interpreting the Vedic cultural pattern. The calls for the language of the space to follow the contextual pattern that makes the place unique and give it an Identity. ",
    ],
    img2: bce31023,
    // img3: bce31022,
    description2: [
      "The site is located in a semi-urban setting of Goa, on the coastal plains. The place owing to its strategic location acts as a major catchment zone for tourist from both national and International levels.The natural contour of the site is utilized with the plaza adapting to the natural change in levels and the built form complementing the same, the overall built space hence achieved is unique in its own being and are assigned with different functions depending upon the level of use. The groundwater table is also maintained in the area through proper drainage.Use of building materials like AAC blocks, Kota stone, recycled ironworks and woodworks, etc. ",
    ],
    img4: bce31024,

    relatedPost: [
      "https://www.instagram.com/p/B2y3OWsngJF/?igshid=zpp2v5eeiezf",
    ],
    relatedProjects: [
      {
        name: "Starlit Suites",
        link: "/project/hospitality/project/starlit-suites",
      },
      {
        name: "The Ornate Hotel",
        link: "/project/interior-design/project/the ornate-hotel",
      },
    ],
    relatedBlog: [
      {
        name: "Cinema Cenetary: Imagine Unimagined",
        link: "imagine-the-unimagined",
      },
    ],
    meta: {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      mainEntityOfPage: {
        "@type": "WebPage",
        "@id": "https://www.architectm.com/publication/3102-bce-vedic-resort",
      },
      headline: "3102 BCE Vedic Resort",
      description:
        "The client’s brief was to design a contemporary Hotel on the lines of the ancient culture of the Vedic age, with incorporation of local elements. The Hotel must register its uniqueness and its ambience must be welcoming and warm not making it a one-time affair.The design was conceptualised on the theme of Indus Valley civilisation, with Incorporation themed fortification, with the concept of great baths, Harappa City walls with reducing wall widths and theme site entry points, exposed bricks construction, wells and many other aspects.",
      image:
        "https://res.cloudinary.com/dubm0zyx2/image/upload/v1584858751/Maheshwari/blog/3102%20BCE%20Vedic%20Resort/image2_itvtvv.jpg",
      author: {
        "@type": "Organization",
        name: "Maheshwari & Associates",
      },
      publisher: {
        "@type": "Organization",
        name: "Maheshwari & Associates",
        logo: {
          "@type": "ImageObject",
          url: "https://www.architectm.com/static/media/logo.2dc7ae21.png",
        },
      },
      datePublished: "2019-01-01",
      dateModified: "2020-10-14",
    },
  },
  {
    title: "Influencing Design through Facade",
    link: "influencing-design",
    tags: [
      {
        tag: " Commercial,",
        link: "/category/commercial-office",
      },
      {
        tag: " Office building,",
        link: "/category/commercial-office",
      },
      {
        tag: " Facade,",
        link: "#",
      },
      {
        tag: "Kolkata",
        link: "#",
      },
    ],
    published: "Modern Green Structure & Architecture",
    blogBy: "",
    date: "August 2018",
    headerImg: idesign2,
    projectInfo: [
      {
        projectName: "Unimark Asian",
        projectLocation: "Shakespeare Sarani, Kolkata",
        siteArea: "34703 sq.ft",
        projectArea: "107200 sq.ft",
        clientName: "Unimark Group",
        type: "Commercial Office",
      },
    ],
    sideDescription: [
      "The distinctive profile of new Unimark Asian’s B+G+17 headquarter office in the CBD area in Kolkata is derived from incorporating triple height external terraces in a dynamic way by stacking five glass boxes to complete the structure. The core is placed asymmetrically and only 4 further columns are required to create a column-free space in this steel formed building.",
    ],
    description1: [
      "In relation to the built form expression, we have divided the monotonous rectangular façade (almost 1:5) in five terraces, flourishing a unique identity that provides a landmark to re-invent the future of design. The terraces also help improve productivity of the workspaces by incorporating elements of nature inside.",
      "DGU glazing has been used in the façade to reduce heat. Since the gap between glasses does not allow a smooth heat conduction, this has also reduced the constant need of air- conditioning load. Ideal orientation with short facades in the east and west allowed utmost use of low e-glazing to maximize daylight into the office interiors and minimize artificial lighting, a prime energy consumption source. In this way, we have been able to save energy through the choice of our materials for the building skin. Through use of light tainted glass panels, we have created a seamless outdoor-indoor connectivity.",
    ],
    img2: idesign1,

    description2: [
      "The lower levels of the building have a triple height. In the lobby and some other services which do not require an external view, we have used stone cladding to give the external floating glass facade a solid base on the ground, providing a sense of gravity and dominance, which we wanted to be the impression of the company to its visitors. Here, the use of stone also acts as a transition phase from the green landscape towards the glass boxes.",
    ],

    relatedPost: [
      "https://www.instagram.com/p/B0yEwhfDi4i/?igshid=dzjjknhmiq8a",
      "https://www.instagram.com/p/B0qQRqPHTIY/?igshid=17akuswxrze53",
    ],
    relatedProjects: [
      {
        name: "23 Circus Avenue",
        link: "/project/commercial-office/project/23-circus-avenue",
      },
      {
        name: "Unimark Asian",
        link: "/project/commercial-office/project/unimark-asian",
      },
    ],
    meta: {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      mainEntityOfPage: {
        "@type": "WebPage",
        "@id": "https://www.architectm.com/publication/influencing-design",
      },
      headline: "Influencing Design through Facade",
      description:
        "The distinctive profile of new Unimark Asian’s B+G+17 headquarter office in the CBD area in Kolkata is derived from incorporating triple height external terraces in a dynamic way by stacking five glass boxes to complete the structure. The core is placed asymmetrically and only 4 further columns are required to create a column-free space in this steel formed building.",
      image:
        "https://res.cloudinary.com/dubm0zyx2/image/upload/v1584965988/Maheshwari/blog/Influencing%20Design/Unimark_5_lt05qx.jpg",
      author: {
        "@type": "Organization",
        name: "Maheshwari & Associates",
      },
      publisher: {
        "@type": "Organization",
        name: "Maheshwari & Associates",
        logo: {
          "@type": "ImageObject",
          url: "https://www.architectm.com/static/media/logo.2dc7ae21.png",
        },
      },
      datePublished: "2018-08-01",
      dateModified: "2020-10-14",
    },
  },
];
const states = {
  loading: true,
  showProjects: false,
  showProjects2: false,
  showSubCategories: false,
  showCategories: false,
  //category:Y]'0\JM "None",
  category: "HNA",
  subcategory: "ALL",
  activeSubCategory: "ALL",
  categories: categories,
  projects: projects,
  teams: teams,
  subcategories: subcategories,
  activeCategory: "HNA",
  activeProjectName: "HNA1",
  default: true,
  index: "",
  setProjectPageComp: false,
  homeRender: "categories",
  relatedProject: [],
  activeCatTitle: [],
  idd: 0,
  founder: true,
  activeTeam: "",
  seminar: seminar,
  slideCount: 1,
  currSlide: 1,
  SEOTags: SEOTags,
  blog: blog,
  activeBlog: "",
  blogName: [],
  idd2: 0,
  nextBlogName: "",
  prevBlogName: "",
  // intervalTracker: 1
};
var interval = null;
const reducer = (state = states, action) => {
  const newState = { ...state };
  const activeCategoryArray = () => {
    newState.activeCatTitle = [];
    //alert("array activeCategory" + newState.activeCategory);
    projects.map((item, index) => {
      if (newState.activeCategory == item.category) {
        newState.activeCatTitle.push(item.header);
      }
    });
  };
  const renderRelated = () => {
    //changeActiveProject();
    newState.relatedProject = [];
    if (newState.activeCatTitle.length > 5) {
      //newState.relatedProject[0] = newState.activeCatTitle[newState.idd];
      newState.relatedProject[1] =
        newState.activeCatTitle[
        (newState.idd + 1) % newState.activeCatTitle.length
        ];
      newState.relatedProject[2] =
        newState.activeCatTitle[
        (newState.idd + 2) % newState.activeCatTitle.length
        ];
      newState.relatedProject[3] =
        newState.activeCatTitle[
        (newState.idd + 3) % newState.activeCatTitle.length
        ];
      newState.relatedProject[4] =
        newState.activeCatTitle[
        (newState.idd + 4) % newState.activeCatTitle.length
        ];
      newState.relatedProject[5] =
        newState.activeCatTitle[
        (newState.idd + 5) % newState.activeCatTitle.length
        ];
    } else {
      //alert("else");
      newState.activeCatTitle.forEach(function (element, index) {
        newState.relatedProject[index] = newState.activeCatTitle[index];
      });
    }

    //alert("Related Projects" + newState.relatedProject);
  };
  const changeActiveProject = () => {
    newState.activeCatTitle.map((item, index) => {
      if (item === newState.activeProjectName) {
        newState.idd = index;
      }
    });
  };

  const moveRight = () => {
    if ($(window).width() < 800) {
      if (newState.currSlide <= newState.slideCount) {
        newState.currSlide = newState.currSlide + 1;

        $("#slider ul").css("left", "-=67.34375vw");
      }

      if (newState.currSlide > newState.slideCount) {
        $("#slider ul").css("left", "0");
        newState.currSlide = 1;
      }
    } else {
      if (newState.slideCount === 1) {
        $("#slider ul").css("left", "0");
      } else if (newState.slideCount > 1) {
        if (newState.currSlide <= newState.slideCount) {
          $("#slider ul").css("left", "-=" + 525 * (100 / 1920) + "vw");
          newState.currSlide = newState.currSlide + 1;
          if (newState.currSlide > newState.slideCount) {
            newState.currSlide = 1;
            $("#slider ul").css("left", "0");
          }
        }
        if (newState.currSlide > newState.slideCount) {
          newState.currSlide = 1;
          $("#slider ul").css("left", "0");
        }
      }
    }
  };
  const activeProject = (render) => {
    switch (render) {
      case "next": {
        newState.activeProjectName =
          newState.activeCatTitle[
          (newState.idd + 1) % newState.activeCatTitle.length
          ];

        changeActiveProject();
        renderRelated();
        console.log("run");
        break;
      }
      case "prev": {
        newState.activeProjectName =
          newState.activeCatTitle[
          (newState.activeCatTitle.length + newState.idd - 1) %
          newState.activeCatTitle.length
          ];
        changeActiveProject();
        renderRelated();
        break;
      }
      default: {
        changeActiveProject();
        renderRelated();
        break;
      }
    }
  };
  const SetblogName = () => {
    newState.blogName = [];
    blog.map((item) => {
      newState.blogName.push(item.link);
    });
  };
  const changeActiveBlog = () => {
    newState.blogName.map((item, index) => {
      if (item === newState.activeBlog) {
        newState.idd2 = index;
      }
    });
  };
  switch (action.type) {
    case "display_subCategories": {
      newState.showSubCategories = true;
      newState.activeSubCategory = action.value;
      if (window.matchMedia("(max-width: 800px)").matches) {
        $(".drop-contt").toggle();
      }

      // if ($(".pp-container").length == 0) {
      //   alert("does not exist");
      //   $(".noResult").css("display", "flex");
      // } else if ($(".pp-container").length > 0) {
      //   $(".noResult").css("display", "none");
      // }

      $(".drop-cont-links").click(function () {
        $(".drop-cont-links").css("color", "#a5a5a5");
        $(this).css("color", "black");
        // $(this).css("font-weight", "bold");
      });

      if (action.value === "ALL") {
        $(".js-content22").css("display", "none");
        $("#optt1").css("display", "block");
        $(".dropicon2").toggle();
        $(".dropicon1").toggle();
        $("#optt0").css("display", "none");
      }
      if (action.value === "COMPLETED PROJECTS") {
        $(".js-content22").css("display", "none");
        $("#optt2").css("display", "block");
        $("#optt2").css("color", "black");
        $("#optt2").css("font-weight", "bold");
        $(".dropicon2").toggle();
        $(".dropicon1").toggle();
        $("#optt0").css("display", "none");
      }
      if (action.value === "UPCOMING PROJECTS") {
        $(".js-content22").css("display", "none");
        $("#optt3").css("display", "block");
        $("#optt3").css("color", "black");
        $("#optt3").css("font-weight", "bold");
        $("#optt0").css("display", "none");

        $(".dropicon2").toggle();
        $(".dropicon1").toggle();
      }
      if (action.value === "CONCEPTUAL") {
        $(".js-content22").css("display", "none");
        $("#optt4").css("display", "block");
        $("#optt4").css("color", "black");
        $("#optt4").css("font-weight", "bold");
        $("#optt0").css("display", "none");

        $(".dropicon2").toggle();
        $(".dropicon1").toggle();
      }

      break;
    }

    case "display_Projects": {
      newState.homeRender = "subCategories";

      //addSubCategories();
      newState.activeCategory = action.value;
      // alert(newState.activeCategory);
      newState.showCategories = !newState.showCategories;
      newState.activeSubCategory = "ALL";
      newState.setProjectPageComp = !newState.setProjectPageComp;
      activeCategoryArray();
      changeActiveProject();
      renderRelated();
      break;
    }

    case "header_click": {
      newState.homeRender = "categories";

      break;
    }

    case "display_info": {
      newState.activeProjectName = action.value;
      //alert("activeProjectName" + newState.activeProjectName);
      // newState.showCategories = false;
      newState.homeRender = "showProjects";
      activeProject(action.value);
      break;
    }

    case "change_founder": {
      newState.founder = true;
      newState.activeTeam = "";
      if (window.matchMedia("(max-width: 800px)").matches) {
        $(".drop-cont").toggle();
        $(".dropicon2").toggle();
        $(".dropicon1").toggle();
      }
      $(".js-content2").css("display", "none");
      $("#op1").css("display", "inline-block");
      $("#op1").css("font-weight", "bold");
      // $(".right-section ").css("height", "79.7vh");

      break;
    }
    case "change_team": {
      newState.founder = false;
      newState.activeTeam = action.value;
      if (window.matchMedia("(max-width: 800px)").matches) {
        $(".drop-cont").toggle();
        // $(".dropicon2").css("display", "none");
        // $(".dropicon1").css("display", "block");
      }

      if (action.value === "AD") {
        $(".js-content2").css("display", "none");
        $("#op2").css("display", "inline-block");
        $("#op2").css("font-weight", "bold");
        $(".dropicon2").toggle();
        $(".dropicon1").toggle();
      }
      if (action.value === "ID") {
        $(".js-content2").css("display", "none");
        $("#op3").css("display", "inline-block");
        $("#op3").css("font-weight", "bold");
        $(".dropicon2").toggle();
        $(".dropicon1").toggle();
      }
      if (action.value === "SS") {
        $(".js-content2").css("display", "none");
        $("#op4").css("display", "inline-block");
        $("#op4").css("font-weight", "bold");
        $(".dropicon2").toggle();
        $(".dropicon1").toggle();
      }
      if (action.value === "ADMIN") {
        $(".js-content2").css("display", "none");
        $("#op5").css("display", "inline-block");
        $("#op5").css("font-weight", "bold");
        $(".dropicon2").toggle();
        $(".dropicon1").toggle();
      }

      break;
    }
    case "set_loading":
      newState.loading = false;

    case "menutoggle": {
      $(".js-content").css("display", "none");
      $("." + action.value).css("display", "block");
      //$("." + name).css("color", "black");
      if (action.value === "team-sec1") {
        $(".sublinks").css("display", "block");
        $("li:first-child").addClass("activee");
        newState.founder = true;
      } else if (action.value === "intro-sec") {
        $(".sublinks").css("display", "none");

        newState.founder = false;
      }
      // newState.founder = true;
      break;
    }

    case "menutoggle2": {
      if (action.value === "Achievements") {
        $(".grid-containerrr2").css("display", "none");
        $(".grid-containerrr").css("display", "grid");
        $(this).addClass("activee");
      }
      if (action.value === "Seminar") {
        $(".grid-containerrr2").css("display", "grid");
        $(".grid-containerrr").css("display", "none");
      }
      break;
    }
    case "onboard_project": {
      newState.homeRender = "subCategories";
      newState.activeCategory = action.value;
      // alert(newState.activeCategory);
      newState.activeSubCategory = "ALL";
      activeCategoryArray();
      //  alert("NS" + newState.homeRender);

      break;
    }
    case "nextsection": {
      $(".drop-cont").css("display", "none");
      $(".sec-1").css("display", "none");
      $(".team-sec1").css("display", "block");
      $("#s-1").css("display", "none");
      $("#s-2").css("display", "inline-block");
      $(".mob-sublinks").css("display", "block");
      $(".js-content2").css("display", "none");
      $("#op1").css("display", "inline-block");
      $("#op1").css("font-weight", "bold");
      $(".currR").css("display", "none");
      $(".disR").css("display", "inline-block");
      $(".currL").css("display", "inline-block");
      $(".disL").css("display", "none");

      // $("li").removeClass("activee");
      newState.founder = true;
      break;
    }
    case "prevsection": {
      $(".drop-cont").css("display", "none");
      $(".sec-2").css("display", "none");
      $(".sec-1").css("display", "block");
      $("#s-1").css("display", "inline-block");
      $("#s-2").css("display", "none");
      $(".mob-sublinks").css("display", "none");
      $(".team-sec1").css("display", "block");

      $(".currR").css("display", "inline-block");
      $(".disR").css("display", "none");
      $(".currL").css("display", "none");
      $(".disL").css("display", "inline-block");

      break;
    }
    case "popupToggle2": {
      $("html").css("overflow", "hidden");

      $(".grid-containerrr2")
        .find("." + action.value)
        .children(".overlay")
        .css("display", "block");

      newState.slideCount = $(".grid-containerrr2")
        .find("." + action.value)
        .find("#slider")
        .children("ul")
        .children("li").length;
      $("#slider ul").css("left", "0");

      newState.currSlide = 1;
      // if (newState.slideCount > 1) {
      //   newState.intervalTracker = 1;
      //   interval = setInterval(function() {
      //     moveRight();
      //   }, 2000);
      // }
      break;
    }
    case "popupClose2": {
      // $("html").css("overflow", "auto");
      $("html").css("overflow-y", "auto");
      $("html").css("overflow-x", "hidden");

      $(".grid-containerrr2")
        .find("." + action.value)
        .children(".overlay")
        .css("display", "none");
      newState.slideCount = 1;
      clearInterval(interval);
      break;
    }
    case "setActiveBlog": {
      SetblogName();
      newState.activeBlog = action.value;
      break;
    }
    case "testArray": {
      SetblogName();
      changeActiveBlog();
      if (newState.idd2 === newState.blogName.length - 1) {
        newState.nextBlogName = newState.blogName[0];
        newState.prevBlogName = newState.blogName[newState.blogName.length - 2];
      }
      if (newState.idd2 !== 0 && newState.idd2 < newState.blogName.length - 1) {
        newState.nextBlogName = newState.blogName[newState.idd2 + 1];
        newState.prevBlogName = newState.blogName[newState.idd2 - 1];
      }
      if (newState.idd2 === 0) {
        newState.nextBlogName = newState.blogName[newState.idd2 + 1];
        newState.prevBlogName = newState.blogName[newState.blogName.length - 1];
      }
      break;
    }
    case "nextBlog": {
      newState.activeBlog =
        newState.blogName[(newState.idd2 + 1) % newState.blogName.length];
      changeActiveBlog();

      if (newState.idd2 === newState.blogName.length - 1) {
        newState.nextBlogName = newState.blogName[0];
        newState.prevBlogName = newState.blogName[newState.blogName.length - 2];
      }
      if (newState.idd2 !== 0 && newState.idd2 < newState.blogName.length - 1) {
        newState.nextBlogName = newState.blogName[newState.idd2 + 1];
        newState.prevBlogName = newState.blogName[newState.idd2 - 1];
      }
      if (newState.idd2 === 0) {
        newState.nextBlogName = newState.blogName[newState.idd2 + 1];
        newState.prevBlogName = newState.blogName[newState.blogName.length - 1];
      }

      break;
    }
    case "prevBlog": {
      newState.activeBlog =
        newState.blogName[
        (newState.blogName.length + newState.idd2 - 1) %
        newState.blogName.length
        ];
      changeActiveBlog();

      if (newState.idd2 === newState.blogName.length - 1) {
        //last
        newState.nextBlogName = newState.blogName[0];
        console.log(newState.nextBlogName);
        newState.prevBlogName = newState.blogName[newState.blogName.length - 2];
      }
      if (newState.idd2 === 0) {
        //first
        newState.nextBlogName = newState.blogName[newState.idd2 + 1];
        newState.prevBlogName = newState.blogName[newState.blogName.length - 1];
      }
      if (
        newState.idd2 !== 0 &&
        newState.idd2 !== newState.blogName.length - 1
      ) {
        newState.nextBlogName = newState.blogName[newState.idd2 + 1];
        newState.prevBlogName = newState.blogName[newState.idd2 - 1];
      }
      break;
    }
    default: {
    }
  }
  return newState;
};
export default reducer;
