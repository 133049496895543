import React, { Component } from "react";
import "./achievementsmenu.css";
import Header from "../header/Header";
import Achievements from "../achievements/Achievements";
import Footer from "../footer/Footer";
import RightMenuOptComponent from "../rightmenuoptcomponent/RightMenuOptionComponent";
import $ from "jquery";
import { connect } from "react-redux";
import LI from "../aboutuscomponent/Licon.png";
import RI from "../aboutuscomponent/Ricon.png";
import disLicon from "../aboutuscomponent/disLicon.png";
import { Helmet } from "react-helmet";
import disRicon from "../aboutuscomponent/disRicon.png";
// let sem1 =
//   "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435162/Maheshwari/Seminar/1_i0zamm.jpg";
let sem1BW =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1564560389/Maheshwari/Seminar/100.2_w7tnyp.jpg";
// let sem2 =
//   "https://res.cloudinary.com/dubm0zyx2/image/upload/v1565329207/Maheshwari/Seminar/2BW_vvutzp.jpg";
let sem2BW =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1565329207/Maheshwari/Seminar/2BW_vvutzp.jpg";
// let sem3 =
//   "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435163/Maheshwari/Seminar/3_y3vwfi.jpg";
let sem3BW =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1565329207/Maheshwari/Seminar/3BW_nhyctg.jpg";
// let sem4 =
//   "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435163/Maheshwari/Seminar/4_dmytqt.jpg";
let sem4BW =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1565329207/Maheshwari/Seminar/4BW_tacdvb.jpg";
// let sem5 =
//   "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435163/Maheshwari/Seminar/5_nqfn22.jpg";
let sem5BW =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1565329207/Maheshwari/Seminar/5BW_x3hzid.jpg";
// let sem6 =
//   "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435163/Maheshwari/Seminar/6_bd2eyi.jpg";
let sem6BW =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1565329207/Maheshwari/Seminar/6BW_f2d5nv.jpg";
// let sem7 =
//   "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435164/Maheshwari/Seminar/7_ezbssr.jpg";
let sem7BW =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1565329207/Maheshwari/Seminar/7BW_bjc5uc.jpg";
// let sem8 =
//   "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435164/Maheshwari/Seminar/8_qvfwgj.jpg";
let sem8BW =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1565329206/Maheshwari/Seminar/8BW_gvkzij.jpg";
let sem9BW =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1566567502/Maheshwari/Seminar/sem9_1_mlg0rd.jpg";
let sem10 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1571983529/Maheshwari/Seminar/sem10_1_jzp3ys.jpg";
let award1 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435167/Maheshwari/Awards/award1_aptosz.jpg";
let award2 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435169/Maheshwari/Awards/award2_rcuj4a.jpg";
let award3 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435169/Maheshwari/Awards/award3_gp9bkt.jpg";
let award4 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435169/Maheshwari/Awards/award4_utzmxx.jpg";
let award5 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435169/Maheshwari/Awards/award5_micfrm.jpg";
let award7 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435170/Maheshwari/Awards/award7_lgdiai.jpg";
let award8 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435170/Maheshwari/Awards/award8_gp2biv.jpg";
let award9 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435170/Maheshwari/Awards/award9_y8gllb.jpg";
let award11 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435167/Maheshwari/Awards/award11_fzmotk.jpg";
let award1BW =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435168/Maheshwari/Awards/award1BW_bky3hj.jpg";
let award2BW =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435169/Maheshwari/Awards/award2BW_hvamox.jpg";
let award3BW =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435169/Maheshwari/Awards/award3BW_ew7z8c.jpg";
let award4BW =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435169/Maheshwari/Awards/award4BW_sqmfms.jpg";
let award5BW =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435170/Maheshwari/Awards/award5BW_hrfhij.jpg";
let award7BW =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435170/Maheshwari/Awards/award7BW_kg40qp.jpg";
let award8BW =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435170/Maheshwari/Awards/award8BW_oshrej.jpg";
let award9BW =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435170/Maheshwari/Awards/award9BW_qe2ays.jpg";
let award11BW =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435167/Maheshwari/Awards/award11BW_qceqoi.jpg";
let award12 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435168/Maheshwari/Awards/award12_qwxgiu.jpg";
let award12BW =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435168/Maheshwari/Awards/award12BW_jgh37b.jpg";
let award14 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435168/Maheshwari/Awards/award14_nckszi.jpg";
let award14BW =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435168/Maheshwari/Awards/award14BW_r33vce.jpg";
let slideCount;
class AchievementsMenu extends Component {
  state = {};
  componentDidMount() {
    $(document).scrollTop(0);

    $("#s-1").css("display", "inline-block");
    $(".currL").css("display", "none");
    $(".disL").css("display", "inline-block");
  }

  displaysec(name) {
    $("." + name)
      .children(".info-containerr")
      .css({
        opacity: "1",
        color: "white",
        transition: "opacity 0.8s ease-in-out",
      });

    $("." + name)
      .children(".info-containerr")
      .css({
        "background-color": "rgba(0, 0, 0, 0.5)",
        transition: "opacity 0.5s ease-in-out",
      });

    $("." + name).removeClass("support");
  }
  blocksec(name) {
    $("." + name)
      .children(".info-containerr")
      .css("opacity", "0");
    $("." + name)
      .children(".info-containerr")
      .css({
        "background-color": "rgba(0, 0, 0, 0)",
      });
    $("." + name).addClass("support");
  }

  popupToggle = (name) => {
    // $(window).scroll(function () {
    //   $(window).scrollTop(current);
    // });
    $("html").css("overflow", "hidden");

    $(".grid-containerrr")
      .find("." + name)
      .children(".overlay")
      .css("display", "block");
  };

  popupClose = (name) => {
    $("html").css("overflow-y", "auto");
    $("html").css("overflow-x", "hidden");
    // $(window).off('scroll');
    $(".grid-containerrr")
      .find("." + name)
      .children(".overlay")
      .css("display", "none");
  };

  // popupToggle2 = name => {
  //   $("html").css("overflow", "hidden");
  //   //$("body").css("overflow", "hidden");
  //   //alert("as");
  //   //alert("toggle");
  //   $(".grid-containerrr2")
  //     .find("." + name)
  //     .children(".overlay")
  //     .css("display", "block");
  //   // alert(name);

  //   slideCount = $(".grid-containerrr2")
  //     .find("." + name)
  //     .find("#slider")
  //     .children("ul")
  //     .children("li").length;
  // };

  // popupClose2 = name => {
  //   $("html").css("overflow", "overlay");
  //   //$("body").css("overflow", "inherit");
  //   //alert(name);
  //   $(".grid-containerrr2")
  //     .find("." + name)
  //     .children(".overlay")
  //     .css("display", "none");
  //   // this.props.slideCount = 0;
  // };

  next() {
    $(".grid-containerrr").css("display", "none");
    $(".grid-containerrr2").css("display", "grid");
    $("#s-1").css("display", "none");
    $("#s-2").css("display", "inline-block");

    $(".currR").css("display", "none");
    $(".disR").css("display", "inline-block");
    $(".currL").css("display", "inline-block");
    $(".disL").css("display", "none");
  }
  prev() {
    $(".grid-containerrr").css("display", "grid");
    $(".grid-containerrr2").css("display", "none");
    $("#s-1").css("display", "inline-block");
    $("#s-2").css("display", "none");

    $(".currR").css("display", "inline-block");
    $(".disR").css("display", "none");
    $(".currL").css("display", "none");
    $(".disL").css("display", "inline-block");
  }
  componentDidUpdate() {
    $(document).scrollTop(0);

    // $("html").css("overflow", "auto");
    // $("html").css("overflow-x", "hidden");
  }
  render() {
    return (
      <div>
        <Helmet>
          <title> Achievement | Maheshwari & Associates</title>
          {/* <link
            rel="canonical"
            href="https://www.architectm.com/achievements"
          /> */}
          <meta
            name="description"
            content="We have received recognition and award for best resdential projects, best commercial projects and best architect by Forbes Design, CREDAI, FOAID, etc. "
          />
          <meta
            name="keywords"
            content="architect firm near me ,architecture companies in mumbai, interior design firms in kolkata,awards, achievements"
          />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="Maheshwari and Associates" />
          <meta property="og:url" content="https://www.architectm.com" />
          <meta
            property="og:description"
            content="Maheshwari & Associates is one of the best architecture firm having their offices in mumbai and Kolkata"
          ></meta>
        </Helmet>
        <div className="fixedsec">
          <Header />
        </div>
        <div className="sections group">
          {/* <div className="overlay">
            <div className="popup" />
          </div> */}
          <div className="col left-sectionn">
            <ul>
              <li
                className="opt1-achieve"
                onClick={() => this.props.menutoggle2("Achievements")}
              >
                <RightMenuOptComponent
                  className="opt1-achieve"
                  opt_item="Awards"
                />
              </li>
              <li
                className="opt2-seminar"
                onClick={() => this.props.menutoggle2("Seminar")}
              >
                <RightMenuOptComponent
                  className="opt2-seminar"
                  opt_item="Seminar"
                />
              </li>
              {/* <li>
                <RightMenuOptComponent opt_item="Events" />
              </li> */}
            </ul>
          </div>
          <div className="col right-section">
            <div className="options">
              <img className="Limage disL" src={disRicon} />
              <img className="Limage currL" src={LI} onClick={this.prev} />
              <p id="s-1">Awards</p>
              <p id="s-2">Seminar</p>
              <img className="Rimage disR" src={disLicon} />
              <img className="Rimage currR" src={RI} onClick={this.next} />
            </div>
            {/* <div className={"grid-containerrr" + this.props.className}> */}
            <div className="grid-containerrr ani-show2">
              <Achievements
                popupToggle={() => this.popupToggle("grid2")}
                onMouseOver={() => this.displaysec("grid2")}
                onMouseOut={() => this.blocksec("grid2")}
                className="grid1 pointer"
                awardname="Konstruct 2.0 Design & Innovation Awards"
                initby="Initiative by SRMB"
                year="2017"
                popupClose={() => this.popupClose("grid2")}
                //name="grid2"
                //awardimg={pp1}
              />

              <Achievements
                className="grid2 support"
                initby=""
                awardname="First prize in Residential plotted category"
                awardimg={award1BW}
                awardimgBW={award1}
                popupToggle={() => this.popupToggle("grid2")}
                popupClose={() => this.popupClose("grid2")}
              />

              <Achievements
                popupToggle={() => this.popupToggle("grid4")}
                onMouseOver={() => this.displaysec("grid4")}
                onMouseOut={() => this.blocksec("grid4")}
                popupClose={() => this.popupClose("grid4")}
                className="grid3 pointer"
                awardname="CREDAI Realty Award 2019"
                initby="CREDAI"
                year="2019"
              />

              <Achievements
                popupToggle={() => this.popupToggle("grid4")}
                popupClose={() => this.popupClose("grid4")}
                className="grid4 support"
                awardname="Best Residential Project (upcoming)"
                initby=""
                awardimg={award2BW}
                awardimgBW={award2}
              />

              <Achievements
                className="grid5 support"
                awardname="Outstanding services to the society and noteworthy contribution in Architecture"
                awardimg={award3BW}
                awardimgBW={award3}
                initby=""
                popupToggle={() => this.popupToggle("grid5")}
                popupClose={() => this.popupClose("grid5")}
              />

              <Achievements
                popupToggle={() => this.popupToggle("grid5")}
                onMouseOver={() => this.displaysec("grid5")}
                onMouseOut={() => this.blocksec("grid5")}
                popupClose={() => this.popupClose("grid5")}
                className="grid6 pointer"
                awardname="Recognition Award"
                initby="Initiative by ABID"
                year="2018"
              />

              <Achievements
                className="grid7 support"
                awardname="Initiative by Pavit"
                awardimg={award4BW}
                awardimgBW={award4}
                init=""
                popupToggle={() => this.popupToggle("grid7")}
                popupClose={() => this.popupClose("grid7")}
              />
              <Achievements
                className="grid8 pointer"
                awardname="Recognition Award"
                //initby="Initiative by Pavit"
                year="2018"
                popupToggle={() => this.popupToggle("grid7")}
                onMouseOver={() => this.displaysec("grid7")}
                onMouseOut={() => this.blocksec("grid7")}
                popupClose={() => this.popupClose("grid7")}
              />
              <Achievements
                popupToggle={() => this.popupToggle("grid10")}
                onMouseOver={() => this.displaysec("grid10")}
                onMouseOut={() => this.blocksec("grid10")}
                popupClose={() => this.popupClose("grid10")}
                className="grid9 pointer"
                awardname="Excellence in Design & Architecture"
                initby="Initiative by FOAID"
                year="2018"
              />
              <Achievements
                popupToggle={() => this.popupToggle("grid10")}
                popupClose={() => this.popupClose("grid10")}
                className="grid10 support"
                awardname="Excellence in Design & Architecture by FOAID"
                initby=""
                awardimg={award5BW}
                awardimgBW={award5}
              />
              <Achievements
                className="grid11 support"
                awardname="Special Contribution"
                awardimg={award12BW}
                awardimgBW={award12}
                popupToggle={() => this.popupToggle("grid11")}
                popupClose={() => this.popupClose("grid11")}
                initby=""
              />
              <Achievements
                popupToggle={() => this.popupToggle("grid11")}
                onMouseOver={() => this.displaysec("grid11")}
                onMouseOut={() => this.blocksec("grid11")}
                popupClose={() => this.popupClose("grid11")}
                className="grid12 pointer"
                awardname="Presented by Austin Plywood"
                initby="Initiative by Austin Plywood"
                year="2018"
              />
              <Achievements
                popupToggle={() => this.popupToggle("grid14")}
                onMouseOver={() => this.displaysec("grid14")}
                onMouseOut={() => this.blocksec("grid14")}
                popupClose={() => this.popupClose("grid14")}
                className="grid13 pointer"
                awardname="Konstruct 2.0 Design & Innovation Awards"
                initby="Initiative by SRMB"
                year="2017"
              />
              <Achievements
                className="grid14 support"
                awardname="Second prize in Residential plotted category."
                awardimg={award7BW}
                awardimgBW={award7}
                popupToggle={() => this.popupToggle("grid14")}
                popupClose={() => this.popupClose("grid14")}
                initby=""
              />
              <Achievements
                popupToggle={() => this.popupToggle("grid16")}
                onMouseOver={() => this.displaysec("grid16")}
                onMouseOut={() => this.blocksec("grid16")}
                popupClose={() => this.popupClose("grid16")}
                className="grid15 pointer"
                awardname="CREDAI Realty Award 2019"
                initby="Initiative by CREDAI"
                year="2019"
              />

              <Achievements
                className="grid16 support"
                awardname="Best Commercial Project (upto 1.5 L sq.ft)"
                initby=""
                awardimg={award8BW}
                awardimgBW={award8}
                popupToggle={() => this.popupToggle("grid16")}
                popupClose={() => this.popupClose("grid16")}
              />

              <Achievements
                className="grid18 support"
                awardname="Best Residential Project Completed"
                awardimg={award9BW}
                initby=""
                awardimgBW={award9}
                popupToggle={() => this.popupToggle("grid18")}
                popupClose={() => this.popupClose("grid18")}
              />
              <Achievements
                popupToggle={() => this.popupToggle("grid18")}
                onMouseOver={() => this.displaysec("grid18")}
                onMouseOut={() => this.blocksec("grid18")}
                popupClose={() => this.popupClose("grid18")}
                className="grid17 pointer"
                awardname="CREDAI Realty Award 2019"
                initby="Initiative by CREDAI"
                year="2019"
              />

              <Achievements
                popupToggle={() => this.popupToggle("grid20")}
                onMouseOver={() => this.displaysec("grid20")}
                onMouseOut={() => this.blocksec("grid20")}
                popupClose={() => this.popupClose("grid20")}
                className="grid19 pointer"
                awardname="CREDAI Realty Award 2018 Architect Award"
                initby="Initiative by CREDAI"
                year="2018"
              />
              <Achievements
                className="grid20 support"
                initby=""
                awardname="Best Residential Award (Upcoming)"
                awardimg={award14BW}
                awardimgBW={award14}
                popupToggle={() => this.popupToggle("grid20")}
                popupClose={() => this.popupClose("grid20")}
              />
              <Achievements
                popupToggle={() => this.popupToggle("grid22")}
                onMouseOver={() => this.displaysec("grid22")}
                onMouseOut={() => this.blocksec("grid22")}
                popupClose={() => this.popupClose("grid22")}
                className="grid21 pointer"
                awardname="Forbes India Design Award"
                initby="Forbes"
                year="2019"
              />

              <Achievements
                className="grid22 support"
                awardname="Best Residential Project for Ananda Complex"
                awardimg={award11BW}
                awardimgBW={award11}
                initby=""
                popupToggle={() => this.popupToggle("grid22")}
                popupClose={() => this.popupClose("grid22")}
              />
            </div>

            <div className="seminar-container">
              <div className="grid-containerrr2 ani-show2">
                <Achievements
                  popupToggle={() => this.props.popupToggle2("grid2")}
                  onMouseOver={() => this.displaysec("grid2")}
                  onMouseOut={() => this.blocksec("grid2")}
                  popupClose={() => this.props.popupClose2("grid2")}
                  className="grid1 pointer"
                  awardname="Jury at Berger Architecture Ideas 4.0"
                  initby="Initiative by FOAID"
                  year="2018"
                  idd={0}
                />

                <Achievements
                  popupToggle={() => this.props.popupToggle2("grid2")}
                  popupClose={() => this.props.popupClose2("grid2")}
                  className="grid2 support greyscale"
                  awardname="Initiative by FOAID"
                  initby=""
                  awardimg={sem1BW}
                  idd={0}
                />
                <Achievements
                  popupToggle={() => this.props.popupToggle2("grid4")}
                  onMouseOver={() => this.displaysec("grid4")}
                  onMouseOut={() => this.blocksec("grid4")}
                  className="grid3 pointer"
                  awardname="Speaker at Design Perspective"
                  initby="Initiative by Saidexpo.com"
                  year="2018"
                  popupClose={() => this.props.popupClose2("grid4")}
                  idd={1}
                />
                <Achievements
                  className="grid4 support greyscale"
                  popupToggle={() => this.props.popupToggle2("grid4")}
                  popupClose={() => this.props.popupClose2("grid4")}
                  awardimg={sem2BW}
                  initby=""
                  // awardimgBW={sem2}
                  awardname="Initiative by Saidexpo"
                  idd={1}
                />
                <Achievements
                  className="grid5 support greyscale"
                  popupToggle={() => this.props.popupToggle2("grid5")}
                  popupClose={() => this.props.popupClose2("grid5")}
                  awardimg={sem3BW}
                  initby=""
                  // awardimgBW={sem3}
                  awardname="Topic: Quantifying Sustainability through Architecture."
                  idd={2}
                />
                <Achievements
                  popupToggle={() => this.props.popupToggle2("grid5")}
                  onMouseOver={() => this.displaysec("grid5")}
                  onMouseOut={() => this.blocksec("grid5")}
                  className="grid6 greyscale pointer"
                  awardname="Speaker at Design Perspective"
                  initby="Initiative by Saidexpo.com"
                  year="2019"
                  popupClose={() => this.props.popupClose2("grid5")}
                  idd={2}
                />
                <Achievements
                  className="grid7 support greyscale"
                  awardname="'When tradition meets modern flair.'Handcrafted Paisely marble table with intricate carvings."
                  popupToggle={() => this.props.popupToggle2("grid7")}
                  popupClose={() => this.props.popupClose2("grid7")}
                  initby=""
                  awardimg={sem4BW}
                  idd={3}
                  // awardimgBW={sem4}
                />
                <Achievements
                  popupToggle={() => this.props.popupToggle2("grid7")}
                  onMouseOver={() => this.displaysec("grid7")}
                  onMouseOut={() => this.blocksec("grid7")}
                  popupClose={() => this.props.popupClose2("grid7")}
                  className="grid8 pointer"
                  awardname="Product Design displayed at Expo"
                  idd={3}
                  year="2018"
                />
                <Achievements
                  className="grid9 pointer"
                  awardname="Steel Construction - Challenges and Solution."
                  popupToggle={() => this.props.popupToggle2("grid10")}
                  onMouseOver={() => this.displaysec("grid10")}
                  onMouseOut={() => this.blocksec("grid10")}
                  popupClose={() => this.props.popupClose2("grid10")}
                  initby="Initiative by SSMB"
                  idd={4}
                  year="2018"
                />
                <Achievements
                  className="grid10 support greyscale"
                  awardname="Distinguished speaker at Steel Construction Summit"
                  popupToggle={() => this.props.popupToggle2("grid10")}
                  popupClose={() => this.props.popupClose2("grid10")}
                  initby=""
                  awardimg={sem5BW}
                  idd={4}
                  // awardimgBW={sem5}
                />
                <Achievements
                  popupToggle={() => this.props.popupToggle2("grid11")}
                  onMouseOver={() => this.displaysec("grid11")}
                  onMouseOut={() => this.blocksec("grid11")}
                  popupClose={() => this.props.popupClose2("grid11")}
                  className="grid12 pointer"
                  awardname="CNBC Panel Discussion"
                  initby="Initiative by CERA"
                  idd={5}
                  year="2013"
                />
                <Achievements
                  popupToggle={() => this.props.popupToggle2("grid11")}
                  popupClose={() => this.props.popupClose2("grid11")}
                  className="grid11 support greyscale"
                  awardname="CNBC Real Estate Awards Ground Awards "
                  awardimg={sem6BW}
                  idd={5}
                  initby=""
                  // awardimgBW={sem6}
                />
                <Achievements
                  className="grid14 support greyscale"
                  awardname="East Zone jury member for Architecture and Interior Design students"
                  popupToggle={() => this.props.popupToggle2("grid14")}
                  popupClose={() => this.props.popupClose2("grid14")}
                  initby=""
                  awardimg={sem7BW}
                  idd={6}
                  // awardimgBW={sem7}
                />
                <Achievements
                  popupToggle={() => this.props.popupToggle2("grid14")}
                  onMouseOver={() => this.displaysec("grid14")}
                  onMouseOut={() => this.blocksec("grid14")}
                  popupClose={() => this.props.popupClose2("grid14")}
                  className="grid13 pointer"
                  awardname="Zonal Jury Member at CERA event."
                  idd={6}
                  initby=""
                  year="2018"
                />
                <Achievements
                  className="grid16 support greyscale"
                  awardname="Intelligent Design Solution"
                  popupToggle={() => this.props.popupToggle2("grid16")}
                  popupClose={() => this.props.popupClose2("grid16")}
                  awardimg={sem8BW}
                  idd={7}
                  initby=""
                  // awardimgBW={sem8}
                />
                <Achievements
                  popupToggle={() => this.props.popupToggle2("grid16")}
                  onMouseOver={() => this.displaysec("grid16")}
                  onMouseOut={() => this.blocksec("grid16")}
                  popupClose={() => this.props.popupClose2("grid16")}
                  className="grid15 pointer"
                  awardname="Speaker at ABID seminar"
                  initby=""
                  idd={7}
                  year="2018"
                />
                <Achievements
                  className="grid18 support greyscale"
                  awardname="Spoke on the topic “Emerging Trends and demands of contemporary India”"
                  popupToggle={() => this.props.popupToggle2("grid18")}
                  popupClose={() => this.props.popupClose2("grid18")}
                  initby=""
                  awardimg={sem9BW}
                  idd={8}
                  // awardimgBW={sem8}
                />
                <Achievements
                  popupToggle={() => this.props.popupToggle2("grid18")}
                  onMouseOver={() => this.displaysec("grid18")}
                  onMouseOut={() => this.blocksec("grid18")}
                  popupClose={() => this.props.popupClose2("grid18")}
                  className="grid17 pointer"
                  awardname="Keynote Speaker at Think Turf Event"
                  initby="Initiative by Voltas "
                  idd={8}
                  year="2019"
                />

                <Achievements
                  className="grid19 support greyscale"
                  awardname="Showcased project 3102 BCE Vedic Resort in Goa. Participated in jury"
                  popupToggle={() => this.props.popupToggle2("grid19")}
                  popupClose={() => this.props.popupClose2("grid19")}
                  initby=""
                  awardimg={sem10}
                  idd={9}
                  // awardimgBW={sem8}
                />
                <Achievements
                  popupToggle={() => this.props.popupToggle2("grid19")}
                  onMouseOver={() => this.displaysec("grid19")}
                  onMouseOut={() => this.blocksec("grid19")}
                  popupClose={() => this.props.popupClose2("grid19")}
                  className="grid20 pointer"
                  awardname="FOAID Delhi"
                  initby="FOAID"
                  idd={9}
                  year="2019"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="achieve-footer">
          <Footer />
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    seminar: state.seminar,
    slideCount: state.slideCount,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    menutoggle2: (name) => dispatch({ type: "menutoggle2", value: name }),
    popupToggle2: (name) => dispatch({ type: "popupToggle2", value: name }),
    popupClose2: (name) => dispatch({ type: "popupClose2", value: name }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AchievementsMenu);
