import React, { Component } from "react";
import client1 from "./client1.css";
import Client2 from "../client2/Client2";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import c1 from "./images/1.jpg";
import c2 from "./images/2.jpg";
import c3 from "./images/3.jpg";
import c4 from "./images/4.jpg";
import c5 from "./images/5.jpg";
import c6 from "./images/6.jpg";
import c7 from "./images/7.jpg";
import c8 from "./images/8.jpg";
import c9 from "./images/9.jpg";
import c10 from "./images/10.jpg";
import c11 from "./images/11.jpg";
import c12 from "./images/12.jpg";
import c13 from "./images/13.jpg";
import c14 from "./images/14.jpg";
import c15 from "./images/15.jpg";
import c16 from "./images/16.jpg";
import c17 from "./images/17.jpg";
import c18 from "./images/18.jpg";
import c19 from "./images/19.jpg";
import c20 from "./images/20.jpeg";
import c21 from "./images/21.jpg";
import c22 from "./images/22.jpg";
import c23 from "./images/23.jpg";
import c24 from "./images/24.jpg";
import c25 from "./images/25.jpg";
import c26 from "./images/26.jpg";
import c27 from "./images/27.jpg";
import c28 from "./images/28.jpg";
import c29 from "./images/29.jpg";
import c30 from "./images/30.jpg";
import $ from "jquery";
import { Helmet } from "react-helmet";

class Client1 extends Component {
  state = {};

  client() {
    $(".clients").css("display", "block");
    $(".collab").css("display", "none");
    $(".leftbtn").css("font-weight", "bold");
    $(".rightbtn").css("font-weight", "unset");
  }
  colab() {
    $(".clients").css("display", "none");
    $(".collab").css("display", "block");
    $(".rightbtn").css("font-weight", "bold");
    $(".leftbtn").css("font-weight", "unset");

    // $(".leftbtn").css("background-color", "white");
    // $(".rightbtn").css("background-color", "#d0d0d0");
  }
  componentDidMount() {
    $(document).scrollTop(0);

    $("html").css("overflow-x", "hidden");
    $("body").css("overflow-x", "hidden");
  }
  render() {
    return (
      <div className="client1-main-container">
        <Helmet>
          <title>Clientele | Maheshwari & Associates</title>
          {/* <link rel="canonical" href="https://www.architectm.com/clientele" /> */}
          <meta
            name="description"
            content="Maheshwari & Associates have builder clients in construction industry such as Unimark, Purti, Tata Housing, Primarc, Srijan, Magnolia, Merlin and PS Group."
          />
          <meta
            name="keywords"
            content="best architecture firms in mumbai,best architect firms in india, interior designer in mumbai,architects website,clientele,clients"
          />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="Maheshwari and Associates" />
          <meta property="og:url" content="https://www.architectm.com" />
          <meta
            property="og:description"
            content="Maheshwari & Associates is one of the best architecture firm having their offices in mumbai and Kolkata"
          ></meta>
        </Helmet>
        <Header />
        <div className="client1-container">
          <div className="clients ani-show" id="tab1">
            <Client2 clientSRC={c1} alt="Salarpuria Sattva" />
            <Client2 clientSRC={c2} alt="Soham Real Estate" />
            <Client2 clientSRC={c3} alt="Unimark" />
            <Client2 clientSRC={c4} alt="P S Group" />
            <Client2 clientSRC={c5} alt="Merlin Group" />
            <Client2 clientSRC={c6} alt="ICRA" />
            <Client2 clientSRC={c7} alt="Protech Housing" />
            <Client2 clientSRC={c8} alt="Pioneer Builders" />
            <Client2 clientSRC={c9} alt="Primarc" />
            <Client2 clientSRC={c10} alt="Srijan" />
            <Client2 clientSRC={c11} alt="Purti Realty" />
            <Client2 clientSRC={c12} alt="Magnolia Group" />
            <Client2 clientSRC={c13} alt="RC Rungta Group" />
            <Client2 clientSRC={c14} alt="Binani" />
            <Client2 clientSRC={c15} alt="Aster Group" />
            <Client2 clientSRC={c16} alt="90 degree" />
            <Client2 clientSRC={c17} alt="amaya-group" />
            <Client2 clientSRC={c18} alt="arch-group" />
            <Client2 clientSRC={c19} alt="asian-group" />
            <Client2 clientSRC={c20} alt="BBA Group" />
            <Client2 clientSRC={c21} alt="CP Group" />
            <Client2 clientSRC={c22} alt="Embee" />
            <Client2 clientSRC={c23} alt="Erudite-realty" />
            <Client2 clientSRC={c24} alt="isha-group" />
            <Client2 clientSRC={c25} alt="Jaivinayak-group" />
            <Client2 clientSRC={c26} alt="Magnolia Realty" />
            <Client2 clientSRC={c27} alt="N K Realtors" />
            <Client2 clientSRC={c28} alt="Shyam-steel" />
            <Client2 clientSRC={c29} alt="Tata Housing" />
            <Client2 clientSRC={c30} alt="tirumala-group1" />
          </div>
          {/* <div className="collab ani-show" id="tab2">
            <AboutUsTeam3 tag="“UNDER CONSTRUCTION”" />
          </div> */}
        </div>
        <div className="client-footer">
          <Footer />
        </div>
      </div>
    );
  }
}

export default Client1;
