import React, { Component } from "react";
import largethumb from "./largethumbnail.css";
class LargeThumbnail extends Component {
  state = {};
  render() {
    return (
      <img className="left-img ani-show5" src={this.props.largethumbnail} />
    );
  }
}

export default LargeThumbnail;
