import React, { Component } from "react";
import "./aboutuscomponent.css";
import AboutUsIntro from "../aboutusintro/AboutUsIntro";
import AboutUsTeam2 from "../aboutusTeam2/AboutUsTeam2";
import AboutUsTeam from "../aboutUsTeam/AboutUsTeam";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import $ from "jquery";
import RightMenuOptComponent from "../rightmenuoptcomponent/RightMenuOptionComponent";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { Spring } from "react-spring/renderprops";
let LI =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435305/Maheshwari/headerslide/Licon_ofjcu0.png";
let RI =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435305/Maheshwari/headerslide/Ricon_h8ibky.png";
let thumb1 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435305/Maheshwari/headerslide/dropicon_z1cvn8.png";
let thumb2 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435305/Maheshwari/headerslide/dropicon2_zqb7lq.png";
let thumb3 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435305/Maheshwari/headerslide/disLicon_cr1gvc.png";
let thumb4 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435305/Maheshwari/headerslide/disRicon_ksfk0v.png";

class AboutUsComponent extends Component {
  state = {
    RIcon: RI,
    LIcon: LI,
    imggSRC: [thumb1, thumb2, thumb3, thumb4],
  };
  componentDidMount() {
    // $("#subactive").addClass("activee");
    $(document).scrollTop(0);

    $("ul li").click(function () {
      $("li").removeClass("activee");
      $(this).addClass("activee");
      // $("#subactive").css("font-weight", "unset");
    });

    $(".js-content").css("display", "none");
    $(".js-content2").css("display", "none");
    $(".intro-sec").css("display", "block");
    $("#s-1").css("display", "inline-block");
    $(".drop-cont").css("display", "none");
    $(".currL").css("display", "none");
    $(".disR").css("display", "none");
    $(".mob-sublinks").css("display", "none");
    $(".sublinks").css("display", "none");
    $("#op1").css("display", "inline-block");
    $("#op1").css("font-weight", "bold");

    $(".rmenu-option1").css("color", "red");
    $("html").css("overflow-x", "hidden");
    $("body").css("overflow-x", "hidden");
  }
  // menutoggle(name) {
  //   $(".js-content").css("display", "none");
  //   $("." + name).css("display", "block");
  //   //$("." + name).css("color", "black");
  //   if (name === "team-sec1") {
  //     $(".sublinks").css("display", "block");
  //   } else {
  //     $(".sublinks").css("display", "none");
  //   }
  // }
  // next() {
  //   $(".drop-cont").css("display", "none");
  //   $(".sec-1").css("display", "none");
  //   $(".team-sec1").css("display", "block");
  //   $("#s-1").css("display", "none");
  //   $("#s-2").css("display", "inline-block");
  //   $(".mob-sublinks").css("display", "block");
  //   $(".js-content2").css("display", "none");
  //   $("#op1").css("display", "inline-block");
  //   $("#op1").css("font-weight", "bold");
  //   $(".currR").css("display", "none");
  //   $(".disR").css("display", "inline-block");
  //   $(".currL").css("display", "inline-block");
  //   $(".disL").css("display", "none");
  // }
  // prev() {
  //   $(".drop-cont").css("display", "none");
  //   $(".sec-2").css("display", "none");
  //   $(".sec-1").css("display", "block");
  //   $("#s-1").css("display", "inline-block");
  //   $("#s-2").css("display", "none");
  //   $(".mob-sublinks").css("display", "none");
  //   $(".team-sec1").css("display", "block");

  //   $(".currR").css("display", "inline-block");
  //   $(".disR").css("display", "none");
  //   $(".currL").css("display", "none");
  //   $(".disL").css("display", "inline-block");
  // }

  seeemore() {
    $(".drop-cont").toggle();
    $(".dropicon2").toggle();
    $(".dropicon1").toggle();
  }
  subtoggle(name) {
    $(".js-content2").css("display", "none");
    $("#" + name).css("display", "inline-block");

    $(".drop-cont").toggle();
    if (name === "op1") {
      $(".js-content").css("display", "none");
      $(".team-sec1").css("display", "block");
    }
    if (name === "op2") {
      $(".js-content").css("display", "none");
      $(".team-sec2").css("display", "block");
    }
  }

  render() {
    const renderFounder = () => {
      return <AboutUsTeam2 />;
    };
    const renderTeam = () => {
      return this.props.teams.map((item, index) => {
        if (item.teamname === this.props.activeTeam) {
          return (
            <div className="team-sec2 sec-2 ani-show3">
              <AboutUsTeam
                key={index}
                timage={item.timage}
                name={item.name}
                post={item.post}
              />
            </div>
          );
        }
      });
    };

    return (
      <Spring
        from={{ opacity: 0 }}
        to={{ opacity: 1 }}
        config={{ duration: 1000 }}
      >
        {(props) => (
          <div style={props}>
            <React.Fragment>
              <Helmet>
                <title>About Us | Maheshwari & Associates</title>
                {/* <link
                  rel="canonical"
                  href="https://www.architectm.com/aboutus"
                /> */}
                <meta
                  name="description"
                  content="Maheshwari & Associates, founded by Ar. Kamal Periwal is one of the top architecture firm in India. The team consist of architects and Interior Designers."
                />
                <meta
                  name="keywords"
                  content="Maheshwari and associates , Maheshwari and associates architects ,kamal periwal,architect mumbai"
                />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Maheshwari and Associates" />
                <meta property="og:url" content="https://www.architectm.com" />
                <meta
                  property="og:description"
                  content="Maheshwari & Associates is one of the best architecture firm having their offices in mumbai and Kolkata"
                ></meta>
              </Helmet>
              <div className="aboutuscomponent-container">
                <Header />

                <div className="options">
                  <img
                    className="Limage disL"
                    src={this.state.imggSRC[3]}
                    alt="Maheshwari"
                  />
                  <img
                    className="Limage currL"
                    src={this.state.LIcon}
                    onClick={() => this.props.prevsection()}
                    alt="Maheshwari"
                  />
                  <p id="s-1">Introduction</p>
                  <p id="s-2">Team</p>
                  <img
                    className="Rimage disR"
                    src={this.state.imggSRC[2]}
                    alt="Maheshwari"
                  />
                  <img
                    className="Rimage currR"
                    src={this.state.RIcon}
                    onClick={() => this.props.nextsection()}
                    alt="Maheshwari"
                  />
                </div>
                <div className="section group">
                  <div className="col left-section">
                    <ul>
                      <li className="rmenu-option1">
                        <RightMenuOptComponent
                          className="rmenu-option1"
                          opt_item="Introduction"
                          onClick={() => this.props.menutoggle("intro-sec")}
                        />
                      </li>

                      <li className="rmenu-option2">
                        <RightMenuOptComponent
                          className="rmenu-option2"
                          opt_item="Team"
                          onClick={() => this.props.menutoggle("team-sec1")}
                        />
                      </li>
                    </ul>
                  </div>

                  <div className="col right-section">
                    <div className="sublinks ani-show3">
                      <ul>
                        <li
                          className="activee"
                          onClick={() => this.props.changeFounder()}
                        >
                          Founder & CEO
                        </li>
                        <li onClick={() => this.props.changeTeam("AD")}>
                          Architectural Design
                        </li>
                        <li onClick={() => this.props.changeTeam("ID")}>
                          Interior Design
                        </li>
                        <li onClick={() => this.props.changeTeam("SS")}>
                          Site Supervision
                        </li>
                        <li onClick={() => this.props.changeTeam("ADMIN")}>
                          Administration
                        </li>
                      </ul>
                    </div>

                    <div className="mob-sublinks" onClick={this.seeemore}>
                      <div className="mob-drop-header">
                        <p className="js-content2" id="op1">
                          Founder & CEO
                        </p>
                        <p className="js-content2" id="op2">
                          Architectural Design
                        </p>
                        <p className="js-content2" id="op3">
                          Interior Design
                        </p>
                        <p className="js-content2" id="op4">
                          Site Supervision
                        </p>
                        <p className="js-content2" id="op5">
                          Administration
                        </p>
                      </div>
                      <div className="seeemore">
                        <div className="dropicon">
                          <div className="dropicon1">
                            <img src={this.state.imggSRC[0]} alt="Maheshwari" />
                          </div>
                          <div className="dropicon2">
                            <img src={this.state.imggSRC[1]} alt="Maheshwari" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="drop-cont">
                      <ul>
                        <li
                          className="activee"
                          // id="subactive"
                          onClick={() => this.props.changeFounder()}
                        >
                          Founder & CEO
                        </li>
                        <li onClick={() => this.props.changeTeam("AD")}>
                          Architectural Design
                        </li>
                        <li onClick={() => this.props.changeTeam("ID")}>
                          Interior Design
                        </li>
                        <li onClick={() => this.props.changeTeam("SS")}>
                          Site Supervision
                        </li>
                        <li onClick={() => this.props.changeTeam("ADMIN")}>
                          Administration
                        </li>
                      </ul>
                    </div>

                    <div className="about-us-sec">
                      <div className="intro-sec sec-1 js-content ani-show3">
                        <AboutUsIntro />
                      </div>

                      <div className="team-sec1 js-content ani-show3">
                        {(() => {
                          if (this.props.founder === true)
                            return <div>{renderFounder()}</div>;
                          else return <div>{renderTeam()}</div>;
                        })()}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="aboutus-footer">
                <Footer />
              </div>
            </React.Fragment>
          </div>
        )}
      </Spring>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    relatedProject: state.relatedProject,
    teams: state.teams,
    activeTeam: state.activeTeam,
    founder: state.founder,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    prevpage: (name) => dispatch({ type: "display_info", value: name }),
    changeTeam: (team) => dispatch({ type: "change_team", value: team }),
    changeFounder: () => dispatch({ type: "change_founder" }),
    menutoggle: (name) => dispatch({ type: "menutoggle", value: name }),
    nextsection: () => dispatch({ type: "nextsection" }),
    prevsection: () => dispatch({ type: "prevsection" }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AboutUsComponent);
