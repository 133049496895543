import React, { Component } from "react";
import "./blog.css";
import { Spring } from "react-spring/renderprops";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import BlogPost from "../blogpost/BlogPost";
import { Helmet } from "react-helmet";
class Blog extends Component {
  state = {};
  render() {
    const renderBlog = () => {
      return this.props.blog.map((item, index) => {
        return (
          <NavLink
            to={"/publication/" + item.link}
            onClick={() => this.props.setActiveBlog(item.link)}
            key={index + 25}
          >
            <BlogPost
              headerImg={item.headerImg}
              header={item.title}
              published={item.published}
              blogBy={item.blogBy}
              descrptn={item.sideDescription}
              tags={item.tags}
              date={item.date}
            />
          </NavLink>
        );
      });
    };
    return (
      <Spring
        from={{ opacity: 0 }}
        to={{ opacity: 1 }}
        config={{ duration: 1500 }}
      >
        {(props) => (
          <div style={props}>
            <div className="blog-container">
              <Helmet>
                <title> Blog | Maheshwari & Associates</title>
                {/* <link
                  rel="canonical"
                  href="https://www.architectm.com/publication"
                /> */}
                <meta
                  name="description"
                  content="Maheshwari & Associates led by Ar. Kamal Periwal is one of the best and famous architecture firm in India having their offices in Mumbai and Kolkata."
                />
                <meta
                  name="keywords"
                  content="Maheshwari and associates,best architects in mumbai, best architects in  Kolkata."
                />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Maheshwari and Associates" />
                <meta property="og:url" content="https://www.architectm.com" />
                <meta
                  property="og:description"
                  content="Maheshwari & Associates is one of the best architecture firm having their offices in mumbai and Kolkata"
                ></meta>
              </Helmet>
              <Header />
              <div className="blogContainer">{renderBlog()}</div>
              <Footer />
            </div>
          </div>
        )}
      </Spring>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    blog: state.blog,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setActiveBlog: (name) => dispatch({ type: "setActiveBlog", value: name }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Blog);
