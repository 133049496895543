import React, { Component } from "react";
import inputcss from "./inputboxcomponent.css";

class InputBoxComponent extends Component {
  state = {};
  render() {
    return (
      <div className="inputbox-container">
        <input
          type="text"
          placeholder={this.props.placeholder}
          value={this.props.value}
          pattern="[0-9]*"
          onInput={this.props.Change}
        />
      </div>
    );
  }
}

export default InputBoxComponent;
