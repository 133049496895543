import React, { Component } from "react";
import footercss from "./footer2.css";
import fb from "./images/fb.png";
import insta from "./images/insta.png";
import linkd from "./images/linkd.png";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";

class Footer2 extends Component {
  state = {
    imageSRC: [fb, insta, linkd],
  };
  render() {
    return (
      <div className="wrapperr2">
        <div className="navLinks">
          <NavLink activeClassName="activeRoute" to="/" exact>
            Projects
          </NavLink>
          <NavLink to="/aboutus" activeClassName="activeRoute">
            About Us
          </NavLink>
          <NavLink activeClassName="activeRoute" to="/achievements" exact>
            Achievements
          </NavLink>
          <NavLink activeClassName="activeRoute" to="/career" exact>
            Career
          </NavLink>
          <NavLink activeClassName="activeRoute" to="/clientele" exact>
            Clientele
          </NavLink>
          <NavLink activeClassName="activeRoute" to="/contact" exact>
            Contact Us
          </NavLink>
        </div>
        <div className="infoContainer">
          <p>Copyright &copy; 2019 Maheshwari Associates</p>
          <div className="place">Mumbai | Kolkata</div>
          <div className="tel">
            Tel: <a href="tel:02228764610">(022) 28764610</a>/{" "}
            <a href="tel:02226853691">26853691</a>
          </div>
          <div className="email">
            Email:{" "}
            <a href="mailto:info@architectm.com" target="_top">
              info@architectm.com
            </a>
          </div>
          <div className="social-logo">
            <a
              href="https://www.facebook.com/architectmdotcom/"
              target="_blank"
            >
              <img className="sm-logo" src={this.state.imageSRC[0]} />
            </a>
            <a
              href="https://www.instagram.com/architectm.com_/"
              target="_blank"
            >
              <img className="sm-logo" src={this.state.imageSRC[1]} />
            </a>
            <a
              href="https://www.linkedin.com/company/architectm/"
              target="_blank"
            >
              <img className="sm-logo" src={this.state.imageSRC[2]} />
            </a>
          </div>
        </div>
        <div className="linksContainer">
          <div className="projectLink">
            <div className="header">Projects</div>
            <div className="sectionContainer">
              <div className="left">
                <div className="text">
                  {" "}
                  <NavLink
                    to="/category/residential-housing-complex"
                    onClick={() =>
                      this.props.showProjects("residential-housing-complex")
                    }
                  >
                    Residential Housing
                  </NavLink>
                </div>
                <div className="text">
                  {" "}
                  <NavLink
                    to="/category/mixed"
                    onClick={() => this.props.showProjects("mixed")}
                  >
                    Mixed Use
                  </NavLink>
                </div>
                <div className="text">
                  {" "}
                  <NavLink
                    to="/category/residential-high-rise-tower"
                    onClick={() =>
                      this.props.showProjects("residential-high-rise-tower")
                    }
                  >
                    Residential Tower
                  </NavLink>
                </div>
                <div className="text">
                  {" "}
                  <NavLink
                    to="/category/cultural"
                    onClick={() => this.props.showProjects("cultural")}
                  >
                    Cultural
                  </NavLink>
                </div>
                <div className="text">
                  {" "}
                  <NavLink
                    to="/category/commercial-retail"
                    onClick={() => this.props.showProjects("commercial-retail")}
                  >
                    Commercial Retail
                  </NavLink>
                </div>
              </div>
              <div className="right">
                <div className="text">
                  {" "}
                  <NavLink
                    to="/category/interior-design"
                    onClick={() => this.props.showProjects("interior-design")}
                  >
                    Interior Design
                  </NavLink>
                </div>
                <div className="text">
                  {" "}
                  <NavLink
                    to="/category/residential-bunglow"
                    onClick={() =>
                      this.props.showProjects("residential-bunglow")
                    }
                  >
                    Residential Bunglow
                  </NavLink>
                </div>
                <div className="text">
                  {" "}
                  <NavLink
                    to="/category/institute"
                    onClick={() => this.props.showProjects("institute")}
                  >
                    Institute
                  </NavLink>
                </div>
                <div className="text">
                  {" "}
                  <NavLink
                    to="/category/commercial-office"
                    onClick={() => this.props.showProjects("commercial-office")}
                  >
                    Commercial Office
                  </NavLink>
                </div>
                <div className="text">
                  {" "}
                  <NavLink
                    to="/category/hospitality"
                    onClick={() => this.props.showProjects("hospitality")}
                  >
                    Hospitality
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
          <div className="aboutLink">
            <div className="header">About Us</div>
            <div className="text">
              {" "}
              <NavLink to="/aboutus">Introduction</NavLink>
            </div>
            <div className="text">
              {" "}
              <NavLink to="/aboutus">Team</NavLink>
            </div>
            <div className="text">
              {" "}
              <NavLink to="/faq">FAQ</NavLink>
            </div>
          </div>
          <div className="achievementLink">
            <div className="header">Achievements</div>
            <div className="text">
              {" "}
              <NavLink
                to="/achievements"
                onClick={() => this.props.menutoggle2("Achievements")}
              >
                Awards
              </NavLink>
            </div>
            <div className="text">
              {" "}
              <NavLink
                to="/achievements"
                onClick={() => this.props.menutoggle2("Seminar")}
              >
                Seminar
              </NavLink>
            </div>
          </div>
          <div className="careerLink">
            <div className="header">Career</div>
            <div className="text">
              {" "}
              <NavLink to="/career">Apply Here</NavLink>
            </div>
          </div>

          <div className="clientLink">
            <div className="header">Clientele</div>
            <div className="text">
              {" "}
              <NavLink to="/clientele">Clientele</NavLink>
            </div>
          </div>

          {/* <div className="contactLink">
            <div className="header">Contact Us</div>
            <div className="address">
              808/809, Corporate Annex, Sonawala Road, Goregaon (E) Mumbai –
              400065
              <br />
              Tel: <a href="tel:02228764610"> (022) 28764610</a>/
              <a href="tel:02226853691">26853691</a>
              <br />
              <br />
              <br />
              37A, Baker Road, Alipore,
              <br />
              Kolkata – 700027
              <br />
              Tel:<a href="tel:03365228584"> (033) 65228584</a>/{" "}
              <a href="tel:03324490323">24490323</a>
              <br />
              Email:{" "}
              <a href="mailto:info@architectm.com" target="_top">
                info@architectm.com
              </a>
            </div>
          </div> */}
          <div className="socialContainer">
            <a
              href="https://www.facebook.com/architectmdotcom/"
              target="_blank"
            >
              <div className="social-icons">
                <img src={fb} alt="" />
                {/* <p> architectmdotcom</p> */}
              </div>
            </a>
            <a
              href="https://www.instagram.com/architectm.com_/"
              target="_blank"
            >
              <div className="social-icons">
                <img src={insta} alt="" />
                {/* <p>architectm.com_</p> */}
              </div>
            </a>
            <a
              href="https://www.linkedin.com/company/architectm/"
              target="_blank"
            >
              <div className="social-icons">
                <img src={linkd} alt="" />
                {/* <p>architectm</p> */}
              </div>
            </a>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    projects: state.projects,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    menutoggle2: (pname) => dispatch({ type: "menutoggle2", value: pname }),
    showProjects: (name) => dispatch({ type: "display_Projects", value: name }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer2);
