import React, { Component } from "react";
import "./faq.css";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import $ from "jquery";
import { Helmet } from "react-helmet";
import Accordion from "../accordion/Accordion";
let meta = {
  "@context": "https://schema.org",
  "@type": "FAQPage",
  mainEntity: [
    {
      "@type": "Question",
      name: "What an architect does?",
      acceptedAnswer: {
        "@type": "Answer",
        text:
          "An architect is someone who translates ideas into a thriving environment, with knowledge, awareness and foresight. Architects invent, design and translate users’ needs into a built ecosystem.",
      },
    },
    {
      "@type": "Question",
      name: "What are the types of architect?",
      acceptedAnswer: {
        "@type": "Answer",
        text:
          "There are different kinds of architects, characterized by the design and specialized sectors. 1. Commercial Architect 2. Housing or Residential Architect 3. Sustainable Architect 4. Industrial Architect 5. Urban Planner 6. Landscape Architect",
      },
    },
    {
      "@type": "Question",
      name: "Who is the best architect in India?",
      acceptedAnswer: {
        "@type": "Answer",
        text:
          "Architects like Hafeez Contractor, Brinda Somaya, B V Joshi, Raj Rewal, Kamal Periwal and architecture firms like Morphogenesis by Manit Rastogi and Sonali Rastogi are paving the way for architectural demand and for the growing architects’ community in India.",
      },
    },
    {
      "@type": "Question",
      name: "What country has the best architects?",
      acceptedAnswer: {
        "@type": "Answer",
        text:
          "Countries like the United Arab Emirates, China, Singapore, USA and UK have been nurturing some of the best qualified and finest architects and architecture firms, who are changing the face of reality in today’s dynamic world.",
      },
    },
    {
      "@type": "Question",
      name: "What does an architect firm do?",
      acceptedAnswer: {
        "@type": "Answer",
        text:
          "An architect collaborates with various consultants such as structural, geotechnical, MEP, landscape, to manifest architectural ideas into reality. They deliver to individual clients as well as real estate developers to conceptualise and implement architectural designs for homes, offices, commercial and residential complexes etc.",
      },
    },
    {
      "@type": "Question",
      name: "What is a commercial architect?",
      acceptedAnswer: {
        "@type": "Answer",
        text:
          "A commercial architect is one that specializes in designing projects such as retail shops, offices, hotels, museums, hospitals, public buildings and other non-residential properties. These architects are experts in designing buildings that are functional and cater to multiple users.",
      },
    },
    {
      "@type": "Question",
      name: "How do I choose a commercial architect?",
      acceptedAnswer: {
        "@type": "Answer",
        text:
          "Apart from having excellent design skills, it is important to choose a commercial architect who understands commercial building codes, safety regulations and financial conditions.",
      },
    },
    {
      "@type": "Question",
      name: "Why is cultural architecture important?",
      acceptedAnswer: {
        "@type": "Answer",
        text:
          "History and heritage have a very important role in building a society’s cultural identity. Cultural architecture enables the expression of a nation’s heritage and its richness and allows the cultural expression in a tangible form.",
      },
    },
    {
      "@type": "Question",
      name: "What is the main role of an architect?",
      acceptedAnswer: {
        "@type": "Answer",
        text:
          "The main role of an architect is to convert requirements into functional buildings, be it homes, offices, public places or industrial ecosystems.",
      },
    },
    {
      "@type": "Question",
      name: "What is bungalow architect?",
      acceptedAnswer: {
        "@type": "Answer",
        text:
          "A bungalow architect is responsible for developing smart and functional bungalows for individuals and real estate players. From designing customized homes, to building residential complexes, these types of housing architects must have the creative skills to turn an idea into a living and functional space, mindful of the budgets.",
      },
    },
    {
      "@type": "Question",
      name: "What does a housing / residential architect do?",
      acceptedAnswer: {
        "@type": "Answer",
        text:
          "A residential architect or housing architect, however generalized, is responsible for developing functional homes for individuals and real estate players.",
      },
    },
    {
      "@type": "Question",
      name: "What do industrial architects do?",
      acceptedAnswer: {
        "@type": "Answer",
        text:
          "An industrial architect is one who can transform and integrate different processes, machinery, equipment and other requirements of a specific industry into a functional building design. The main goal of industrial architects is to be able to provide a space that can help simplify the operations of a business while providing an ergonomic space for users.",
      },
    },
    {
      "@type": "Question",
      name: "What is the best type of architect?",
      acceptedAnswer: {
        "@type": "Answer",
        text:
          "An architect who can visualise, communicate ideas using plans and 3D views, knows about building codes, collaborate with consultants, conveys ideas to the client are the best type of architects.",
      },
    },
    {
      "@type": "Question",
      name: "Is architecture a good career?",
      acceptedAnswer: {
        "@type": "Answer",
        text:
          "In the Indian context, architecture primarily established through historical and cultural influences, which modernized to fit the current needs of a rapidly expanding country. This directly affirms the importance of architects in India. With a growing disposable income and demand for living spaces, the scope for architects in India is increasing every day.",
      },
    },
    {
      "@type": "Question",
      name: "Who makes a good architect?",
      acceptedAnswer: {
        "@type": "Answer",
        text:
          "A good architect is someone who understands client needs, creatively manifests ideas and builds a functional environment, while treading effortlessly with high pressure situations, rules, regulations and budgets.",
      },
    },
    {
      "@type": "Question",
      name: "What does an interior design company do?",
      acceptedAnswer: {
        "@type": "Answer",
        text:
          "An interior design firm or interior design company is an entity which brings together interior designers to establish a practice which facilitates building enhancements and creation projects for individuals or real estate players.",
      },
    },
    {
      "@type": "Question",
      name: "What skills are needed for an interior designer?",
      acceptedAnswer: {
        "@type": "Answer",
        text:
          "Interior designers work closely with architects, civil engineers, mechanical engineers, and construction laborers and helpers to determine how interior spaces will look and function. They should be skilled to read, edit and draw blueprints, and must be aware of building codes, inspection regulations and other standard considerations..",
      },
    },
    {
      "@type": "Question",
      name: "What are the qualities of a good interior designer?",
      acceptedAnswer: {
        "@type": "Answer",
        text:
          "A good interior designer is someone who understands spaces and the use of decorative elements, colours, lighting and materials, essential in creating a wholesome space. Apart from this, it is also important for a good interior designer to be detail oriented, have efficient interpersonal skills and understand the technicalities of the architecture to deliver the best. Projects",
      },
    },
  ],
};

class FAQ extends Component {
  state = {};

  componentDidMount() {
    $(document).scrollTop(0);
  }
  render() {
    return (
      <div className="faq-container">
        <Helmet>
          <title> FAQ | Maheshwari & Associates</title>
          <script className="structured-data-list" type="application/ld+json">
            {JSON.stringify(meta)}
          </script>
        </Helmet>
        {/* <Helmet>
          <title>FAQ | Maheshwari & Associates</title>
          <meta
            name="description"
            content="Maheshwari & Associates have builder clients in construction industry such as Unimark, Purti, Tata Housing, Primarc, Srijan, Magnolia, Merlin and PS Group."
          />
          <meta
            name="keywords"
            content="best architecture firms in mumbai,best architect firms in india, interior designer in mumbai,architects website,clientele,clients"
          />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="Maheshwari and Associates" />
          <meta property="og:url" content="https://www.architectm.com" />
          <meta
            property="og:description"
            content="Maheshwari & Associates is one of the best architecture firm having their offices in mumbai and Kolkata"
          ></meta>
        </Helmet> */}
        <Header />
        <div className="content">
          <h1 className="faq-header">Architects</h1>
          <Accordion>
            <div label="What an architect does?">
              <p>
                An architect is someone who translates ideas into a thriving
                environment, with knowledge, awareness and foresight. Architects
                invent, design and translate users’ needs into a built
                ecosystem.
              </p>
            </div>
            <div label="What are the types of architect?">
              <p>
                There are different kinds of architects, characterized by the
                design and specialized sectors.
              </p>
              <ul style={{ marginLeft: "2vw" }}>
                <li>Commercial Architect</li>
                <li>Housing or Residential Architect</li>
                <li>Sustainable Architect</li>
                <li>Industrial Architect</li>
                <li>Urban Planner </li>
                <li>Landscape Architect</li>
              </ul>
            </div>
            <div label="Who is the best architect in India?">
              <p>
                Architects like Hafeez Contractor, Brinda Somaya, B V Joshi, Raj
                Rewal, Kamal Periwal and architecture firms like Morphogenesis
                by Manit Rastogi and Sonali Rastogi are paving the way for
                architectural demand and for the growing architects’ community
                in India.
              </p>
            </div>
            <div label="What country has the best architects?">
              <p>
                Countries like the United Arab Emirates, China, Singapore, USA
                and UK have been nurturing some of the best qualified and finest
                architects and architecture firms, who are changing the face of
                reality in today’s dynamic world.
              </p>
            </div>
            <div label="What does an architect firm do?">
              <p>
                An architect collaborates with various consultants such as
                structural, geotechnical, MEP, landscape, to manifest
                architectural ideas into reality. They deliver to individual
                clients as well as real estate developers to conceptualise and
                implement architectural designs for homes, offices, commercial
                and residential complexes etc.
              </p>
            </div>
            <div label="What is a commercial architect?">
              <p>
                A commercial architect is one that specializes in designing
                projects such as retail shops, offices, hotels, museums,
                hospitals, public buildings and other non-residential
                properties. These architects are experts in designing buildings
                that are functional and cater to multiple users.
              </p>
            </div>
            <div label="How do I choose a commercial architect?">
              <p>
                Apart from having excellent design skills, it is important to
                choose a commercial architect who understands commercial
                building codes, safety regulations and financial conditions.
              </p>
            </div>
            <div label="Why is cultural architecture important?">
              <p>
                History and heritage have a very important role in building a
                society’s cultural identity. Cultural architecture enables the
                expression of a nation’s heritage and its richness and allows
                the cultural expression in a tangible form.
              </p>
            </div>
            <div label="What is the main role of an architect?">
              <p>
                The main role of an architect is to convert requirements into
                functional buildings, be it homes, offices, public places or
                industrial ecosystems.
              </p>
            </div>
            <div label="What is bungalow architect? ">
              <p>
                A bungalow architect is responsible for developing smart and
                functional bungalows for individuals and real estate players.
                From designing customized homes, to building residential
                complexes, these types of housing architects must have the
                creative skills to turn an idea into a living and functional
                space, mindful of the budgets.
              </p>
            </div>
            <div label="What does a housing / residential architect do?">
              <p>
                A residential architect or housing architect, however
                generalized, is responsible for developing functional homes for
                individuals and real estate players.
              </p>
            </div>
            <div label="What do industrial architects do?">
              <p>
                An industrial architect is one who can transform and integrate
                different processes, machinery, equipment and other requirements
                of a specific industry into a functional building design. The
                main goal of industrial architects is to be able to provide a
                space that can help simplify the operations of a business while
                providing an ergonomic space for users.
              </p>
            </div>
            <div label="What is the best type of architect?">
              <p>
                An architect who can visualise, communicate ideas using plans
                and 3D views, knows about building codes, collaborate with
                consultants, conveys ideas to the client are the best type of
                architects.
              </p>
            </div>
            <div label="Is architecture a good career?">
              <p>
                In the Indian context, architecture primarily established
                through historical and cultural influences, which modernized to
                fit the current needs of a rapidly expanding country. This
                directly affirms the importance of architects in India. With a
                growing disposable income and demand for living spaces, the
                scope for architects in India is increasing every day.
              </p>
            </div>
            <div label="Who makes a good architect?">
              <p>
                A good architect is someone who understands client needs,
                creatively manifests ideas and builds a functional environment,
                while treading effortlessly with high pressure situations,
                rules, regulations and budgets.
              </p>
            </div>
          </Accordion>
          <h1 className="faq-header">Interior Designer</h1>
          <Accordion>
            <div label="What does an interior design company do?">
              <p>
                An architect is someone who translates ideas into a thriving
                environment, with knowledge, awareness and foresight. Architects
                invent, design and translate users’ needs into a built
                ecosystem.
              </p>
            </div>
            <div label="What skills are needed for an interior designer?">
              <p>
                Interior designers work closely with architects, civil
                engineers, mechanical engineers, and construction laborers and
                helpers to determine how interior spaces will look and function.
                They should be skilled to read, edit and draw blueprints, and
                must be aware of building codes, inspection regulations and
                other standard considerations.
              </p>
            </div>

            <div label="What are the qualities of a good interior designer?">
              <p>
                A good interior designer is someone who understands spaces and
                the use of decorative elements, colours, lighting and materials,
                essential in creating a wholesome space. Apart from this, it is
                also important for a good interior designer to be detail
                oriented, have efficient interpersonal skills and understand the
                technicalities of the architecture to deliver the best.
              </p>
            </div>
          </Accordion>
        </div>
        <div className="faq-footer">
          <Footer />
        </div>
      </div>
    );
  }
}

export default FAQ;
