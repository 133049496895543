import React, { Component } from "react";
import aboutusteam from "./aboutusthumbnail.css";
import { Spring } from "react-spring/renderprops";

class AboutUsThumbnail extends Component {
  state = {};
  render() {
    return (
      <Spring
        from={{ opacity: 0 }}
        to={{ opacity: 1, display: "inline-block" }}
        config={{ duration: 1500 }}
      >
        {(props) => (
          <div style={props}>
            <div className="thumb-main-container" onClick={this.props.onClick}>
              <div className="image-thumbnail-container">
                <img className="thumb-disp" src={this.props.thumb} />
              </div>
            </div>
          </div>
        )}
      </Spring>
    );
  }
}

export default AboutUsThumbnail;
