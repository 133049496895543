import React, { Component } from "react";
import headerslidecss from "./headerslide.css";
import { NavLink } from "react-router-dom";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import $ from "jquery";
import { connect } from "react-redux";
import queryString from "query-string";
import { BrowserRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import DeskSlider from "../deskSlider/DeskSlider";

let img1 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435305/Maheshwari/headerslide/1_nlmbi7.jpg";
let img2 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435305/Maheshwari/headerslide/2_iumspq.jpg";
let img3 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435306/Maheshwari/headerslide/3_dzxe9r.jpg";
let img4 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1564725078/Maheshwari/headerslide/Untitled-1_swvoea.jpg";
let img5 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435306/Maheshwari/headerslide/5_v7tpl9.jpg";
let img6 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435304/Maheshwari/headerslide/6_rphjzz.jpg";
let img7 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435304/Maheshwari/headerslide/7_lns88e.jpg";
let img8 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435305/Maheshwari/headerslide/8_kntjvi.jpg";
let img9 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435305/Maheshwari/headerslide/9_seyim4.jpg";
let img10 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563452887/Maheshwari/headerslide/61_gsup7z.jpg";
let LI =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435305/Maheshwari/headerslide/Licon_ofjcu0.png";
let RI =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435305/Maheshwari/headerslide/Ricon_h8ibky.png";
let thumb1 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435305/Maheshwari/headerslide/dropicon_z1cvn8.png";
let thumb2 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435305/Maheshwari/headerslide/dropicon2_zqb7lq.png";
let thumb3 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435305/Maheshwari/headerslide/disLicon_cr1gvc.png";
let thumb4 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435305/Maheshwari/headerslide/disRicon_ksfk0v.png";
let curr = "";
class HeaderSlide extends Component {
  state = {
    imgg1: img1,
    imgg2: img2,
    imgg3: img3,
    imgg4: img4,
    imgg5: img5,
    imgg6: img6,
    imgg7: img7,
    imgg8: img8,
    imgg9: img9,
    imgg10: img10,
    RIcon: RI,
    LIcon: LI,
    imggSRC: [thumb1, thumb2, thumb3, thumb4],
  };

  componentDidMount() {
    setTimeout(() => this.props.setLoading(), 2000);
    $("body").css("overflow-x", "hidden");
    $(".drop-cont-links").click(function () {
      // $(".drop-cont-links").css("color", "#a5a5a5");
      $(this).css("color", "black");
    });
    $("#optt0").css("display", "block");
    if (
      queryString.parse(this.props.location.search, {
        ignoreQueryPrefix: true,
      }).activeProject
    ) {
      this.props.showData(
        queryString.parse(this.props.location.search, {
          ignoreQueryPrefix: true,
        }).activeProject
      );
    }
    // this.props.history.push(
    //   "/?activeProject=" +
    //     this.props.activeProjectName +
    //     "&?category=" +
    //     this.props.activeCategory
    // );
    this.props.history.push("/");
  }

  updateState() {
    this.setState({ showProjects: false });
  }

  displaysection(sectionname) {
    $(".first-section").css("display", "none");
    $("." + curr + "-section").css("display", "none");
    curr = sectionname;
    $("." + curr + "-section").css("display", "block");
  }

  seeemore() {
    $(".drop-contt").toggle();
  }
  componentDidUpdate() {}
  render() {
    const renderGrids = () => {
      return (
        <div className="gallery ani-showw">
          <NavLink to="/category/residential-housing-complex">
            <div
              className="gallery__item--1"
              onClick={(e) =>
                this.props.showProjects("residential-housing-complex")
              }
            >
              <div className="captionn" onClick={this.props.onClick}>
                <div className="contennt">RESIDENTIAL: HOUSING COMPLEX</div>
              </div>

              <img
                src={this.state.imgg1}
                alt="RESIDENTIAL HOUSING COMPLEX"
                className="gallery__img"
              />
            </div>
          </NavLink>
          <NavLink to="/category/residential-bunglow">
            <div
              className="gallery__item--2"
              onClick={() => this.props.showProjects("residential-bunglow")}
            >
              <img
                src={this.state.imgg2}
                alt="RESIDENTIAL BUNGLOW"
                className="gallery__img"
              />
              <div className="captionn">
                <div className="contennt">RESIDENTIAL: BUNGLOW</div>
              </div>
            </div>
          </NavLink>

          <NavLink to="/category/commercial-office">
            <div
              className="gallery__item--3"
              onClick={() => this.props.showProjects("commercial-office")}
            >
              <div className="captionn">
                <div className="contennt">COMMERCIAL: OFFICE</div>
              </div>
              <img
                src={this.state.imgg3}
                alt="COMMERCIAL OFFICE"
                className="gallery__img"
              />
            </div>
          </NavLink>

          <NavLink to="/category/commercial-retail">
            <div
              className="gallery__item--4"
              onClick={(e) => this.props.showProjects("commercial-retail")}
            >
              <img
                src={this.state.imgg4}
                alt="COMMERCIAL RETAIL"
                className="gallery__img"
              />
              <div className="captionn">
                <div className="contennt">COMMERCIAL: RETAIL</div>
              </div>
            </div>
          </NavLink>

          <NavLink to="/category/residential-high-rise-tower">
            <div
              className="gallery__item--5"
              onClick={() =>
                this.props.showProjects("residential-high-rise-tower")
              }
            >
              <div className="captionn">
                <div className="contennt">RESIDENTIAL: HIGH RISE TOWER</div>
              </div>
              <img
                src={this.state.imgg5}
                alt="RESIDENTIAL-HIGH RISE TOWER"
                className="gallery__img"
              />
            </div>
          </NavLink>

          <NavLink to="/category/interior-design">
            <div
              className="gallery__item--6"
              onClick={() => this.props.showProjects("interior-design")}
            >
              <img
                src={this.state.imgg6}
                alt="INTERIOR DESIGNING PROJECT"
                className="gallery__img"
              />
              <div className="captionn">
                <div className="contennt">INTERIOR DESIGN</div>
              </div>
            </div>
          </NavLink>

          <NavLink to="/category/cultural">
            <div
              className="gallery__item--7"
              onClick={() => this.props.showProjects("cultural")}
            >
              <div className="captionn">
                <div className="contennt">CULTURAL</div>
              </div>
              <img
                src={this.state.imgg7}
                alt="CULTURAL"
                className="gallery__img"
              />
            </div>
          </NavLink>

          <NavLink to="/category/mixed">
            <div
              className="gallery__item--8"
              onClick={() => this.props.showProjects("mixed")}
            >
              <img
                src={this.state.imgg8}
                alt="MIXED USE"
                className="gallery__img"
              />
              <div className="captionn">
                <div className="contennt">MIXED USE</div>
              </div>
            </div>
          </NavLink>

          <NavLink to="/category/hospitality">
            <div
              className="gallery__item--7-1"
              onClick={() => this.props.showProjects("hospitality")}
            >
              <div className="captionn">
                <div className="contennt">HOSPITALITY</div>
              </div>
              <img
                src={this.state.imgg9}
                alt="HOSPITALITY"
                className="gallery__img"
              />
            </div>
          </NavLink>

          <NavLink to="/category/institute">
            <div
              className="gallery__item--10"
              onClick={() => this.props.showProjects("institute")}
            >
              <img
                src={this.state.imgg10}
                alt="INSTITUTE"
                className="gallery__img"
              />
              <div className="captionn">
                <div className="contennt">INSTITUTE</div>
              </div>
            </div>
          </NavLink>
        </div>
      );
    };

    const renderProjects = () => {
      return <BrowserRouter />;
    };

    // if ($(window).width() < 800) {

    return (
      <React.Fragment>
        <Helmet>
          <title>
            Leading Architecture Firm in India | Maheshwari & Associates
          </title>
          {/* <link rel="canonical" href="https://www.architectm.com" /> */}
          <meta
            name="description"
            content="Maheshwari & Associates led by Ar. Kamal Periwal is one of the best and famous architecture firm in India having their offices in Mumbai and Kolkata."
          />
          <meta
            name="keywords"
            content="Maheshwari and associates,best architects in mumbai, best architects in  Kolkata."
          />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="Maheshwari and Associates" />
          <meta property="og:url" content="https://www.architectm.com" />
          <meta
            property="og:description"
            content="Maheshwari & Associates is one of the best architecture firm having their offices in mumbai and Kolkata"
          ></meta>
          <script type="application/ld+json">
            {`{
                "@context": "http://www.schema.org",
                "@type": "WebSite",
                "name": "Maheshwari & Associates",
                "alternateName": "Maheshwari & Associates",
                "url": "https://www.architectm.com/"
              }`}
          </script>

          <script type="application/ld+json">
            {`{
                "@context": "http://www.schema.org",
                "@type": "HomeAndConstructionBusiness",
                "name": "Maheshwari & Associates",
                "url": "https://www.architectm.com/",
                "logo": "/static/media/logo.2dc7ae21.png",
                "image": "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563435305/Maheshwari/headerslide/1_nlmbi7.jpg",
                "description": "Maheshwari & Associates is one of the best architecture firm having their offices in mumbai and Kolkata",
                "address": {
                "@type": "PostalAddress",
                "streetAddress": "808/809 Corporate Annexe, Sonawala Rd, Goregaon",
                "addressLocality": "Mumbai",
                "addressRegion": "Maharashtra ",
                "postalCode": "400063",
                "addressCountry": "India"
                },
                "hasMap": "https://www.google.com/maps/place/Maheshwari+%26+Associates+Architects/@19.161442,72.85075,15z/data=!4m5!3m4!1s0x0:0x61e9f5b1388de7e7!8m2!3d19.161442!4d72.8507498?hl=en",
                "contactPoint": {
                  "@type": "ContactPoint",
                  "telephone": "(022) 28764610/ 26853691",
                  "contactType": "Tel:"
                }
              }`}
          </script>

          <script type="application/ld+json">
            {`{
                "@context": "http://www.schema.org",
                "@type": "product",
                "brand": "Maheshwari & Associates",
                "name": "Maheshwari & Associates",
                "image": "/static/media/logo.2dc7ae21.png",
                "description": "Maheshwari & Associates led by Ar. Kamal Periwal is one of the best and famous architecture firm in India having their offices in Mumbai and Kolkata.",
                "aggregateRating": {
                  "@type": "aggregateRating",
                  "ratingValue": "4.5",
                  "reviewCount": "74"
                }
              }`}
          </script>
        </Helmet>
        {/* {this.props.loading ? <Loading /> : <React.Fragment />} */}
        {/* <Loading /> */}
        <div className="top-container">
          <Header name={this.updateState} />
          {$(window).width() > 800 ? (
            <DeskSlider />
          ) : (
            <div className="container">
              {this.props.homeRender === "categories" ? (
                <div>{renderGrids()}</div>
              ) : (
                <div>{renderGrids()}</div>
              )}
            </div>
          )}
        </div>
        <div className="phooter">
          <Footer />
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    projects: state.projects,
    showProjectsdiv: state.showProjects,
    showProjects2: state.showProjects2,
    categories: state.categories,
    showSubCategories: state.showSubCategories,
    subcategories: state.subcategories,
    activeCategory: state.activeCategory,
    activeSubCategory: state.activeSubCategory,
    showCategories: state.showCategories,
    setProjectPageComp: state.setProjectPageComp,
    homeRender: state.homeRender,
    activeProjectName: state.activeProjectName,
    loading: state.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    stateChange: () => dispatch({ type: "change_state" }),
    stateChange2: () => dispatch({ type: "change_state2" }),
    showSubCategories: (name) =>
      dispatch({ type: "display_subCategories", value: name }),
    showProjects: (name) => dispatch({ type: "display_Projects", value: name }),
    showData: (pname) => dispatch({ type: "display_info", value: pname }),
    setLoading: () => dispatch({ type: "set_loading" }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderSlide);
