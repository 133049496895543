import React, { Component } from "react";
import aboutusteam from "./aboutusteam.css";
import Header from "../header/Header";
import dummy from "./images/dummy.jpg";

class AboutUsTeam extends Component {
  state = {};
  render() {
    return (
      <div className="team-main-container">
        <div className="team-container">
          {(() => {
            if (!this.props.timage) {
              return <img className="team-disp" src={dummy} />;
            } else {
              return <img className="team-disp" src={this.props.timage} />;
            }
          })()}

          <div className="team-info-holder">
            <div className="name">{this.props.name}</div>
            <div className="post">{this.props.post}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default AboutUsTeam;
