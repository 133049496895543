import React, { Component } from "react";
import videosSreencss from "./header.css";
import logo from "./image/logo.png";
import { NavLink } from "react-router-dom";

import c from "./image/close.png";
import i from "./image/insta.png";
import f from "./image/facebook.png";
import l from "./image/linkdin.png";
import $ from "jquery";

import { connect } from "react-redux";
var dropmenuopen = "close";
class Header extends Component {
  state = {
    imageSRC: logo,
    close: c,
    fb: f,
    insta: i,
    linkd: l,
  };

  constructor(props) {
    super(props);
  }
  componentDidMount() {
    $(".header-Container").css("border", "0.5px solid black");
    this.CloseDropmenuToggle();
  }

  OpenDropmenuToggle() {
    //alert("overflow: hidden");
    $(".header-icon").css("display", "none");
    $(".close-logo").css("display", "none");
    $(".close-text").css("display", "block");
    $(".header-nav").css("display", "block");
    $(".social-icon").css("display", "block");
    $(".header-Container").css("border", "none");
    $("html").css("overflow", "hidden");
    $("body").css("overflow", "hidden");
    $(".companyName").css("display", "none");
    dropmenuopen = "open";
    $(".main-container").css("height", "100vh");
  }

  CloseDropmenuToggle() {
    if (window.matchMedia("(max-width: 800px)").matches) {
      $(".header-icon").css("display", "block");
      $(".close-logo").css("display", "block");
      $(".close-text").css("display", "none");
      $(".header-nav").css("display", "none");
      $(".social-icon").css("display", "none");
      $(".header-Container").css("border", "0.5px solid black");
      $("html").css("overflow", "scroll");
      $("body").css("overflow", "scroll");
      $(".main-container").css("height", "auto");
      $(".companyName").css("display", "block");
    }
    //alert("overflow: scroll");
  }

  header() {
    $(".first-section").css("display", "block");
    $(".hide").css("display", "none");
  }

  render() {
    return (
      <div className="main-container">
        <div className="header-Container">
          <NavLink to="/" activeClassName="activeRoute" exact>
            <div className="header-icon">
              <img className="hlogo" src={this.state.imageSRC} alt="" />
            </div>
          </NavLink>
          <div className="close-logo" onClick={this.OpenDropmenuToggle}>
            <img className="logo" src={this.state.close} />
          </div>

          <div className="close-text" onClick={this.CloseDropmenuToggle}>
            CLOSE
          </div>
          <NavLink to="/" activeClassName="activeRoute" exact>
            <div className="header-logo">
              <img className="logo" src={this.state.imageSRC} />
            </div>
          </NavLink>
          <div className="companyName">Maheshwari & Associates</div>
        </div>
        <div className="header-nav">
          <div className="link-sec">
            <NavLink activeClassName="activeRoute" to="/" exact>
              Projects
            </NavLink>
            <NavLink to="/aboutus" activeClassName="activeRoute">
              About Us
            </NavLink>
            <NavLink activeClassName="activeRoute" to="/publication" exact>
              Publications
            </NavLink>
            <NavLink activeClassName="activeRoute" to="/achievements" exact>
              Achievements
            </NavLink>
            <NavLink activeClassName="activeRoute" to="/career" exact>
              Career
            </NavLink>
            <NavLink activeClassName="activeRoute" to="/clientele" exact>
              Clientele
            </NavLink>
            <NavLink activeClassName="activeRoute" to="/faq" exact>
              FAQ
            </NavLink>
            <NavLink activeClassName="activeRoute" to="/contact" exact>
              Contact Us
            </NavLink>
          </div>
        </div>
        <div className="social-icon">
          <div className="social-container">
            <a href="https://www.facebook.com/architectmdotcom/">
              <img className="social-logo fleft" src={this.state.fb} />
            </a>
            <a href="https://www.instagram.com/architectm.com_/">
              <img className="social-logo" src={this.state.insta} />
            </a>
            <a href="https://www.linkedin.com/company/architectm/">
              <img className="social-logo fright" src={this.state.linkd} />
            </a>
          </div>
        </div>
      </div>
    );
  }
}

// const mapStateToProps = state => {
//   return {
//     projects: state.projects
//   };
// };

const mapDispatchToProps = (dispatch) => {
  return {
    stateChange: () => dispatch({ type: "header_click" }),
    onboard: (name) => dispatch({ type: "onboard_project", value: name }),
  };
};

export default connect(null, mapDispatchToProps)(Header);
