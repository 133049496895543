import React, { Component } from "react";
import aboutusintrocss from "./aboutusintro.css";
let pic1 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1564741769/Maheshwari/1_hf0ckp.jpg";
let pic2 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1564742471/Maheshwari/20190330_183007_gkhfir.jpg";
class AboutUsIntro extends Component {
  state = {};
  render() {
    return (
      <div className="introo-container">
        <div className="para1">
          {/* <img src={pic1} alt="best architecture firms in mumbai and Kolkata" /> */}
          <p className="title">THE FIRM</p>
          <p className="desc">
            Maheshwari & Associates (M&A) was founded by Ar. Kamal Periwal in
            the mid- 90’s in Mumbai (then called Bombay), India. In couple
            years, Kolkata became the main hub for the company’s projects. Over
            the years, Eastern India has become the core nucleus for primarily
            projects in Kolkata, Siliguri & Guwahati. Along with eastern India,
            Mumbai, Jaipur and few other locations in India where the firm’s
            architectural projects have come up.
          </p>
          <p className="desc">
            M&A has design studios in Mumbai and Kolkata with over 50+
            experienced architects, interior designers, site engineers, 3D
            visualizers and other allied staff. M&A has sound experience in
            delivering master planning projects, luxurious bungalows,
            large-scale residential housing, commercial and retail projects of
            varying sizes.
          </p>
          <p className="desc">
            M&A believes in creation of brands through design that people seek
            out and remember. Some of M&A designs which have been iconic and
            trend setter; Unimark Asian, the building with long cantilevered
            staggered floor plates, ‘The million square feet mall of Guwahati -
            The Grand Mall’, Altius Tower, a luxury high-rise residential tower
            at Kolkata, has the potential to bring about changes in the socio
            economic landscape of the region.
          </p>
          <p className="desc">
            Few exciting projects in the pipeline are 10 luxurious bungalows in
            the range of 15,000 to 50,000 sqft BUA in Mumbai, Jaipur, Kolkata,
            Raichak and Siliguri. Large-scale residential projects in the
            outskirts of Kolkata; Gopalpur built-up area of 13.5 lac sqft, and
            Kawakari built-up area 5 lac sqft. Trump Tower coming near the
            Science City area of Kolkata is going to be another one of our
            projects which shapes the skyline of Kolkata. Commercial projects in
            the pipeline are Shyam Steel head-office and Austin Tower.
          </p>
        </div>

        <div className="para2">
          {/* <img src={pic2} alt="top architects in kolkata and Mumbai" /> */}
          <p className="title">PHILOSOPHY</p>
          <p className="desc">
            The philosophy that forms the basis of every design is Creation of
            brand through designs that people seek out & remember. Our firm
            believes in providing value addition into the projects, these
            additions is what makes our projects stand out in the architectural
            skylines:
            <br />
            <br />
            <ul>
              <li>
                Create spaces that emotionally charge you up for a progressive
                thinking.
              </li>
              <li>
                Maximizing the sellable space and also the, sell ability of
                spaces through design.
              </li>
              <li>
                Creating spaces with contemporary and modern features however
                being Vastu compliant.
              </li>
              <li>
                Maximizing the efficiency of space including spatial linkages,
                comfort and flow of people, goods and services.
              </li>
              <li>
                Generating design that respects the thought and need of the
                clients, the user group, the future needs, the contextual needs,
                the environment needs.
              </li>
            </ul>
          </p>
          <p className="desc">
            M&A wouldn’t be one of the leading, creativity driven practices it
            is had it not been for our valuable team. Every member of staff
            brings a unique viewpoint that provides innovative ideas when put
            together, listened to, and challenged. We push each other to the
            best we can be, to develop new skills, share knowledge and celebrate
            successes.
          </p>
        </div>

        <div className="para2">
          <p className="title">VISION</p>
          <p className="desc">
            Our vision is not only to be one of the leading design studios, but
            to build a team who are motivated to provide unique value in each
            project. Our vision is dominated by:
            <br />
            <br />
            <ul>
              <li>To be governed by our values and aspirations.</li>
              <li>
                To achieve the architectural objectives which have been agreed
                at the outset of each stage of work.
              </li>
              <li>To be a financially stable business with growing profits.</li>
              <li>To be a platform for innovation and challenge.</li>
              <li>To increase our portfolio of high calibre repeat clients.</li>
              <li>To achieve high quality delivery of what we promise.</li>
              <li>To attract and develop high quality staff.</li>
              <li>To communicate the values and aspirations of the practice</li>
            </ul>
          </p>
        </div>
      </div>
    );
  }
}

export default AboutUsIntro;
