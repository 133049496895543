import React, { Component } from "react";
import noresultcss from "./noresult.css";

class NoResult extends Component {
  state = {};
  render() {
    return (
      <div className="noResult ani-showw">
        Generating Something More.
        <br />
        Stay Tuned.
      </div>
    );
  }
}

export default NoResult;
