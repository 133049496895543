import $ from "jquery";
import ContacttUsComponent from "../contactuscomponent/ContactUsComponent";
import { directive } from "@babel/types";

export function submit(data) {
  //validation

  const Fname = data.Fname;
  const Lname = data.Lname;
  const email = data.EmailID;
  const number = data.MobNumber;
  const message = data.Message;
  //alert("Data -> " + data.Fname);
  // const Lname = $("#Lname").val();
  // const email = $("#EmailID").val();
  // const number = $("#MobNumber").val();
  // const message = $("#Message").val();
  if (Fname.length == 0) {
    alert("Please Enter Your Name");
    return;
  }
  if (Lname.length == 0) {
    alert("Please Enter Your Last Name");
    return;
  }
  //email = data.EmailID;
  if (email.length == 0) {
    alert("Please enter valid email address");
    return;
  }
  if (validateEmail(email)) {
  } else {
    alert("Invalid Email Address");
    return;
  }
  if (number.length == 0) {
    alert("Please Enter Your Number");
    return;
  }
  if (message.length == 0) {
    alert("Please Enter Your Message");
    return;
  }
  $("#disable").attr("disabled", true);
  // enq = "";

  $.ajax({
    url: "/script.php",
    type: "POST",
    data: {
      Fname: Fname,
      Lname: Lname,
      email: email,
      number: number,
      message: message
    },
    success: function(res) {
      // $(".subbtnb").attr("onclick", "submit();");
      // $("#disable").css("background-color", "red");
      // //alert(res);
      $("#disable").attr("disabled", false);
      // $("#Fname").val("");
      // $("#Lname").val("");
      // $("#EmailID").val("");
      // $("#MobNumber").val("");
      // $("#Message").val("");

      $(".inputbox-container")
        .find("input[type=text]")
        .val("");
      $(".contact-form")
        .find("input[type=text], textarea")
        .val("");
      alert("Thank you for contact us. We will revert back soon");
    },
    error: function(e) {
      // $(".subbtnb").attr("onclick", "submit();");
      // $("#disable").css("background-color", " red");
      $("#disable").attr("disabled", false);

      alert("Error Occurred");
    }
  });
}

// $(document).ready(function() {
//   $("#MobNumber").keypress(function(e) {
//     if (e.which != 8 && e.which != 0 && (e.which < 48 || e.which > 57)) {
//       alert("Please Enter Valid Number");
//       return false;
//     }
//     return;
//   });
// });
function validateEmail(email) {
  var filter = /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
  if (filter.test(email)) {
    return true;
  } else {
    return false;
  }
}
