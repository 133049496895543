import React, { Component } from "react";
import "./blogpage.css";
import { Spring } from "react-spring/renderprops";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import $ from "jquery";
import { FacebookShareButton } from "react-share";
import fb from "../images/fb.png";
import { connect } from "react-redux";
import InstagramEmbed from "react-instagram-embed";
import { NavLink } from "react-router-dom";
import { createBrowserHistory } from "history";
import { Helmet } from "react-helmet";
const history = createBrowserHistory();

class BlogPage extends Component {
  state = {
    desc:
      "The client’s brief was to design a contemporary Hotel on the lines of the ancient culture of the Vedic age, with incorporation of local elements. The Hotel must register its uniqueness and its ambience must be welcoming and warm not making it a one-time affair.",
  };
  componentDidMount() {
    $(document).scrollTop(0);
    this.props.blogName();
    this.props.updateBlogLink(this.props.activeBlogNamee);
    $(".link-sec a:nth-child(3)").addClass("activeRoute");
  }
  componentDidUpdate() {
    // alert(this.props.activeBlog); $(document).scrollTop(0);
    $(document).scrollTop(0);
    this.props.blogName();
    history.push("/publication/" + this.props.activeBlog);
  }
  render() {
    const renderRelatedBlog = () => {
      return this.props.blog.map((item, index) => {
        if (item.link === this.props.activeBlog) {
          return item.relatedBlog.map((relatedBlog) => {
            return this.props.blog.map((item2) => {
              if (relatedBlog.link === item2.link) {
                return (
                  <NavLink
                    to={"/publication/" + relatedBlog.link}
                    onClick={() => this.props.setActiveBlog(relatedBlog.link)}
                  >
                    <div className="related-blogs">
                      <div className="posts">
                        <div className="header">{item2.title}</div>
                        <div className="author">
                          {item2.blogBy ? "Blog By" : "Published By"}{" "}
                          {item2.blogBy ? item2.blogBy : item2.published}
                        </div>
                        <div className="description">
                          {item.sideDescription.map((itemm, index) => {
                            if (index === 0) {
                              return itemm.substring(0, 100);
                            }
                          })}
                          ...
                        </div>
                      </div>
                    </div>
                  </NavLink>
                );
              }
            });
          });
        }
      });
    };
    return (
      <Spring
        from={{ opacity: 0 }}
        to={{ opacity: 1 }}
        config={{ duration: 1500 }}
      >
        {(props) => (
          <div style={props}>
            <div className="blog-container">
              {this.props.blog.map((item, index) => {
                if (item.link === this.props.activeBlog) {
                  return (
                    <Helmet>
                      <title> Blog | Maheshwari & Associates</title>
                      <script
                        className="structured-data-list"
                        type="application/ld+json"
                      >
                        {JSON.stringify(item.meta)}
                      </script>
                    </Helmet>
                  );
                }
              })}
              {/*  */}

              <Header />
              <div className="blogPageContainer">
                {this.props.blog.map((item, index) => {
                  if (item.link === this.props.activeBlog) {
                    return (
                      <div className="leftSection">
                        {/* <div className="blog-header-image">
                          <img src={item.headerImg} alt="" />
                        </div> */}
                        <div className="blog-header">{item.title}</div>
                        <div className="creatorInfo">
                          {item.published && (
                            <div className="publishedIn">
                              <p className="title">Published in:</p>
                              <p className="contentt">{item.published}</p>
                            </div>
                          )}
                          {item.blogBy && (
                            <div className="blogBy">
                              <p className="title">Blog By:</p>
                              <p className="contentt">{item.blogBy}</p>
                            </div>
                          )}
                          {item.date && (
                            <div className="date">
                              <p className="title">Date:</p>
                              <p className="contentt">{item.date}</p>
                            </div>
                          )}
                        </div>
                        <div className="info-inline">
                          {item.projectInfo &&
                            item.projectInfo.map((info) => {
                              return (
                                <div className="blog-project-info">
                                  <div className="project-info-container">
                                    <p className="titlee">{info.projectName}</p>
                                  </div>
                                  <div className="project-info-container">
                                    <p className="titlee">Location: </p>
                                    <p className="descc">
                                      {info.projectLocation}
                                    </p>
                                  </div>
                                  <div className="project-info-container">
                                    <p className="titlee">Project Status: </p>
                                    <p className="descc">
                                      {info.projectStatus}
                                    </p>
                                  </div>
                                  {info.projectInitiation && (
                                    <div className="project-info-container">
                                      <p className="titlee">
                                        Project Initiation:
                                      </p>
                                      <p className="descc">
                                        {info.projectInitiation}
                                      </p>
                                    </div>
                                  )}
                                  {info.projectCompletion && (
                                    <div className="project-info-container">
                                      <p className="titlee">
                                        Project Completion:
                                      </p>
                                      <p className="descc">
                                        {info.projectCompletion}
                                      </p>
                                    </div>
                                  )}
                                  {info.clientName && (
                                    <div className="project-info-container">
                                      <p className="titlee">Client Name:</p>
                                      <p className="descc">{info.clientName}</p>
                                    </div>
                                  )}
                                  {info.siteArea && (
                                    <div className="project-info-container">
                                      <p className="titlee">Site Area:</p>
                                      <p className="descc">{info.siteArea}</p>
                                    </div>
                                  )}
                                  {info.projectArea && (
                                    <div className="project-info-container">
                                      <p className="titlee">Project Area:</p>
                                      <p className="descc">
                                        {info.projectArea}
                                      </p>
                                    </div>
                                  )}
                                  {info.desginTeam && (
                                    <div className="project-info-container">
                                      <p className="titlee">Design Team:</p>
                                      <p className="descc">{info.desginTeam}</p>
                                    </div>
                                  )}
                                  {info.type && (
                                    <div className="project-info-container">
                                      <p className="titlee">Type:</p>
                                      <p className="descc">{info.type}</p>
                                    </div>
                                  )}
                                </div>
                              );
                            })}
                          {item.projectInfo && (
                            <div className="desc1">{item.sideDescription}</div>
                          )}
                        </div>
                        <div className="blog-description">
                          {item.description1.map((desc1) => {
                            return <p>{desc1}</p>;
                          })}
                        </div>

                        {item.img2 && item.img3 ? (
                          <div className="blog-image-with-description">
                            <div className="blog-image">
                              <img
                                src={item.img2}
                                alt="blog"
                                className="image1"
                              />
                              <img
                                src={item.img3}
                                alt="blog"
                                className="image2"
                              />
                            </div>
                            <div className="blog-desc">
                              {item.description2 && (
                                <div className="blog-description">
                                  {item.description2.map((desc2) => {
                                    return <p>{desc2}</p>;
                                  })}
                                </div>
                              )}
                            </div>
                          </div>
                        ) : (
                          ""
                        )}

                        {item.img2 && !item.img3 ? (
                          <div className="single-image-with-description">
                            <div className="blog-image">
                              <img
                                src={item.img2}
                                alt="blog"
                                className="image1"
                              />
                            </div>
                            <div className="blog-desc">
                              {item.description2 && (
                                <div className="blog-description">
                                  {item.description2.map((desc2) => {
                                    return <p>{desc2}</p>;
                                  })}
                                </div>
                              )}
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {/* {item.description2 && (
                          <div className="blog-description">
                            {item.description2.map(desc2 => {
                              return <p>{desc2}</p>;
                            })}
                          </div>
                        )} */}
                        {item.description3 && (
                          <div className="blog-description">
                            {item.description3.map((desc3) => {
                              return <p>{desc3}</p>;
                            })}
                          </div>
                        )}
                        {item.img4 && (
                          <div className="blog-image2">
                            <img src={item.img4} alt="" />
                          </div>
                        )}

                        {/* more info about a blog should be added below this */}

                        {item.otherInfo &&
                          item.otherInfo.map((otherInfo, index) => {
                            return (
                              <div className="otherInfo">
                                <div className="other-header">
                                  {otherInfo.title}
                                </div>
                                {otherInfo.image1 && (
                                  <div className="other-project-header-image">
                                    <img
                                      src={otherInfo.image1}
                                      alt=""
                                      className=""
                                    />
                                  </div>
                                )}

                                {otherInfo.description1 && (
                                  <div className="blog-description">
                                    {otherInfo.description1.map((desc1) => {
                                      return <p>{desc1}</p>;
                                    })}
                                  </div>
                                )}
                                {otherInfo.image2 && otherInfo.description2 ? (
                                  <div className="single-image-with-description">
                                    <div className="blog-image">
                                      <img
                                        src={otherInfo.image2}
                                        alt="blog"
                                        className="image1"
                                      />
                                    </div>
                                    <div className="blog-desc">
                                      {otherInfo.description2 && (
                                        <div className="blog-description">
                                          {otherInfo.description2.map(
                                            (desc2) => {
                                              return <p>{desc2}</p>;
                                            }
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                                {otherInfo.image2 && !otherInfo.description2 && (
                                  <div className="other-project-header-image2">
                                    <img
                                      src={otherInfo.image2}
                                      alt=""
                                      className=""
                                    />
                                  </div>
                                )}

                                {otherInfo.description3 && (
                                  <div className="blog-description">
                                    {otherInfo.description3.map((desc3) => {
                                      return <p>{desc3}</p>;
                                    })}
                                  </div>
                                )}
                                <img
                                  src={otherInfo.tableImg}
                                  alt=""
                                  className="other-info-table"
                                />
                                {otherInfo.megazineCover && (
                                  <div className="megazineContainer">
                                    <img
                                      src={otherInfo.megazineCover}
                                      alt="Megazine"
                                    />
                                  </div>
                                )}
                              </div>
                            );
                          })}
                        <div className="tags-and-share">
                          <div className="publishedIn">
                            <p className="title">Tags:</p>
                            <p className="contentt italic">
                              {item.tags.map((itemm) => {
                                return (
                                  <NavLink to={itemm.link}>{itemm.tag}</NavLink>
                                );
                              })}
                            </p>
                          </div>
                          <div className="fb-share">
                            <p>Share :</p>
                            <FacebookShareButton
                              className="icon"
                              children=""
                              quote={this.props.quote}
                              url={
                                "https://architectm.com/publication/" +
                                this.props.activeBlog
                              }
                            >
                              <img className="fb-icon" src={fb} />
                            </FacebookShareButton>
                          </div>
                        </div>
                        <div className="underline"></div>
                        <div className="mob-fb-share">
                          <p>Share :</p>
                          <FacebookShareButton
                            className="icon"
                            children=""
                            quote={this.props.quote}
                            url={
                              "https://architectm.com/publication/" +
                              this.props.activeBlog
                            }
                          >
                            <img className="fb-icon" src={fb} />
                          </FacebookShareButton>
                        </div>
                        <div className="blog-navigation">
                          <div
                            className="prev-blog"
                            onClick={() => this.props.prevBlog()}
                          >
                            <div className="previous-text">PREVIOUS</div>
                            <div className="previous-blog-name">
                              {this.props.prevBlogName
                                .replace(/-/g, " ")
                                .toUpperCase()}
                            </div>
                          </div>
                          <div
                            className="next-blog"
                            onClick={() => this.props.nextBlog()}
                          >
                            <div className="next-text">NEXT</div>
                            <div className="next-blog-name">
                              {this.props.nextBlogName
                                .replace(/-/g, " ")
                                .toUpperCase()}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }
                })}
                {this.props.blog.map((item, index) => {
                  if (item.link === this.props.activeBlog) {
                    return (
                      <div className="rightSection">
                        {item.relatedPost && (
                          <div className="related-post">
                            <div className="related-header">Related Post</div>
                            {item.relatedPost.map((insta) => {
                              return (
                                <div className="insta-post">
                                  <InstagramEmbed
                                    url={insta}
                                    maxWidth="100%"
                                    hideCaption={true}
                                    containerTagName="div"
                                    protocol=""
                                    injectScript
                                    onLoading={() => {}}
                                    onSuccess={() => {}}
                                    onAfterRender={() => {}}
                                    onFailure={() => {}}
                                  />
                                </div>
                              );
                            })}
                          </div>
                        )}
                        <div className="related-project">
                          <div className="related-header">Related Projects</div>
                          <div className="related-projects">
                            <ul>
                              {item.relatedProjects.map((projects) => {
                                return (
                                  <NavLink to={projects.link}>
                                    <li>{projects.name}</li>
                                  </NavLink>
                                );
                              })}
                            </ul>
                          </div>
                        </div>
                        {item.relatedBlog && (
                          <div className="related-blog">
                            <div className="related-header">Related Blog</div>
                            <div className="render-related-blog">
                              {renderRelatedBlog()}
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  }
                })}
              </div>
              <Footer />
            </div>
          </div>
        )}
      </Spring>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    blog: state.blog,
    activeBlog: state.activeBlog,
    nextBlogName: state.nextBlogName,
    prevBlogName: state.prevBlogName,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    blogName: () => dispatch({ type: "testArray" }),
    nextBlog: (name) => dispatch({ type: "nextBlog", value: name }),
    prevBlog: (name) => dispatch({ type: "prevBlog", value: name }),
    updateBlogLink: (name) =>
      dispatch(dispatch({ type: "setActiveBlog", value: name })),
    setActiveBlog: (name) => dispatch({ type: "setActiveBlog", value: name }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BlogPage);
// export default withRouter(
//   connect(mapStateToProps, mapDispatchToProps)(BlogPage)
// );
