import React, { Component } from "react";
import "./blogpost.css";
import blog1 from "../images/blog1.png";
class BlogPost extends Component {
  state = {
    desc:
      "The client’s brief was to design a contemporary Hotel on the lines of the ancient culture of the Vedic age, with incorporation of local elements. The Hotel must register its uniqueness and its ambience must be welcoming and warm not making it a one-time affair.",
  };
  render() {
    const renderDesc = () => {
      return;
    };
    return (
      <div className="blogPostSection">
        <img src={this.props.headerImg} alt="" />
        <div className="header">{this.props.header}</div>
        <div className="creatorInfo">
          {this.props.published && (
            <div className="publishedIn">
              <p className="title">Published in:</p>
              <p className="contentt">{this.props.published}</p>
            </div>
          )}
          {this.props.blogBy && (
            <div className="blogBy">
              <p className="title">Blog By :</p>
              <p className="contentt">{this.props.blogBy}</p>
            </div>
          )}
          {this.props.date && (
            <div className="date">
              <p className="title">Date :</p>
              <p className="contentt">{this.props.date}</p>
            </div>
          )}
        </div>

        <div className="description">
          {console.log(this.props.descrptn)}
          {this.props.descrptn.map((item, index) => {
            if (index === 0) {
              return item.substring(0, 120);
            }
          })}
          ...
        </div>
        <div className="tags">
          <p className="title">Tags:</p>
          <p className="tag-content">
            {this.props.tags.map((item) => {
              return item.tag;
            })}
          </p>
        </div>
      </div>
    );
  }
}

export default BlogPost;
