import React, { Component } from "react";
import "./achievements.css";
import $ from "jquery";
// import next from "../images/next.png";
import { connect } from "react-redux";
// import prev from "../images/prev.png";
let next =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1565272850/Maheshwari/next_ka35oq.png";
let prev =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1565272883/Maheshwari/back_e2gais.png";

let currSlide = 1;
class Achievements extends Component {
  state = {};

  moveLeft = () => {
    if ($(window).width() < 800) {
      if (currSlide === 1) {
        $("#slider ul").css(
          "left",
          "-=" + (this.props.slideCount - 1) * 67.34375 + "vw"
        );
        currSlide = this.props.slideCount;
      } else if (currSlide === this.props.slideCount) {
        $("#slider ul").css("left", "+=67.34375vw");
        currSlide = currSlide - 1;
      } else if (currSlide <= this.props.slideCount) {
        $("#slider ul").css("left", "+=67.34375vw");
        currSlide = currSlide - 1;
      }
    } else {
      if (currSlide === 1) {
        $("#slider ul").css(
          "left",
          "-=" + (this.props.slideCount - 1) * 525 * (100 / 1920) + "vw"
        );
        currSlide = this.props.slideCount;
      } else if (currSlide === this.props.slideCount) {
        $("#slider ul").css("left", "+=" + 525 * (100 / 1920) + "vw");
        currSlide = currSlide - 1;
      } else if (currSlide <= this.props.slideCount) {
        $("#slider ul").css("left", "+=" + 525 * (100 / 1920) + "vw");
        currSlide = currSlide - 1;
      }
    }
  };

  moveRight = () => {
    if ($(window).width() < 800) {
      if (currSlide <= this.props.slideCount) {
        currSlide = currSlide + 1;

        $("#slider ul").css("left", "-=67.34375vw");
      }

      if (currSlide > this.props.slideCount) {
        $("#slider ul").css("left", "0");
        currSlide = 1;
      }
    } else {
      console.log("acheivements");
      if (currSlide <= this.props.slideCount) {
        $("#slider ul").css("left", "-=" + 525 * (100 / 1920) + "vw");
        currSlide = currSlide + 1;
        if (currSlide > this.props.slideCount) {
          currSlide = 1;
          $("#slider ul").css("left", "0");
        }
      }
      if (currSlide > this.props.slideCount) {
        currSlide = 1;
        $("#slider ul").css("left", "0");
      }
    }
  };
  componentDidUpdate() {
    $("#slider ul").css("left", "-=" + 0 * (100 / 1920) + "vw");
  }
  render() {
    // const settings = {
    //   dots: false,
    //   // className: "slider-adjust",
    //   infinite: true,
    //   speed: 500,
    //   autoplay: true
    // };

    const sliderImages = () => {
      return this.props.seminar.map((item, index) => {
        if (item.idd === this.props.idd) {
          return item.img.map((img, index) => {
            return (
              <li key={index + "1"}>
                <img src={img} className="popawardimg" />
              </li>
            );
          });
        }
      });
    };

    return (
      <div
        className={"grid-item " + this.props.className}
        onMouseOver={this.props.onMouseOver}
        onMouseOut={this.props.onMouseOut}
      >
        {(() => {
          if (!this.props.awardimg) return <div />;
          else
            return (
              <img
                src={this.props.awardimg}
                className="awardimg"
                onClick={() => this.props.popupToggle}
                //onClick={this.popupToggle()}
                alt={this.props.initby}
              />
            );
        })()}

        <div
          className="info-containerr"
          onClick={() => this.props.popupToggle()}
        >
          <div className="awardname">{this.props.awardname}</div>
          <div className="initby">{this.props.initby}</div>
          <div className="year">{this.props.year}</div>
        </div>

        <div
          className="overlay ani-show4"
          // onClick={() => this.props.popupClose()}
        >
          <div className="popup">
            <div className="popclose " onClick={() => this.props.popupClose()}>
              X
            </div>
            {/* <img src={this.props.awardimgBW} className="popawardimg" /> */}
            <div className="slider-images" id="slider">
              <ul id="grid2">{sliderImages()}</ul>
              <img
                src={this.props.awardimgBW}
                className="popawardimg"
                id="grid1"
                alt={this.props.initby}
              />
            </div>
            <img
              src={prev}
              className="prev-btn"
              onClick={() => this.moveLeft()}
            />
            <img
              src={next}
              className="next-btn"
              onClick={() => this.moveRight()}
            />
            <div className="pop-info">
              <div className="popawardname">{this.props.awardname}</div>
              <div className="popinitby">{this.props.initby}</div>
              <div className="popyear">{this.props.year}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    seminar: state.seminar,
    slideCount: state.slideCount,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(Achievements);
