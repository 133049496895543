import React, { Component } from "react";
import RelatedProjects from "../relatedProjects/RelatedProjects";
import AboutUsThumbnail from "../aboutusThumbnail/AboutUsThumbnail";
import AboutUsThumbInfo from "../aboutusThumbinfo/AboutUsThumbInfo";
import projectpagecss from "./projectpagecomponents.css";
import { NavLink } from "react-router-dom";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import ShareComponent from "../sharecomponent/ShareComponent";
import ProjectBtn from "../projectbtn/ProjectBtn";
import $ from "jquery";
import { connect } from "react-redux";
import LargeThumbnail from "../largethumbnail/LargeThumbnail";
import { strict } from "assert";
import { stat } from "fs";
import { Spring } from "react-spring/renderprops";
import { createBrowserHistory } from "history";

const history = createBrowserHistory();

class ProjectPageComponents extends Component {
  newUrl = "";
  state = {
    ap: "None",
    thumbindex: 0,
    id: 2,
    count: 1,
    target: this.props.activeProjectName,
    second: 0,
    third: 0,
    forth: 0,
    fifth: 0,
    catProjects: [],
    catName: []
    //newUrl2: newUrl
  };

  componentDidMount() {
    $(document).scrollTop(0);
    $("#sec1").css("display", "block");
    $("#img1").css("display", "block");
    this.ap = this.props.activeProjectName;
    this.props.projects.map((item, index) => {
      if (item.category === this.props.activeCategory) {
        this.state.catProjects.push(item.header);
      }
    });
    // this.props.history.push(
    //   "/?activeProject=" +
    //     this.props.activeProjectName +
    //     "&?category=" +
    //     this.props.activeCategory
    // );
    //this.props.history.push();
    //alert(this.newUrl);
    this.props.updateLink(this.props.activeProjectNamee);
    this.props.showProjects(this.props.activeCategoryy);
  }

  obj(thumbs) {
    $(".left-img").attr("src", thumbs);
  }
  componentDidUpdate() {
    history.push(
      "/project/" +
      this.props.activeCategory +
      "/project/" +
      this.props.activeProjectName
    );
  }
  render() {
    const projs = () => {
      // this.newUrl =
      //   "/?activeProject=" +
      //   this.props.activeProjectName +
      //   "&?category=" +
      //   this.props.activeCategory;

      const renderInfo = () => {
        return this.props.projects.map((item, index) => {
          if (item.header === this.props.activeProjectName) {
            return (
              <div className="pp-container ani-show">
                <AboutUsThumbInfo
                  title={item.title} //edited here
                  place={item.place}
                  // ProjectTeam={item.ProjectTeam}
                  Client={item.Client}
                  completion={item.completion}
                  area={item.area}
                  desc={item.description}
                />
              </div>
            );
          }
        });
      };
      const renderRelatedProj = () => {
        return this.props.relatedProject.map((items, index) => {
          return (
            <li className="lipointer" onClick={() => showData2(items)}>
              {items.replace(/-/g, " ").toUpperCase()}
            </li>
          );
        });
      };
      const renderRelatedProjects = () => {
        return <RelatedProjects>{renderRelatedProj()}</RelatedProjects>;
      };
      const renderThumbs = () => {
        return this.props.projects.map((item, index) => {
          if (item.header === this.props.activeProjectName) {
            return item.thumbs.map((thumbs, index) => {
              return (
                <AboutUsThumbnail
                  thumb={thumbs}
                  onClick={() => this.obj(thumbs)}
                />
              );
            });
          }
        });
      };

      const renderLargeThumb = () => {
        return this.props.projects.map((item, index) => {
          if (item.header === this.props.activeProjectName) {
            return (
              <LargeThumbnail
                largethumbnail={item.thumbs[this.state.thumbindex]}
              />
            );
          }
        });
      };

      const renderShare = () => {
        return this.props.projects.map((item, index) => {
          if (item.header === this.props.activeProjectName) {
            return <ShareComponent quote={item.title} url={this.newUrl} />;
          }
        });
      };

      const nextpage = () => {
        // this.props.history.push(
        //   "/?activeProject=" +
        //     this.props.activeProjectName +
        //     "&?category=" +
        //     this.props.activeCategory
        // );
        this.props.nextpage("next");
      };
      const showData2 = items => {
        // this.props.history.push(
        //   "/?activeProject=" + items + "&?category=" + this.props.activeCategory
        // );
        this.props.showData(items);
      };

      const prevpage = () => {
        // this.props.history.push(
        //   "/?activeProject=" +
        //     this.props.activeProjectName +
        //     "&?category=" +
        //     this.props.activeCategory
        // );
        this.props.prevpage("prev");
      };
      const newpage = () => {
        this.props.newpage();
      };
      const backToSubCategories = () => {
        this.props.backToSubCategories(this.props.activeCategory);
      };

      return (
        // <Spring
        //   from={{ opacity: 0 }}
        //   to={{ opacity: 1 }}
        //   config={{ duration: 1000 }}
        // >
        //   {props => (
        //     <div style={props}>
        //       <div>
        <div className="section group">
          <div className="mob-paging">
            <ProjectBtn
              nextt={nextpage}
              prevv={prevpage}
              backToSubCategories={this.props.activeCategory}
            />
          </div>
          <div className="column leftsection ani-show">
            {renderLargeThumb()}
          </div>
          <div className="column rightsection">
            <div className="thumbnail-section">{renderThumbs()}</div>

            <div className="thumb-info js-content" id="sec1">
              {this.props.default ? <div>{renderInfo()}</div> : <div />}
            </div>
            <div className="relatedproj">
              {this.props.default ? (
                <div>{renderRelatedProjects()}</div>
              ) : (
                  <div />
                )}
            </div>
            <div className="share-content">
              <div>{renderShare()}</div>
            </div>
            <div className="paging-content">
              <ProjectBtn
                nextt={nextpage}
                prevv={prevpage}
                backToSubCategories={this.props.activeCategory}
              />
            </div>
          </div>
        </div>
        //       </div>
        //     </div>
        //   )}
        // </Spring>
      );
    };
    return (
      <React.Fragment>
        <div className="projectpage-header">
          <Header />
        </div>
        {projs()}
        <div className="phooter">
          <Footer />
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    projects: state.projects,
    activeProjectName: state.activeProjectName,
    default: state.default,
    indexx: state.index,
    category: state.category,
    activeCategory: state.activeCategory,
    relatedProject: state.relatedProject
  };
};

const mapDispatchToProps = dispatch => {
  return {
    nextpage: name => dispatch({ type: "display_info", value: name }),
    prevpage: name => dispatch({ type: "display_info", value: name }),
    showData: name => dispatch({ type: "display_info", value: name }),
    updateLink: name => dispatch({ type: "display_info", value: name }),
    showProjects: name => dispatch({ type: "display_Projects", value: name })

    // backToSubCategories: name =>
    //   dispatch({ type: "display_Projects", value: name })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectPageComponents);
