import React, { Component } from "react";
import projectpagecss from "./projectbtn.css";
import mi from "./menu.jpg";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";

class ProjectBtn extends Component {
  state = {
    menuicon: mi
  };
  render() {
    return (
      <div className="pbtn">
        <div className="ptitle" onClick={this.props.prevv}>
          PREV
        </div>
        <div className="pp_button">
          <NavLink
            to={"/category/" + this.props.backToSubCategories}
            onClick={() => this.props.display_subCategories("ALL")}
          >
            <img className="menuicon" src={this.state.menuicon} />
          </NavLink>
        </div>

        <div className="ntitle" onClick={this.props.nextt}>
          NEXT
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    projects: state.projects,
    activeProjectName: state.activeProjectName,
    default: state.default,
    indexx: state.index,
    category: state.category,
    activeCategory: state.activeCategory,
    relatedProject: state.relatedProject
  };
};

const mapDispatchToProps = dispatch => {
  return {
    display_subCategories: name =>
      dispatch({ type: "display_subCategories", value: name })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectBtn);
