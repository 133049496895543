import React, { Component } from "react";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import "./notfound.css";
class NotFound extends Component {
  state = {};
  render() {
    return (
      <div className="notFoundContainer">
        <Header />
        <div className="text">
          <p>Page Not Found</p>
        </div>
      </div>
    );
  }
}

export default NotFound;
