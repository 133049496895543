import React, { Component } from "react";
import ppsubcss from "./projectpagesublinks.css";
import ProjectPage from "../projectPage/ProjectPage";
import $ from "jquery";
import { connect } from "react-redux";
class ProjectPageSubLinks extends Component {
  state = {};

  componentDidMount() {
    if (window.matchMedia("(max-width: 800px)").matches) {
      $("#link1").css("font-weight", "bold");
      $(".link").click(function() {
        // $(".link").css("color", "#a5a5a5");
        $(this).css("color", "black");
      });
    } else {
      $(".link").click(function() {
        $(".link").css("color", "#a5a5a5");
        $(this).css("color", "black");
      });
    }

    $("#link1").css("color", "black");
    // $("#link1").css("font-weight", "bold");

    $(".drop-cont-links").click(function() {
      $(".drop-cont-links").css("color", "#a5a5a5");
      $(".drop-cont-links").css("font-weight", "unset");
      $(this).css("color", "black");
      $(this).css("font-weight", "bold");
    });
  }

  render() {
    return (
      <React.Fragment>
        {(() => {
          if (window.matchMedia("(max-width: 800px)").matches) {
            return (
              <div className="pp-sublinks" onClick={this.props.clicked}>
                <div className="link" id="link1">
                  {this.props.btn_name}
                </div>
              </div>
            );
          } else
            return (
              <div className="pp-sublinks" onClick={this.props.onClick}>
                <div className="link" id="link1">
                  {this.props.btn_name}
                </div>
              </div>
            );
        })()}
      </React.Fragment>
    );
  }
}

export default ProjectPageSubLinks;
