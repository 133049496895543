import React, { Component } from "react";
import aboutusthumbinfocss from "./aboutusthumbInfo.css";
import $ from "jquery";
import thumb1 from "./dropicon.png";
import thumb2 from "./dropicon2.png";
import { Spring } from "react-spring/renderprops";
import ReadMoreReact from "read-more-react";
let current = 0;
class AboutUsThumbInfo extends Component {
  state = {
    imggSRC: [thumb1, thumb2],
  };
  componentDidMount() {
    // $(".mob-info").css("display", "none");
    $(".dropicon11").css("display", "block");

    $(".sm2").css("display", "none");
  }

  seemore() {
    $(".mob-info").toggle();
    if (current === 1) {
      $(".dropicon11").css("display", "block");
      $(".dropicon22").css("display", "none");
      $(".sm2").css("display", "none");
      $(".sm1").css("display", "block");
      current = 0;
    } else {
      $(".dropicon22").css("display", "block");
      $(".dropicon11").css("display", "none");
      $(".sm1").css("display", "none");
      $(".sm2").css("display", "block");
      current = 1;
    }
  }
  render() {
    return (
      <Spring
        from={{ opacity: 0 }}
        to={{ opacity: 1 }}
        config={{ duration: 1500 }}
      >
        {(props) => (
          <div style={props}>
            <div className="thumbinfo">
              <div className="mob-title">
                <div className="title">{this.props.title}</div>
                <div className="place">{this.props.place}</div>

                <div className="seemore" onClick={this.seemore}>
                  <div className="sm1">See More</div>
                  <div className="sm2">See Less</div>
                  <div className="dropicon">
                    <div className="dropicon11">
                      <img src={this.state.imggSRC[0]} />
                    </div>
                    <div className="dropicon22">
                      <img src={this.state.imggSRC[1]} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="mob-info">
                {/* <div className="title-text ">
                  Project Team:
                  <span className="desc-text">{this.props.ProjectTeam}</span>
                </div> */}
                {(() => {
                  if (this.props.Client.length === 0) return <div />;
                  else
                    return (
                      <div className="title-text ">
                        Client:
                        <span className="desc-text">{this.props.Client}</span>
                      </div>
                    );
                })()}

                {(() => {
                  if (this.props.completion.length === 0) return <div />;
                  else
                    return (
                      <div className="title-text ">
                        Project Completion:
                        <span className="desc-text">
                          {this.props.completion}
                        </span>
                      </div>
                    );
                })()}

                {(() => {
                  if (this.props.area.length === 0) return <div />;
                  else
                    return (
                      <div className="title-text ">
                        Site Area:
                        <span className="desc-text"> {this.props.area}</span>
                      </div>
                    );
                })()}

                <div className="desc-text">
                  <ReadMoreReact
                    text={this.props.desc}
                    min={80}
                    ideal={240}
                    max={240}
                    readMoreText="Read more"
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </Spring>
    );
  }
}

export default AboutUsThumbInfo;
