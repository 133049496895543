import React, { Component } from "react";
import relatedprojectscss from "./relatedprojects.css";

class RelatedProjects extends Component {
  state = {
    isLoaded: true,
  };
  componentDidUpdate() {
    // this.setState({
    //   isLoaded: true
    // });
  }

  render() {
    const renderRelatedProject = () => {
      return (
        <div className="related-main-container">
          <div className="topic">See Related Projects</div>
          <div className="content">
            <ul>{this.props.children}</ul>
          </div>
        </div>
      );
    };
    return <div>{this.state.isLoaded ? renderRelatedProject() : <div />}</div>;
  }
}

export default RelatedProjects;
