import $ from "jquery";
import ContacttUsComponent from "../contactuscomponent/ContactUsComponent";
import { directive } from "@babel/types";

export function submit(data) {
  //validation

  const Fname = data.Fname;
  const email = data.EmailID;
  const number = data.MobNumber;
  const landline = data.Landline;
  const city = data.City;
  const relocate = data.Relocate;
  const position = data.Position;
  const qualification1 = data.Qualification1;
  const passing1 = data.PassingYear1;
  const qualification2 = data.Qualification2;
  const passing2 = data.PassingYear2;
  const totalexp = data.TotalExp;
  const workstatus = data.WorkStatus;
  const currentsal = data.CurrentSal;
  const expectedsal = data.ExpectedSal;
  const notice = data.Notice;
  const source = data.Source;
  const CV = data.CV;

  //alert("Data -> " + data.Fname);
  // const Lname = $("#Lname").val();
  // const email = $("#EmailID").val();
  // const number = $("#MobNumber").val();
  // const message = $("#Message").val();
  if (Fname.length == 0) {
    alert("Please Enter Your Name");
    return;
  }
  //email = data.EmailID;
  if (email.length == 0) {
    alert("Please enter valid email address");
    return;
  }
  if (validateEmail(email)) {
  } else {
    alert("Invalid Email Address");
    return;
  }
  if (number.length == 0) {
    alert("Please Enter Your Mobile Number");
    return;
  }
  if (city.length == 0) {
    alert("Please Enter Your City");
    return;
  }
  if (relocate.length == 0) {
    alert("Please Choose your option");
    return;
  }
  if (position.length == 0) {
    alert("Please Enter the Position you are applying for");
    return;
  }

  if (qualification1.length == 0) {
    alert("Please Select Qualification1 option");
    return;
  }

  if (passing1.length == 0) {
    alert("Please Select Passing Year");
    return;
  }
  if (totalexp.length == 0) {
    alert("Please Enter Your Total Experience");
    return;
  }
  if (currentsal.length == 0) {
    alert("Please Enter Your Current Salary");
    return;
  }
  if (expectedsal.length == 0) {
    alert("Please Enter Your Expected Salary");
    return;
  }
  if (notice.length == 0) {
    alert("Please Enter Your Notice Period");
    return;
  }
  if (CV.length == 0) {
    alert("Please Enter Your CV Link");
    return;
  }
  // $(".subbtn").attr("onclick", "");
  $("#dis").attr("disabled", true);

  // enq = "";

  $.ajax({
    url: "/career.php",
    type: "POST",
    data: {
      Fname: Fname,
      email: email,
      number: number,
      landline: landline,
      city: city,
      relocate: relocate,
      position: position,
      qualification1: qualification1,
      passing1: passing1,
      qualification2: qualification2,
      passing2: passing2,
      totalexp: totalexp,
      workstatus: workstatus,
      currentsal: currentsal,
      expectedsal: expectedsal,
      notice: notice,
      source: source,
      CV: CV
    },
    success: function(res) {
      // $(".subbtn").attr("onclick", "submit();");
      // $("#dis").css("background-color", "red");
      // alert(res);
      $("#dis").attr("disabled", false);

      // $("#Fname").val("");
      // $("#EmailID").val("");
      // $("#MobNumber").val("");
      // $("#Landline").val("");
      // $("#City").val("");
      // $("#Position").val("");
      // $("#qualification1").val("");
      // $("#passingyear1").val("");
      // $("#qualification2").val("");
      // $("#passingyear2").val("");
      // $("#totalexp").val("");
      // $("#workstatus").val("");
      // $("#currentsal").val("");
      // $("#expectedsal").val("");
      // $("#noticeperiod").val("");
      // $("#source").val("");
      // $("#link").val("");

      $(".inputbox-container")
        .find("input[type=text]")
        .val("");
      $(".contact-form")
        .find("input[type=text], textarea")
        .val("");

      alert("Thank you for your interest. Your application is under review.");
    },
    error: function(e) {
      // $(".subbtn").attr("onclick", "submit();");
      // $("#dis").css("background-color", "red");
      alert("Error Occurred");
      $("#dis").attr("disabled", false);
    }
  });
}

// $(document).ready(function() {
//   $("#MobNumber").keypress(function(e) {
//     if (e.which != 8 && e.which != 0 && (e.which < 48 || e.which > 57)) {
//       alert("Please Enter Valid Number");
//       return false;
//     }
//     return;
//   });
// });
function validateEmail(email) {
  var filter = /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
  if (filter.test(email)) {
    return true;
  } else {
    return false;
  }
}
