import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import "../components/reducer/common.css";
import AboutUsComponent from "./aboutuscomponent/AboutUsComponent";
import ProjectPageComponents from "./projectpagecomponents/ProjectPageComponents";
import Client1 from "./client1/Client1";
import Client2 from "./client2/Client2";
import HeaderSlide from "./headerslide/HeaderSlide";
import AchievementsMenu from "./achievementsmenu/AchievementsMenu";
import ContacttUsComponent from "./contactuscomponent/ContactUsComponent";
import Carrer from "./carrer/Carrer";
import RenderProjects from "./renderProjects/RenderProjects";
import Blog from "./blog/Blog";
import BlogPage from "./blogpage/BlogPage";
import NotFound from "./notfound/NotFound";
import FAQ from "./faq/FAQ";

class Main extends Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route path="/" component={HeaderSlide} exact />
          <Route path="/aboutus" component={AboutUsComponent} exact />
          <Route path="/achievements" component={AchievementsMenu} exact />
          <Route path="/career" component={Carrer} exact />
          <Route path="/clientele" component={Client1} exact />
          <Route path="/faq" component={FAQ} exact />
          <Route path="/contact" component={ContacttUsComponent} exact />
          <Route path="/publication" component={Blog} exact />
          <Route
            path="/publication/:blog"
            render={({ match }) => {
              return <BlogPage activeBlogNamee={match.params.blog} />;
            }}
            exact
            strict
          />
          <Route
            path="/category/:category"
            render={({ match }) => {
              return <RenderProjects activeCategoryy={match.params.category} />;
            }}
            exact
            strict
          />
          <Route
            path="/project/:category/project/:project"
            render={({ match }) => {
              return (
                <ProjectPageComponents
                  activeProjectNamee={match.params.project}
                  activeCategoryy={match.params.category}
                />
              );
            }}
          />
          <Route component={NotFound} />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default Main;
