import React, { Component } from "react";
import optcss from "./opt.css";
import $ from "jquery";

class RightMenuOptComponent extends Component {
  state = {};
  componentDidMount() {
    $(".rmenu-option1")
      .find(".rightmenu-text")
      .addClass("activelink");

    // $(".left-sectionn")
    //   .find(".rightmenu-text")
    //   .addClass("activelink");

    $(".opt1-achieve")
      .find(".rightmenu-text")
      .addClass("activelink");
  }
  activeLink() {
    $(".rightmenu-text").removeClass("activelink");
    $("." + this.props.className)
      .find(".rightmenu-text")
      .addClass("activelink");

    $(".rightmenu-text").removeClass("activelink");
    $("." + this.props.className)
      .find(".rightmenu-text")
      .addClass("activelink");
  }
  render() {
    return (
      <h2 className="opt-container" onClick={() => this.activeLink()}>
        <span className="rightmenu-text" onClick={this.props.onClick}>
          {this.props.opt_item}
        </span>
      </h2>
    );
  }
}

export default RightMenuOptComponent;
