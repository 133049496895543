import React, { Component } from "react";
import sectioncss from "./section.css";
class Section extends Component {
  state = {};
  render() {
    return (
      <div className="sec-comp-container">
        <div className="header">{this.props.title}</div>

        {(() => {
          if (this.props.desc.length === 0) return <React.Fragment />;
          else return <div className="desc">{this.props.desc}</div>;
        })()}

        <div className="inline-section">
          {(() => {
            if (this.props.jobheader.length === 0) return <React.Fragment />;
            else
              return (
                <div className="left-sec">
                  <div className="left-header">{this.props.jobheader}</div>
                  <div className="left-desc">{this.props.jobdesc}</div>
                </div>
              );
          })()}

          {(() => {
            if (this.props.qualification.length === 0)
              return <React.Fragment />;
            else
              return (
                <div className="right-sec">
                  <div className="right-header">{this.props.qualification}</div>
                  <ul>
                    <li>{this.props.req1}</li>
                    <li>{this.props.req2}</li>
                    <li>{this.props.req3}</li>
                    {this.props.req4 && <li>{this.props.req4}</li>}

                    {(() => {
                      if (this.props.req5.length === 0)
                        return <React.Fragment />;
                      else return <li>{this.props.req5}</li>;
                    })()}
                  </ul>
                </div>
              );
          })()}
        </div>
      </div>
    );
  }
}

export default Section;
