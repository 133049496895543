import React, { Component } from "react";
import InputBoxComponent from "../inputboxcomponent/InputBoxComponent";
import Footer from "../footer/Footer";
import Header from "../header/Header";
import Section from "../sectioncomponent/Section";
import { Spring } from "react-spring/renderprops";
import $ from "jquery";
import { Helmet } from "react-helmet";
import carrercss from "./carrer.css";
import { submit } from "../scripts/careermail";
let data = {
  Fname: "test",
};
let metaData = {
  "@context": "https://schema.org/",
  "@type": "EmployerAggregateRating",
  itemReviewed: {
    "@type": "Organization",
    name: "Maheshwari & Associates",
    sameAs: "https://www.architectm.com/",
  },
  ratingValue: "5",
  bestRating: "5",
  worstRating: "1",
  ratingCount: "5",
};
class Carrer extends Component {
  state = {
    value: "",
    value2: "",
    value3: "",
    value4: "",
    value5: "",
    value6: "", //radio button
    value7: "",
    value8: "",
    value9: "",
    value10: "",
    value11: "",
    value12: "",
    value13: "",
    value14: "",
    value15: "",
    value16: "",
    value17: "",
    value18: "",
    isButtonDisabled: false,
  };
  componentDidMount() {
    $(document).scrollTop(0);
  }
  handleChange = (event) => {
    this.setState({
      value: event.target.value,
    });
  };

  handleChange2 = (event) => {
    this.setState({
      value2: event.target.value,
    });
  };
  handleChange3 = (event) => {
    const value3 = event.target.validity.valid
      ? event.target.value
      : this.state.value3;

    this.setState({ value3 });

    // this.setState({
    //   value3: event.target.value
    // });
  };
  handleChange4 = (event) => {
    const value4 = event.target.validity.valid
      ? event.target.value
      : this.state.value4;

    this.setState({ value4 });
  };
  handleChange5 = (event) => {
    this.setState({
      value5: event.target.value,
    });
  };
  handleChange6 = (event) => {
    this.setState({
      value6: event.currentTarget.value, //radio button
    });
  };
  handleChange7 = (event) => {
    this.setState({
      value7: event.target.value,
    });
  };
  handleChange8 = (event) => {
    this.setState({
      value8: event.target.value,
    });
  };
  handleChange9 = (event) => {
    const value9 = event.target.validity.valid
      ? event.target.value
      : this.state.value9;

    this.setState({ value9 });
  };
  handleChange10 = (event) => {
    this.setState({
      value10: event.target.value,
    });
  };
  handleChange11 = (event) => {
    const value11 = event.target.validity.valid
      ? event.target.value
      : this.state.value11;

    this.setState({ value11 });
  };
  handleChange12 = (event) => {
    const value12 = event.target.validity.valid
      ? event.target.value
      : this.state.value12;

    this.setState({ value12 });
  };
  handleChange13 = (event) => {
    this.setState({
      value13: event.target.value,
    });
  };
  handleChange14 = (event) => {
    const value14 = event.target.validity.valid
      ? event.target.value
      : this.state.value14;

    this.setState({ value14 });
  };
  handleChange15 = (event) => {
    const value15 = event.target.validity.valid
      ? event.target.value
      : this.state.value15;

    this.setState({ value15 });
  };
  handleChange16 = (event) => {
    this.setState({
      value16: event.target.value,
    });
  };
  handleChange17 = (event) => {
    this.setState({
      value17: event.target.value,
    });
  };
  handleChange18 = (event) => {
    this.setState({
      value18: event.target.value,
    });
  };

  render() {
    const sendDataToScript = () => {
      data.Fname = this.state.value;
      data.EmailID = this.state.value2;
      data.MobNumber = this.state.value3;
      data.Landline = this.state.value4;
      data.City = this.state.value5;
      data.Relocate = this.state.value6;
      data.Position = this.state.value7;
      data.Qualification1 = this.state.value8;
      data.PassingYear1 = this.state.value9;
      data.Qualification2 = this.state.value10;
      data.PassingYear2 = this.state.value11;
      data.TotalExp = this.state.value12;
      data.WorkStatus = this.state.value13;
      data.CurrentSal = this.state.value14;
      data.ExpectedSal = this.state.value15;
      data.Notice = this.state.value16;
      data.Source = this.state.value17;
      data.CV = this.state.value18;

      submit(data);
    };
    return (
      <Spring
        from={{ opacity: 0 }}
        to={{ opacity: 1 }}
        config={{ duration: 1500 }}
      >
        {(props) => (
          <div style={props}>
            <div className="career-container">
              <Helmet>
                <title> Careers | Maheshwari & Associates</title>
                {/* <link
                  rel="canonical"
                  href="https://www.architectm.com/career"
                /> */}
                <meta
                  name="description"
                  content="We are always hiring architects, interior designer and other designers. If you are looking for jobs in architecture industry email at careers@architectm.com"
                />
                <meta
                  name="keywords"
                  content="architecture firms in kolkata, architect firms in mumbai, architect kolkata,Architect Job opening,career"
                />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Maheshwari and Associates" />
                <meta property="og:url" content="https://www.architectm.com" />
                <meta
                  property="og:description"
                  content="Maheshwari & Associates is one of the best architecture firm having their offices in mumbai and Kolkata"
                ></meta>
                <script
                  className="structured-data-list2"
                  type="application/ld+json"
                >
                  {JSON.stringify(metaData)}
                </script>
              </Helmet>
              <Header />
              <section className="content">
                <div className="columns">
                  <div className="branch">
                    <div className="lastUpdated">
                      (Last Updated: 19-01-2021)
                    </div>
                    <div className="opening">
                      <Section
                        header="Current Opening"
                        jobheader=""
                        qualification="Qualification"
                        desc="Architect in Kolkata & Mumbai"
                        jobdesc=""
                        req1="Bachelor's degree in architecture"
                        req2="Proficient in Autocad & other designing softwares"
                        req3="3+ years of experience in the field needed"
                        req4="Design portfolio required"
                        req5=""
                      />
                    </div>
                    <div className="opening">
                      <Section
                        jobheader=""
                        qualification="Qualification"
                        desc="Site Supervisor in Kolkata & Mumbai"
                        jobdesc=""
                        req1="Bachelor's degree in Civil Engineering"
                        req2="Proficient in AutoCAD"
                        req3="With 3+ years of experience in the field needed"
                        req4="Site supervision experience"
                        req5=""
                      />
                    </div>
                    <div className="opening">
                      <Section
                        desc="Interior Designer in Kolkata & Mumbai"
                        jobheader=""
                        qualification="Qualification"
                        jobdesc=""
                        req1="Bachlores’ degree in Interior designing"
                        req2="Proficient in autocad & other designing softwares"
                        req3="With 2+ years of experience in the field"
                        req5=""
                      />
                    </div>

                    <div className="opening">
                      <Section
                        jobheader=""
                        qualification="Qualifications"
                        desc="Junior Accountant in Kolkata"
                        jobdesc=""
                        req1="Bachlores’ in Commerce"
                        req2="Proficiency in Tally"
                        req3="With 2+ years of experience in accounting"
                        req5=""
                      />
                    </div>
                  </div>
                  <div className="message">
                    <div className="title pad3">Apply Here</div>
                    <InputBoxComponent
                      placeholder="Full Name*"
                      id="Fname"
                      value={this.state.value}
                      Change={this.handleChange}
                    />

                    <InputBoxComponent
                      placeholder="Your Email*"
                      id="EmailID"
                      value={this.state.value2}
                      Change={this.handleChange2}
                    />

                    <InputBoxComponent
                      placeholder="Mobile*"
                      id="MobNumber"
                      value={this.state.value3}
                      Change={this.handleChange3}
                      pattern="[0-9]*"
                    />
                    <InputBoxComponent
                      placeholder="Landline"
                      id="Landline"
                      value={this.state.value4}
                      Change={this.handleChange4}
                      pattern="[0-9]*"
                    />
                    <InputBoxComponent
                      placeholder="City You Reside*"
                      id="City"
                      value={this.state.value5}
                      Change={this.handleChange5}
                    />

                    <div className="radio-container">
                      Ready to Relocate*
                      <div className="radio">
                        <input
                          type="radio"
                          value="Yes"
                          checked={this.state.value6 === "Yes"}
                          onChange={this.handleChange6}
                        />
                        <div className="radioText"> Yes</div>
                      </div>
                      <div className="radio">
                        <input
                          type="radio"
                          checked={this.state.value6 === "No"}
                          value="No"
                          onChange={this.handleChange6}
                        />
                        <div className="radioText"> No</div>
                      </div>
                    </div>

                    {/* <select
                      id="Position"
                      value={this.state.value7}
                      onChange={this.handleChange7}
                    >
                      <option value="">Position Applying For*</option>
                      <option value="Architect in Kolkata">
                        Architect in Kolkata
                      </option>
                      <option value="Architectural Renderer / 3D Visualiser">
                        Architectural Renderer / 3D Visualiser
                      </option>
                      <option value="Architect in Kolkata">
                        Architect in Kolkata
                      </option>
                      <option value="IT Developer">IT Developer</option>
                    </select> */}
                    <InputBoxComponent
                      placeholder="Position Applying For*"
                      id="Position"
                      value={this.state.value7}
                      Change={this.handleChange7}
                    />

                    {/* <select
                      id="qualification1"
                      value={this.state.value8}
                      onChange={this.handleChange8}
                    >
                      <option value="">Qualification 1*</option>
                      <option value="Qualification1">Qualification1</option>
                      <option value="Qualification2">Qualification2</option>
                    </select> */}
                    <InputBoxComponent
                      placeholder="Qualification 1*"
                      id="qualification1"
                      value={this.state.value8}
                      Change={this.handleChange8}
                    />

                    {/* <select
                      id="passingyear1"
                      value={this.state.value9}
                      onChange={this.handleChange9}
                    >
                      <option value="">Passing Year*</option>
                      <option value="2015">2015</option>
                      <option value="2016">2016</option>
                      <option value="2017">2017</option>
                      <option value="2018">2018</option>
                      <option value="2019">2019</option>
                    </select> */}

                    <InputBoxComponent
                      placeholder="Passing Year*"
                      id="passingyear1"
                      value={this.state.value9}
                      Change={this.handleChange9}
                      pattern="[0-9]*"
                    />

                    {/* <select
                      id="qualification2"
                      value={this.state.value10}
                      onChange={this.handleChange10}
                    >
                      <option value="">Qualification 2*</option>
                      <option value="Qualification1">Qualification1</option>
                      <option value="Qualification2">Qualification2</option>
                    </select> */}
                    <InputBoxComponent
                      placeholder="Qualification 2*"
                      id="qualification2"
                      value={this.state.value10}
                      Change={this.handleChange10}
                    />

                    {/* <select
                      id="passingyear2"
                      value={this.state.value11}
                      onChange={this.handleChange11}
                    >
                      <option value="">Passing Year*</option>
                      <option value="2015">2015</option>
                      <option value="2016">2016</option>
                      <option value="2017">2017</option>
                      <option value="2018">2018</option>
                      <option value="2019">2019</option>
                    </select> */}
                    <InputBoxComponent
                      placeholder="Passing Year*"
                      id="passingyear2"
                      value={this.state.value11}
                      Change={this.handleChange11}
                      pattern="[0-9]*"
                    />

                    <InputBoxComponent
                      placeholder="Total Experience"
                      id="totalexp"
                      value={this.state.value12}
                      Change={this.handleChange12}
                      pattern="[0-9]*"
                    />

                    <select
                      id="workstatus"
                      value={this.state.value13}
                      onChange={this.handleChange13}
                    >
                      <option value="">Work Status</option>
                      <option value="Employed">Employed</option>
                      <option value="Unemployed">Unemployed</option>
                    </select>

                    <InputBoxComponent
                      placeholder="Current Salary(PM)*"
                      id="currentsal"
                      value={this.state.value14}
                      Change={this.handleChange14}
                      pattern="[0-9]*"
                    />
                    <InputBoxComponent
                      placeholder="Expected Salary(PM)*"
                      id="expectedsal"
                      value={this.state.value15}
                      Change={this.handleChange15}
                      pattern="[0-9]*"
                    />

                    <select
                      id="noticeperiod"
                      value={this.state.value16}
                      onChange={this.handleChange16}
                    >
                      <option value="">Notice Period*</option>
                      <option value="15 days">15 days</option>
                      <option value="30 days">30 days</option>
                      <option value="45 days">45 days</option>
                    </select>
                    <InputBoxComponent
                      placeholder="Google Drive Link of Your CV"
                      id="link"
                      value={this.state.value18}
                      Change={this.handleChange18}
                    />
                    <select
                      id="source"
                      value={this.state.value17}
                      onChange={this.handleChange17}
                    >
                      <option value="">Sourced/Referenced By</option>
                      <option value="Friend">Friend</option>
                      <option value="Social Media">Social Media</option>
                      <option value="Advertisement">Advertisement</option>
                      <option value="Others">Others</option>
                    </select>
                    <br />
                    <button
                      className="subbtn"
                      id="dis"
                      onClick={sendDataToScript}
                    >
                      SUBMIT
                    </button>

                    <p className="title pad4">OR</p>
                    <p className="mail">
                      Mail us your CV and portfolio (pdf format) <br />
                      <a href="mailto:careers@architectm.com">
                        careers@architectm.com
                      </a>
                    </p>
                  </div>

                  <div className="hiring">
                    <div className="title pad33">Frequent Hiring</div>
                    <div className="hiring-head">
                      Architect in Kolkata & Mumbai
                    </div>
                    <div className="hiring-head">
                      Interior Designer in Kolkata & Mumbai
                    </div>
                    <div className="hiring-head">
                      Interns in Architecture & Interior Design courses
                    </div>

                    {/* <div className="hiring-desc">
                      Lorem Ipsum has been the industry’s standard dummy text
                      ever since the 1500s, when an unknown printer took a
                      galley of type and scrambled it to make
                    </div> */}
                  </div>
                </div>
              </section>
              <Footer />
            </div>
          </div>
        )}
      </Spring>
    );
  }
}

export default Carrer;
