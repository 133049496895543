import React, { Component } from "react";
import sharecompcss from "./sharecomponent.css";
import i1 from "./fb.png";
import i2 from "./mail.png";
import { FacebookShareButton } from "react-share";
import { FacebookIcon } from "react-share";
import { Spring } from "react-spring/renderprops";
import { connect } from "react-redux";

var reactNodeLi = React.createElement("li", { id: "li1" }, "one");
class ShareComponent extends Component {
  state = {
    icon1: i1,
    icon2: i2
  };

  render() {
    return (
      <Spring
        from={{ opacity: 0 }}
        to={{ opacity: 1 }}
        config={{ duration: 1500 }}
      >
        {props => (
          <div style={props}>
            <div className="share-container">
              <span>Share:</span>
              <div className="email-share">
                <a href="mailto:info@architectm.com" target="_top">
                  <img className="icon" src={this.state.icon2} />
                </a>
              </div>
              <div className="fb-share">
                <FacebookShareButton
                  className="icon"
                  children=""
                  quote={this.props.quote}
                  url={
                    "https://architectm.com/project/" +
                    this.props.activeCategory +
                    "/project/" +
                    this.props.activeProjectName
                  }
                >
                  <img className="icon" src={this.state.icon1} />
                </FacebookShareButton>
              </div>
            </div>
          </div>
        )}
      </Spring>
    );
  }
}
const mapStateToProps = state => {
  return {
    activeCategory: state.activeCategory,
    activeProjectName: state.activeProjectName
  };
};

const mapDispatchToProps = dispatch => {
  return {
    nextpage: name => dispatch({ type: "display_info", value: name }),
    prevpage: name => dispatch({ type: "display_info", value: name }),
    showData: name => dispatch({ type: "display_info", value: name }),
    updateLink: name => dispatch({ type: "display_info", value: name }),
    showProjects: name => dispatch({ type: "display_Projects", value: name })

    // backToSubCategories: name =>
    //   dispatch({ type: "display_Projects", value: name })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShareComponent);
